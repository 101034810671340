import { ApiServicesTypes } from '@@types/apiLogicTypes'
import { itemPhotoFile } from '@components/Settings/ServicePhotoItem'
import { useBoolStateTypeReturn } from '@hooks/commonHooks'
import { UseInputType } from '@hooks/useInput'
import Layout from '@layouts/NonScrollableLayout'
import { colorOptions } from '@utils/variableApp'
import { Dropdown } from 'primereact/dropdown'
import { Dispatch, SetStateAction } from 'react'
import { Checkbox } from '../Checkbox/Checkbox'
import { renderItemTemplate, renderLabelTemplate } from '../DropdownColorTemplate/DropdownColorTemplate'
import { LoadPhoto } from '../LoadPhoto/LoadPhoto'
import { UiButton } from '../UiButton/UiButton'
import { UiText } from '../UiText/UiText'
import { UiTextArea } from '../UiTextArea/UiTextArea'
import { UiTextField } from '../UiTextField/UiTextField'
import './style.css'

export type RoomFormProps = {
  name: UseInputType
  capacity: UseInputType
  description: UseInputType
  types: ApiServicesTypes[] | null
  title: string
  selectedCategory: ApiServicesTypes | null
  setSelectedCategory: (value: SetStateAction<ApiServicesTypes | null>) => void
  sendForm: () => void
  useBoolStateCheckbox: useBoolStateTypeReturn
  isDisabledFile: boolean
  setDisabledFile: Dispatch<SetStateAction<boolean>>
  files: itemPhotoFile[]
  onClickDelete(index: number, id?: number): void
  setUploadFile(item: itemPhotoFile): void
  setSelectedColor: Dispatch<
    SetStateAction<{
      color: string
    }>
  >
  color?: { color: string }
}

export const RoomForm = (props: RoomFormProps) => {
  const {
    name,
    selectedCategory,
    setSelectedCategory,
    types,
    sendForm,
    useBoolStateCheckbox,
    isDisabledFile,
    setDisabledFile,
    files,
    onClickDelete,
    setUploadFile,
    title,
    capacity,
    description,
    setSelectedColor,
    color,
  } = props

  return (
    <Layout title={title} scrollable={true}>
      <div className='flex h-full flex-col gap-[16px] overflow-x-auto rounded-[3px]'>
        <div className='flex flex-row items-center gap-[32px]'>
          <UiTextField className='!w-[233px]' name='Название' {...name}></UiTextField>

          {types && (
            <div className='flex w-[100%] max-w-[233px] flex-col gap-[4px]'>
              <UiText className='text-grey'>Категория</UiText>

              <Dropdown
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.value)}
                options={types}
                optionLabel='name'
                placeholder='Категория'
                className='md:w-14rem worker w-full border-green'
                panelClassName='worker-panel'
                color='green'
              />
            </div>
          )}
        </div>
        <div className='flex flex-row items-center gap-[32px]'>
          <UiTextField className='!w-[233px]' name='Вместимость' {...capacity}></UiTextField>

          <div className='flex w-[100%] max-w-[233px] flex-col gap-[4px]'>
            <UiText className='text-grey'>Цвет календаря</UiText>

            <Dropdown
              itemTemplate={renderItemTemplate}
              value={color}
              onChange={(e) => setSelectedColor(e.value)}
              options={colorOptions}
              optionLabel='color'
              valueTemplate={renderLabelTemplate}
              placeholder='Цвет календаря'
              className='md:w-14rem worker w-full border-green'
              panelClassName='worker-panel color-picker'
              color='green'
            />
          </div>
        </div>
        <div className='flex flex-row items-center gap-[32px]'>
          <div className='flex flex-row gap-[32px]'>
            <UiTextArea className='!w-[763px]' title='Описание' {...description} />
          </div>
        </div>
        <div className='flex flex-row gap-[33px]'>
          <LoadPhoto
            isDisabledFile={isDisabledFile}
            setDisabledFile={(isDisabledFile) => setDisabledFile(isDisabledFile)}
            arrayFile={files}
            setUploadFile={setUploadFile}
            onClickDelete={onClickDelete}
          />
        </div>
        <div className='mt-auto flex h-[52px] flex-row items-end gap-[16px] border-0 border-t-[1px] border-solid border-grey-secondary px-[16px]'>
          <UiButton onClick={sendForm} className='w-[233px]'>
            Сохранить
          </UiButton>
          <div className='flex items-center gap-[8px]'>
            <Checkbox useBoolState={useBoolStateCheckbox}></Checkbox>
            <UiText className='mb-[3px] text-grey'>Доступно на сайте</UiText>
          </div>
        </div>
      </div>
    </Layout>
  )
}
