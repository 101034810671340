import { useStore } from '@hooks/storeHook'
import { observer } from 'mobx-react-lite'
import { FormProvider, useForm } from 'react-hook-form'
import { TGameForm } from '../types'
import { GameTimes, GameZone } from './GameZone/GameZone'
import { PCZone } from './PCZone/PCZone'

export const GameForm = observer(() => {
  const {
    game: { gameZones, computers, games },
  } = useStore()

  const methods = useForm<TGameForm>({
    mode: 'onChange',
    defaultValues: {
      gamezone: gameZones.map((e) => {
        return {
          name: e.name,
          pc: {
            name: e.computer?.name,
            api_id: String(e.computer?.id),
            monitor_id: String(e.computer?.monitor_id),
          },
          quests: games.filter((game) => e.games.some((item) => item.id === game.id)),
          api_id: e.id,
          time: GameTimes.find((time) => time.duration === e.duration) ?? undefined,
          is_solo: Boolean(e.is_solo),
        }
      }),
      pc: computers.map((e) => ({
        name: e.name,
        api_id: String(e.id),
        key: e.location + ':' + e.number,
        monitor_id: String(e.monitor_id),
      })),
      pcOptions: computers.map((e) => {
        return { name: e.name, api_id: String(e.id) }
      }),
    },
  })

  return (
    <FormProvider {...methods}>
      <form className='flex h-full flex-col gap-4'>
        <GameZone />
        <PCZone />
      </form>
    </FormProvider>
  )
})
