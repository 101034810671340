import { UiText } from '@components/ui/UiText/UiText'
import classNames from 'classnames'
import { DetailedHTMLProps, FC, TextareaHTMLAttributes } from 'react'
import { useFormContext } from 'react-hook-form'

interface IFormTextArea extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  name: string
  title?: string
}

export const FormTextArea: FC<IFormTextArea> = ({ name, title, placeholder = 'Введите текст', ...props }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const getErrorMessage = () => {
    const keys: string[] = name.split('.')
    let result: any = errors

    for (const key of keys) {
      result = result?.[key]
    }

    return result
  }

  return (
    <div className={`flex flex-col gap-[4px]`}>
      {title && <UiText className='text-grey'>{title}</UiText>}
      <textarea
        {...register(name, {
          required: props.required && 'Обязательное поле',
        })}
        placeholder={placeholder}
        {...props}
        required={undefined}
        className={classNames(
          `text-area h-[36px] min-h-[100px] resize-none rounded-[3px] border-[1px] border-solid border-green bg-white px-[8px] py-[10px] text-base !tracking-normal !text-grey focus:outline-none`,
          props.className,
          !!getErrorMessage() && 'border-red'
        )}
      ></textarea>
    </div>
  )
}
