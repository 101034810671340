import classNames from 'classnames'
import { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react'
import styles from './UiButton.module.css'

interface IUiButton extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children: ReactNode
  icon?: boolean
  isLoading?: boolean
  color?: 'blue' | 'green' | 'red' | 'grey' | 'yellow'
}

export const UiButton: FC<IUiButton> = ({ children, icon, isLoading, color = 'green', ...props }) => {
  return (
    <button
      {...props}
      style={{
        padding: icon ? undefined : '8px',
        maxWidth: icon ? '36px' : undefined,
      }}
      className={classNames(
        `${props.className} flex h-[36px] min-w-[36px] cursor-pointer items-center justify-center rounded-[3px] border-0 text-sm font-black uppercase text-white active:scale-[0.95]`,
        styles[color],
        props.disabled && styles.disabled
      )}
      disabled={props.disabled || isLoading}
      ref={props.ref}
    >
      {isLoading ? <i className='pi pi-spin pi-spinner' /> : children}
    </button>
  )
}
