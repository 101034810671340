import { ApiCashReport } from '@@types/apiLogicTypes'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { canAccessFeature, FeatureNameEnum } from '@config/featureAccessConfig'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { Calendar } from 'primereact/calendar'
import { Column } from 'primereact/column'
import { DataTable, DataTableStateEvent } from 'primereact/datatable'
import { FC, useContext, useEffect, useState } from 'react'

interface ITable {
  setInitialReportHandler: (report: ApiCashReport) => void
}

export const Table: FC<ITable> = observer(({ setInitialReportHandler }) => {
  const logic = useLogic()
  const {
    auth: { selectedPark, user },
    payments: { cashboxSum, cashReports },
  } = useStore()
  const context = useContext(ToastContext)

  const [totalRecords, setTotalRecords] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [lazyState, setlazyState] = useState<DataTableStateEvent>({
    first: 0,
    rows: 10,
    page: 0,
    sortField: '',
    sortOrder: null,
    filters: {
      global: { value: null, matchMode: FilterMatchMode.BETWEEN },
      created_at: {
        operator: FilterOperator.AND,
        constraints: [{ value: new Date(), matchMode: FilterMatchMode.DATE_IS }],
      },
    },
    multiSortMeta: null,
  })

  const loadLazyData = async () => {
    setIsLoading(true)

    const { page, filters } = lazyState
    //@ts-ignore
    const { matchMode, value } = filters?.created_at?.constraints[0] || {}

    const formatDate = (date: Date) => moment(date).format('YYYY-MM-DD')

    let startDate, endDate

    if (matchMode === FilterMatchMode.DATE_IS && value) {
      startDate = endDate = formatDate(value)
    } else if (matchMode === FilterMatchMode.BETWEEN && Array.isArray(value)) {
      ;[startDate, endDate] = value.map(formatDate)
    }

    const response = await logic.loadCashReports(page! + 1, startDate, endDate)

    if (response?.data) {
      setTotalRecords(response?.data.total)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    loadLazyData()
  }, [selectedPark, lazyState])

  const onFilter = (props: DataTableStateEvent) => {
    props['first'] = 0
    setlazyState(props)
  }

  const onPage = (props: Required<DataTableStateEvent>) => {
    setlazyState(props)
  }

  const onSort = (props: Required<DataTableStateEvent>) => {
    console.log(props)
  }

  const dateFilterTemplate = (options: any) => {
    const selectionMode = options.filterModel.matchMode === FilterMatchMode.BETWEEN ? 'range' : 'single'

    return (
      <Calendar
        selectionMode={selectionMode}
        value={options.value}
        onChange={(e: any) => {
          options.filterCallback(e.value, options.index)
        }}
        dateFormat='dd.mm.yy'
        locale='ru'
        maxDate={new Date()}
        hideOnRangeSelection={selectionMode === 'range'}
        mask={selectionMode === 'range' ? '99.99.9999 - 99.99.9999' : '99.99.9999'}
      />
    )
  }

  const dateBodyTemplate = (rowData: any) => {
    return moment(rowData.created_at).format('DD.MM.YYYY')
  }

  const deleteTemplate = (rowData: any) => {
    if (rowData.type !== 3 && rowData.type !== 4) return null

    const deleteHandler = async () => {
      await logic.deleteCashReport(rowData)
    }

    return (
      <UiButton
        onClick={deleteHandler}
        color='red'
        disabled={
          (rowData.status === 1 && !canAccessFeature(user!.roles, FeatureNameEnum.manageUncompleteLogs)) ||
          (rowData.status === 0 && !canAccessFeature(user!.roles, FeatureNameEnum.manageCompletedLogs))
        }
      >
        <i className='pi pi-trash !text-white'></i>
      </UiButton>
    )
  }

  const statusBodyTemplate = (rowData: ApiCashReport) => {
    const onProceed = async () => {
      const response = await logic.minusCashReport(rowData.id)
      if (!response?.status) context?.toastShowHandler({ status: false, errors: response?.errors })
    }

    if (canAccessFeature(user!.roles, FeatureNameEnum.manageUncompleteLogs)) {
      if (rowData.status === 1)
        return (
          <UiText className='cursor-pointer font-medium underline' onClick={onProceed}>
            Провести
          </UiText>
        )
      else return <UiText className='font-medium !text-green'>Проведен</UiText>
    } else {
      if (rowData.status === 1) return <UiText className='font-medium text-grey'>Не проведен</UiText>
      else return <UiText className='font-medium !text-green'>Проведен</UiText>
    }
  }

  const onRowDoubleClickHandler = (data: ApiCashReport) => {
    if (data.status === 0 && canAccessFeature(user!.roles, FeatureNameEnum.manageCompletedLogs))
      setInitialReportHandler(data)
    else if (data.status === 1 && canAccessFeature(user!.roles, FeatureNameEnum.manageUncompleteLogs))
      setInitialReportHandler(data)
    else return
  }

  return (
    <section className='flex flex-col gap-5 overflow-auto'>
      <UiText className='absolute right-[20px] top-[75px] z-20 ml-auto text-grey'>
        Нал:
        <span className='ml-auto rounded-[3px] border border-solid border-grey-secondary px-2 py-[7px]'>
          {cashboxSum}
        </span>
      </UiText>
      <DataTable
        lazy
        totalRecords={totalRecords}
        onPage={onPage}
        filters={lazyState.filters}
        globalFilterFields={['created_at']}
        onSort={onSort}
        onFilter={onFilter}
        value={cashReports}
        loading={isLoading}
        showGridlines
        size={'small'}
        filterLocale='ru'
        className='ui-paginator-table h-fit bg-white text-base'
        removableSort
        sortField={lazyState.sortField}
        first={lazyState.first}
        sortOrder={lazyState.sortOrder}
        emptyMessage='Платежи не найдены'
        paginator
        rows={20}
        scrollable
        scrollHeight='flex'
        onRowDoubleClick={(e) => onRowDoubleClickHandler(e.data as ApiCashReport)}
      >
        <Column
          filterField='created_at'
          header='Дата'
          dataType='date'
          body={dateBodyTemplate}
          filterMatchMode={FilterMatchMode.BETWEEN}
          showFilterMatchModes={true}
          filterMatchModeOptions={[
            { label: 'Период', value: 'between' },
            { label: 'За день', value: 'dateIs' },
          ]}
          showFilterMenuOptions={true}
          showAddButton={false}
          showFilterOperator={false}
          filterPlaceholder='Выберите фильтр'
          filter
          filterElement={dateFilterTemplate}
          filterApply={(e) => (
            <UiButton onClick={e.filterApplyCallback} className='capitalize'>
              Принять
            </UiButton>
          )}
          filterClear={(e) => (
            <UiButton
              onClick={e.filterClearCallback}
              className='!border border-solid border-blue capitalize'
              color='blue'
            >
              Очистить
            </UiButton>
          )}
        ></Column>
        <Column field='id' header='Номер'></Column>
        <Column field='name' header='Название'></Column>
        <Column field='type' header='Тип' body={(e) => actionNameSwitcher(e.type)}></Column>
        <Column field='sum' header='Сумма, ₽'></Column>
        <Column field='status' header='Статус' body={(e) => statusBodyTemplate(e)} />
        <Column body={(e) => deleteTemplate(e)} />
      </DataTable>
    </section>
  )
})

const actionNameSwitcher = (type: 1 | 2 | 3 | 4) => {
  switch (type) {
    case 1:
      return 'Продажа'
    case 2:
      return 'Инкассация'
    case 3:
      return 'Авансовый отчет'
    case 4:
      return 'РКО'
  }
}
