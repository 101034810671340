import { Role } from '@@types/apiLogicTypes'
import { mainPool, RoleNameEnum } from './roles'

type TFeatureAccessRolesConfig = {
  [key: string]: RoleNameEnum[]
}

export enum FeatureNameEnum {
  collectionMoney = 'collectionMoney',
  manageUncompleteLogs = 'manageUncompletedLogs',
  manageCompletedLogs = 'manageCompletedLogs',
}

export const featureAccessRolesConfig: TFeatureAccessRolesConfig = {
  [FeatureNameEnum.collectionMoney]: [RoleNameEnum.manager], // Изменяемый массив
  [FeatureNameEnum.manageUncompleteLogs]: [...mainPool], // Изменяемый массив
  [FeatureNameEnum.manageCompletedLogs]: [RoleNameEnum.manager], // Изменяемый массив
}

export const canAccessFeature = (userRoles: Role[], featureName: FeatureNameEnum): boolean => {
  const featureConfig = featureAccessRolesConfig[featureName]
  if (!featureConfig) return false
  return userRoles.some((role) => featureConfig.includes(role.name))
}
