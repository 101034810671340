import { ApiAttraction } from '@@types/apiLogicTypes'
import logo from '@assets/images/skala.png'
import { checkBoxBodyTemplate, imgBodyTemplate } from '@components/ui/ColumnContents/ColumnContents'
import { AttractionTableItem } from '@components/ui/ContainerTable/AttractionTableItem'
import { TableButtons } from '@components/ui/ContainerTable/TableButtons'
import { TablePageContainer } from '@components/ui/TablePageContainer/TablePageContainer'
import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ArchiveContext } from '@context/archiveContext'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { createFormData } from '@utils/createDataForm'
import { tableNameAttraction } from '@utils/variableApp'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useContext, useEffect } from 'react'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'

export const AttractionScreen = observer(() => {
  const logic = useLogic()
  const {
    attraction: { attractions },
    auth: { selectedPark },
  } = useStore()

  const onAdd = () => {
    navigateTo(AppRoutesPaths.SettingAttractionAdd)
  }

  const onArchive = () => {
    navigateTo(AppRoutesPaths.SettingAttractionArchive)
  }

  const location = useLocation()
  const inArchive = location && location.pathname === AppRoutesPaths.SettingAttractionArchive
  const context = useContext(ToastContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (inArchive) logic.loadAttraction(true)
    else logic.loadAttraction()
  }, [location, selectedPark])

  function onClickEdit(item: ApiAttraction) {
    let path: string = ''

    path = generatePath(AppRoutesPaths.SettingAttractionUpdate, {
      id: String(item.id),
    })
    navigate(path)
  }

  async function onChangeActive(item: { active: number | boolean; [key: string]: any }) {
    const form = new FormData()
    form.append('active', String(Number(!Boolean(Number(item.active)))))
    form.append('park_id', String(item.park_id))
    form.append('id', String(item.id))

    await logic.updateAttraction(form, String(item.id))
  }

  async function onDelete(item: ApiAttraction) {
    if (item.deleted === 0) {
      const { status, errors } = await logic.deleteAttraction(item.id)
      if (!status) {
        context?.toastShowHandler({ status, errors })
      } else logic.loadAttraction()
    } else {
      const { status, errors } = await logic.updateAttraction(createFormData({ deleted: 0 }), String(item.id))
      if (!status) {
        context?.toastShowHandler({ status, errors })
      } else logic.loadAttraction(true)
    }
  }

  const buttonBodyTemplate = (item: ApiAttraction) => {
    return (
      <TableButtons onClickEdit={!inArchive ? () => onClickEdit(item) : undefined} onClickBox={() => onDelete(item)} />
    )
  }

  const nameBodyTemplateWithIcon = (rowData: ApiAttraction) => {
    return (
      <div className='flex items-center justify-between'>
        <UiText onClick={() => onClickEdit(rowData)}>{rowData.name}</UiText>
        {rowData?.group_id && <img src={logo} alt='Создано администратором' className='ml-2 h-3 w-3' />}
      </div>
    )
  }

  return (
    <ArchiveContext.Provider value={{ inArchive, onArchive }}>
      <TablePageContainer
        tableProps={{
          arrayHeadName: tableNameAttraction,
          children: <TableList items={attractions ?? []} />,
        }}
        mainTitle='Аттракционы'
        textAdd={'Аттракцион'}
        onClickAdd={onAdd}
        textArchive={'Аттракционы'}
      >
        <DataTable
          value={attractions ?? []}
          showGridlines
          className={classnames('bg-white text-base')}
          style={{ fontSize: '12px' }}
          removableSort
          sortField='name'
          sortOrder={1}
          emptyMessage='Нет данных'
          // filters={filters}
        >
          <Column field='photos' body={imgBodyTemplate} header='Фото' bodyClassName='text-center'></Column>
          <Column
            field='name'
            header='Название'
            sortable
            body={nameBodyTemplateWithIcon}
            // filter
            // filterPlaceholder='Поиск по названию'
            // showAddButton={false}
          ></Column>
          {/* <Column field='id' header='Сортировка' sortable></Column> */}
          {!inArchive && (
            <Column field='active' header='Сайт' body={(item) => checkBoxBodyTemplate(item, onChangeActive)}></Column>
          )}
          <Column field='' header='' body={buttonBodyTemplate}></Column>
        </DataTable>
      </TablePageContainer>
    </ArchiveContext.Provider>
  )
})

export const TableList = (props: { items: ApiAttraction[] }) =>
  props.items.map((item, index) => <AttractionTableItem key={item.id} index={index + 1} item={item} />)
