import { ApiAttraction, ApiValidationResponse } from '@@types/apiLogicTypes'
import logo from '@assets/images/skala.png'
import { AppRoutesPaths } from '@config/navigation'
import { ArchiveContext } from '@context/archiveContext'
import { ToastContext } from '@context/toastContext'
import { useLogic } from '@hooks/storeHook'
import { createFormData } from '@utils/createDataForm'
import { useContext, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Checkbox } from '../Checkbox/Checkbox'
import { IconElement } from '../IconElement/IconElement'
import { UiText } from '../UiText/UiText'
import { TD } from './ContainerTable'
import { TableButtons } from './TableButtons'

export const AttractionTableItem = ({
  item,
  index,
  isAdmin = false,
}: {
  item: ApiAttraction
  index: number
  isAdmin?: boolean
}) => {
  const logic = useLogic()
  const navigate = useNavigate()

  const archiveContext = useContext(ArchiveContext)
  const toastContext = useContext(ToastContext)

  const [isActive, setActive] = useState<boolean>(Boolean(item.active))

  function onClickEdit() {
    let path: string = ''

    if (isAdmin) {
      path = generatePath(AppRoutesPaths.AdminPanelAttractionUpdate, {
        id: String(item.id),
      })
    } else {
      path = generatePath(AppRoutesPaths.SettingAttractionUpdate, {
        id: String(item.id),
      })
    }
    navigate(path)
  }
  async function onChangeActive() {
    const form = new FormData()
    form.append('active', String(Number(!isActive)))
    form.append('park_id', String(item.park_id))
    form.append('id', String(item.id))

    let response: ApiValidationResponse = { status: false }

    if (isAdmin) {
      response = await logic.updateAdminAttraction(form, String(item.id))
    } else {
      response = await logic.updateAttraction(form, String(item.id))
    }

    if (response) {
      setActive((prev) => !prev)
    }
  }

  async function onDelete() {
    if (item.deleted === 0) {
      const { status, errors } = await logic.deleteAttraction(item.id)
      if (!status) {
        toastContext?.toastShowHandler({ status, errors })
      } else logic.loadAttraction()
    } else {
      const { status, errors } = await logic.updateAttraction(createFormData({ deleted: 0 }), String(item.id))
      if (!status) {
        toastContext?.toastShowHandler({ status, errors })
      } else logic.loadAttraction(true)
    }
  }

  return (
    <>
      <tr>
        <TD>
          {item?.images?.[0]?.path ? (
            <IconElement
              // width={80}
              height={80}
              icon={item?.images?.[0]?.path ?? ''}
            ></IconElement>
          ) : (
            <>Нет фото</>
          )}
        </TD>
        <TD cursorPointer>
          <div className='flex items-center justify-between'>
            <UiText onClick={onClickEdit}>{item?.name}</UiText>
            {item.group_id && <img src={logo} alt='Создано администратором' className='ml-2 h-3 w-3' />}
          </div>
        </TD>
        <TD>
          <UiText>{String(index)}</UiText>
        </TD>
        {!archiveContext?.inArchive && !isAdmin && (
          <TD center>
            <Checkbox useBoolState={[isActive, onChangeActive, onChangeActive]}></Checkbox>
          </TD>
        )}
        <TD center>
          <TableButtons
            onClickEdit={!archiveContext?.inArchive ? onClickEdit : undefined}
            onClickBox={!isAdmin ? onDelete : undefined}
          />
        </TD>
      </tr>
    </>
  )
}
