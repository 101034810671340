import { IconElement } from '@components/ui/IconElement/IconElement'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'

export type itemPhotoFile = {
  path: string
  name: string
  file?: File
  id?: number
  small?: boolean
  className?: string
}

export interface itemPhotoFileProps extends itemPhotoFile {
  index: number
  onClickDelete(index: number, id?: number): void
}

export const ServicePhotoItem = ({ id, path, name, index, onClickDelete, small, className }: itemPhotoFileProps) => {
  function deletePhoto() {
    onClickDelete(index, id)
  }
  return (
    <div className={`${className} flex !h-auto ${small ? 'flex-row gap-3' : 'flex-col gap-4'}`}>
      <IconElement className={className} icon={path} height={small ? 60 : 233}></IconElement>
      <div
        className={`flex ${
          small ? 'h-full flex-col py-[3px]' : 'flex-row items-center'
        } h-[36px] w-full justify-between`}
      >
        <UiText className='text-grey'>{name}</UiText>
        {small ? (
          <UiText className='cursor-pointer font-extrabold text-red' onClick={deletePhoto}>
            Удалить
          </UiText>
        ) : (
          <UiButton onClick={deletePhoto} color='red' icon>
            <IconElement icon={'/icons/DeleteIcon.svg'}></IconElement>
          </UiButton>
        )}
      </div>
    </div>
  )
}
