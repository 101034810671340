import { ReactNode } from 'react'
import { UiText } from '../UiText/UiText'

export const UiTextField = ({
  name,
  className,
  placeholder = 'Введите текст',
  value,
  onChange,
  disabled,
  type,
}: {
  name?: string | ReactNode
  className?: string
  placeholder?: string

  value?: string
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void
  disabled?: boolean
  type?: 'text' | 'number'
}) => {
  return (
    <div className={`flex flex-col gap-[4px]`}>
      {name && <UiText className='text-grey'>{name}</UiText>}
      <input
        className={`text-field h-[36px] rounded-[3px] border-[1px] border-solid border-green bg-white px-[8px] py-[10px] text-grey focus:outline-none ${className} ${
          disabled ? 'opacity-[0.6]' : ''
        }`}
        type={type ?? 'text'}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
      ></input>
    </div>
  )
}
