import { Park } from '@@types/apiLogicTypes'
import { CenterTable } from '@components/ui/CenterTable/CenterTable'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { RoleNameEnum } from '@config/roles'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

export const ChoiseCenter = observer(() => {
  const {
    auth: { user, choisePark, availableParks },
    park: { parks },
    users: { resetEmployees },
  } = useStore()
  const logic = useLogic()

  function onClickPark(park: Park) {
    resetEmployees()
    choisePark(park)
    localStorage.setItem('parkId', String(park.id))
    navigateTo(AppRoutesPaths.Reception)
  }

  const isAdmin = user?.roles[0].name === RoleNameEnum.superadmin

  useEffect(() => {
    logic.getAvailableParks()
  }, [])

  return (
    <Layout title='Центры' scrollable>
      <div className='flex h-full flex-col gap-[16px] rounded-[3px]'>
        <CenterTable centers={isAdmin ? parks : availableParks ?? []} onClickPark={onClickPark} />
      </div>
    </Layout>
  )
})
