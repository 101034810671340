import { ApiEmployee } from '@@types/apiLogicTypes'
import { Avatar } from 'primereact/avatar'

export const UserAvatar = ({
  className,
  onClick,
  user,
}: {
  className?: string
  onClick: () => void
  user: ApiEmployee
}) => (
  <div className={`flex cursor-pointer flex-col items-center justify-center gap-[2px] ${className}`} onClick={onClick}>
    {user.image ? (
      <Avatar image={user.image} size='normal' shape='circle' className='h-[40px] w-[40px]' />
    ) : (
      <Avatar icon='pi pi-user' size='normal' shape='circle' />
    )}
    <span className='text-center text-sm text-black'> {(user.surname ? user.surname + ' ' : '') + user.name} </span>
  </div>
)
