import { ApiCreateApplicationForm, ApiUser } from '@@types/apiLogicTypes'
import { FormCheckbox } from '@components/ui/Form/FormCheckBox/FormCheckbox'
import FormInput from '@components/ui/Form/FormInput'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { IDisabled } from '@modules/TicketSale/Form/Form'
import { createFormData } from '@utils/createDataForm'
import { formatPhoneNumber } from '@utils/formatForm'
import { formatPhone, normalizePhone } from '@utils/textUtils'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteCompleteEvent } from 'primereact/autocomplete'
import { Avatar } from 'primereact/avatar'
import { Dialog } from 'primereact/dialog'
import { ChangeEvent, FC, useContext, useState } from 'react'
import { Controller, ControllerRenderProps, useFieldArray, useFormContext, useWatch } from 'react-hook-form'

enum AGREEMENTS {
  LOYALTY = 'is_loyalty_agreed',
  INFORMATION = 'is_information_agreed',
}

const ClientPart: FC<IDisabled> = observer(({ disable }) => {
  const methods = useFormContext<ApiCreateApplicationForm>()
  const logic = useLogic()
  const ctx = useContext(ToastContext)
  const [filteredUsers, setFilteredUsers] = useState<ApiUser[]>([])
  const [isUserSelected, setIsUserSelected] = useState<boolean>(false)
  const [modalOptions, setModalOptions] = useState<{ visible: boolean; type?: AGREEMENTS }>({
    visible: false,
    type: undefined,
  })

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

  const [phone, name] = useWatch({
    control: methods.control,
    name: ['order.user.phone', 'order.user.name'],
  })

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'order.user.properties',
  })

  const addProperty = () => {
    append({ name: '', value: '1' })
  }

  const removeProperty = async (index: number) => {
    remove(index)
  }

  const {
    auth: { selectedPark },
  } = useStore()

  const onHide = () => {
    setModalOptions({ visible: false, type: undefined })
  }

  const search = async (event: AutoCompleteCompleteEvent) => {
    let _filteredUsers: ApiUser[] = []

    if (event.query.trim().length < 7) {
      _filteredUsers = []
    } else {
      const response = await logic.searchUserByPhone(event.query)
      //@ts-ignore
      _filteredUsers = response
    }

    setFilteredUsers(_filteredUsers)
  }

  const onChangePhone = (
    field: ControllerRenderProps<ApiCreateApplicationForm, 'order.user.phone'>,
    e: AutoCompleteChangeEvent
  ) => {
    if (e.target.value.phone) {
      setIsUserSelected(true)
      const propertiesArray = e.target.value.properties
        ? Object.entries(e.target.value.properties).map(([key]) => ({
            name: key,
            value: '1',
          }))
        : []
      field.onChange(formatPhoneNumber(e.target.value.phone))
      methods.setValue('order.user.name', e.target.value.name)
      methods.setValue('order.user.id', e.target.value.id)
      methods.setValue('order.user.email', e.target.value.email)
      methods.setValue('order.user.children', e.target.value.children)
      methods.setValue('order.user.bonus', e.target.value.bonus?.sum ?? 0)
      //@ts-ignore
      methods.setValue('order.user.is_loyalty_agreed', Boolean(e.target.value.loyalty_agree))
      //@ts-ignore
      methods.setValue('order.user.is_information_agreed', Boolean(e.target.value.information_agree))
      methods.clearErrors('order.user.name')
      methods.setValue('order.user.properties', propertiesArray)
    } else {
      handleInputChange(field, e)
      setIsUserSelected(false)
    }
  }

  const handleInputChange = (
    field: ControllerRenderProps<ApiCreateApplicationForm, 'order.user.phone'>,
    e: AutoCompleteChangeEvent
  ) => {
    if (isUserSelected) {
      methods.setValue('order.children', [])
      methods.setValue('order.newKid', undefined)
      methods.setValue('order.user.name', '')
      methods.setValue('order.user.id', undefined)
      methods.setValue('order.user.email', '')
      methods.setValue('order.user.children', [])
      methods.setValue('order.user.bonus', 0)
      methods.setValue('order.user.properties', [])
      //@ts-ignore
      methods.setValue('order.user.is_loyalty_agreed', false)
      //@ts-ignore
      methods.setValue('order.user.is_information_agreed', false)
    }
    const inputValue = e.target.value
    const formattedValue = formatPhone(normalizePhone(inputValue))
    if (e.target.value.length <= 16) {
      field.onChange(formattedValue)
    }
  }

  const updateUserAgreement = async (state: boolean, type: AGREEMENTS) => {
    const id = methods.getValues('order.user.id')!
    if (id) {
      const { status, errors } = await logic.updateClient(
        id,
        type === AGREEMENTS.INFORMATION
          ? createFormData({ information_agree: String(+Boolean(state)) })
          : createFormData({ loyalty_agree: String(+Boolean(state)) })
      )
      if (status) {
        onHide()
        //@ts-ignore
        methods.setValue('order.user.' + type, state)
      } else ctx?.toastShowHandler({ status: false, errors: errors })
    } else {
      const formData = createFormData({
        phone: phone,
        name: name,
        email: methods.getValues('order.user.email'),
        park_id: selectedPark!.id,
      })
      type === AGREEMENTS.INFORMATION
        ? formData.append('information_agree', String(+Boolean(state)))
        : formData.append('loyalty_agree', String(+Boolean(state)))

      const { status, errors, data } = await logic.createClient(formData)
      if (status) {
        onHide()
        //@ts-ignore
        methods.setValue('order.user.' + type, state)
        methods.setValue('order.user.id', data?.id)
      } else ctx?.toastShowHandler({ status: false, errors: errors })
    }
  }

  const printAgreement = async () => {
    const pdfUrl =
      modalOptions.type === AGREEMENTS.INFORMATION ? selectedPark?.information_agree : selectedPark?.bonus_program

    if (!pdfUrl) {
      ctx?.toastShowHandler({ status: false, errors: 'Документ не найден.' })
      return
    }

    window.open(pdfUrl, 'PRINT', 'height=400,width=600')
    updateUserAgreement(true, modalOptions.type!)
  }

  const sendAgreement = async () => {
    const formData = new FormData()
    formData.append('phone', methods.getValues('order.user.phone')!)
    formData.append('name', methods.getValues('order.user.name')!)
    formData.append('park_id', String(selectedPark!.id))
    const { status, errors } =
      modalOptions.type === AGREEMENTS.INFORMATION
        ? await logic.sendInformationAgreement(formData)
        : await logic.sendLoyaltyAgreement(formData)
    if (!status) ctx?.toastShowHandler({ status: false, errors: errors })
    else updateUserAgreement(true, modalOptions.type!)
  }

  const onClickCheckoxHandler = (type?: AGREEMENTS) => {
    setModalOptions({ visible: true, type: type })
  }

  const updatePropertiesApi = async () => {
    let id = methods.getValues('order.user.id')!

    if (!id) {
      const formData = createFormData({
        phone: phone,
        name: name,
        email: methods.getValues('order.user.email'),
        park_id: selectedPark!.id,
      })
      const { status, errors, data } = await logic.createClient(formData)
      if (status) {
        onHide()
        methods.setValue('order.user.id', data?.id)
        id = data!.id
      } else {
        ctx?.toastShowHandler({ status: false, errors: errors })
        return
      }
    }

    const currentFields = methods.getValues('order.user.properties')
    const formData = new FormData()
    currentFields.forEach((e) => {
      formData.append(`properties[${e.name}]`, '1')
    })
    await logic.updateClient(id, formData)
  }

  const onChangeHandler = async (e: ChangeEvent<HTMLInputElement>, i: number) => {
    methods.setValue(`order.user.properties.${i}.name`, e.target.value)

    if (e.target.value === '') return
    if (timer) {
      clearTimeout(timer)
    }

    const newTimer = setTimeout(async () => {
      await updatePropertiesApi()
    }, 1500)

    setTimer(newTimer)
  }

  const deleteHandler = async (i: number) => {
    removeProperty(i)
    await updatePropertiesApi()
  }

  return (
    <div className='flex w-full flex-col gap-4'>
      <UiText className='h-[36px] content-center text-lg font-bold'>Гость</UiText>
      <Controller
        name='order.user.phone'
        control={methods.control}
        rules={{ required: 'Value is required.', minLength: 16 }}
        render={({ field, fieldState }) => (
          <div className={`flex flex-col gap-[4px]`}>
            <AutoComplete
              maxLength={16}
              field='name'
              value={field.value}
              suggestions={filteredUsers}
              delay={500}
              completeMethod={search}
              itemTemplate={(e) => ItemTemplate(e)}
              placeholder='Номер телефона'
              onChange={(e) => {
                onChangePhone(field, e)
              }}
              className={cn(
                fieldState.error && 'border-red',
                'text-field h-[36px] w-full rounded-[3px] border border-solid border-green bg-white !p-0 px-[8px] py-[10px] text-grey focus:shadow-none focus:outline-none'
              )}
              disabled={disable}
            />
          </div>
        )}
      />
      <FormInput name='order.user.name' placeholder='Имя' required={true} completed={disable || isUserSelected} />
      <FormInput
        placeholder='E-mail'
        name='order.user.email'
        customPattern={
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        }
        completed={disable || isUserSelected}
      />
      <div className='flex gap-4'>
        <FormCheckbox
          name={'order.user.is_information_agreed'}
          disabled={disable || name.length === 0 || phone.length !== 16}
          title='Согласие с программой рассылок'
          onChange={
            methods.getValues('order.user.is_information_agreed')
              ? () => updateUserAgreement(false, AGREEMENTS.INFORMATION)
              : () => onClickCheckoxHandler(AGREEMENTS.INFORMATION)
          }
        />
      </div>
      <FormCheckbox
        name={'order.user.is_loyalty_agreed'}
        disabled={disable || name.length === 0 || phone.length !== 16}
        title='Согласие с бонусной программой'
        onChange={
          methods.getValues('order.user.is_loyalty_agreed')
            ? () => updateUserAgreement(false, AGREEMENTS.LOYALTY)
            : () => onClickCheckoxHandler(AGREEMENTS.LOYALTY)
        }
      />

      <div className='flex items-center gap-4'>
        <UiText className='text-lg font-bold'>Особенности</UiText>
        <UiButton
          className='h-[36px] w-[36px]'
          onClick={addProperty}
          type='button'
          disabled={disable || name.length === 0 || phone.length !== 16}
        >
          <i className='pi pi-plus text-sm'></i>
        </UiButton>
      </div>

      {fields.map((prop, i) => (
        <div key={prop.id} className='flex gap-4'>
          <FormInput
            className='w-[215px]'
            name={`order.user.properties.${i}.name`}
            placeholder='Введите ключ'
            required
            onChange={(e) => onChangeHandler(e, i)}
            disabled={disable}
          />
          <UiButton
            className='h-[36px] w-[36px]'
            onClick={() => deleteHandler(i)}
            color='red'
            type='button'
            disabled={disable}
          >
            <i className='pi pi-trash text-sm'></i>
          </UiButton>
        </div>
      ))}

      <Dialog
        onHide={onHide}
        visible={modalOptions.visible}
        header={
          <UiText>
            {modalOptions.type === AGREEMENTS.INFORMATION
              ? `Согласие с программой рассылок`
              : 'Согласие с бонусной программой'}
          </UiText>
        }
        contentClassName='flex gap-4'
      >
        <UiButton color='blue' onClick={sendAgreement}>
          Отправить согласие
        </UiButton>
        <UiButton color='green' onClick={printAgreement}>
          Распечатать
        </UiButton>
        <UiButton color='red' onClick={onHide}>
          Отмена
        </UiButton>
      </Dialog>
    </div>
  )
})

export const ItemTemplate = (item: ApiUser, query?: string) => {
  const index = query ? item.name.toLocaleLowerCase().indexOf(query.toLocaleLowerCase().replace(' ', '')) : -1
  const phone = formatPhoneNumber(item.phone!)
  const indexPhone = query ? phone.indexOf(query) : -1
  let parts_array: string[] = []
  let parts_array_phone: string[] = []

  if (index !== -1) {
    const first_part = item.name.substring(0, index)
    const second_part = item.name.substring(index, query!.length)
    const third_part = item.name.substring(index - 1 + query!.length)

    parts_array = [first_part, second_part, third_part]
  }

  if (indexPhone !== -1) {
    const first_part = phone.substring(0, index)
    const second_part = phone.substring(index, query!.length)
    const third_part = phone.substring(index + 1 + query!.length)

    parts_array_phone = [first_part, second_part, third_part]
  }

  return (
    <div className='flex items-center gap-4'>
      <Avatar icon='pi pi-user' size='normal' shape='circle' />
      <div className='gap flex flex-col gap-1'>
        <div>
          {index !== -1 ? (
            parts_array.map((e, i) => (
              <span key={i} className={i === 1 ? 'text-base font-bold' : 'text-base'}>
                {e}
              </span>
            ))
          ) : (
            <UiText className='text-base'>{item.name}</UiText>
          )}
        </div>
        <div>
          {indexPhone !== -1 ? (
            parts_array_phone.map((e, i) => (
              <span key={i} className={i === 1 ? 'text-sm font-bold' : 'text-sm'}>
                {e}
              </span>
            ))
          ) : (
            <UiText className='text-sm'>{phone}</UiText>
          )}
        </div>
      </div>
    </div>
  )
}

export default ClientPart
