import { FC, ReactNode } from 'react'

type KanbanBoardProps = {
  children: ReactNode[]
}

const KanbanBoard: FC<KanbanBoardProps> = ({ children }) => {
  return (
    <section className='flex w-full justify-center overflow-auto'>
      <div
        className={`grid w-full max-w-[1440px] justify-between gap-[16px]`}
        style={{
          gridTemplateColumns: `repeat(${children.length}, minmax(0, 1fr))`,
        }}
      >
        {children}
      </div>
    </section>
  )
}

export default KanbanBoard
