import { SearchField } from '@components/ui/SearchField/SearchField'
import { UiText } from '@components/ui/UiText/UiText'

import { DataThreePrimereact } from '@components/ui/DataTree/DataThreePrimereact'
import { UiTextField } from '@components/ui/UiTextField/UiTextField'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { useInput } from '@hooks/useInput'
import Layout from '@layouts/NonScrollableLayout'
import { TreeNodePrimereact } from '@utils/createDataTreePrimereact'
import { getKeyforObj } from '@utils/getKeyforObj'
import { observer } from 'mobx-react-lite'
import { Dropdown } from 'primereact/dropdown'
import { useContext, useEffect, useState } from 'react'
import { BottomButtons } from './BottomButtons'

export type selectedTreeObjType = {
  name: string
  id: string
  parent: TreeNodePrimereact
}

export const SettingsCategories = observer(() => {
  const logic = useLogic()
  const context = useContext(ToastContext)

  const nameInput = useInput()
  const {
    category: { dataThree, types, categories, handleCategoriesAndTypesChange },
    auth: { selectedPark },
  } = useStore()

  const [selectedLocation, setSelectedLocation] = useState<string | null>(null)

  const [selectedTreeObj, setSelectedTreeObj] = useState<selectedTreeObjType | null>(null)

  async function onClickAdd() {
    let response = false

    if (selectedTreeObj) {
      response = await logic.updateCategory(selectedTreeObj.id, nameInput.value)
    } else if (selectedPark?.id && selectedLocation) {
      const id = Number(getKeyforObj(types ?? {}, selectedLocation)) ?? ''

      response = await logic.createCategory({
        name: nameInput.value,
        type: id,
      })
    }

    context?.toastShowHandler({ status: response })
  }

  function selectedTree(name: string, id: string, parent: TreeNodePrimereact) {
    if (name && id) {
      setSelectedTreeObj({ name, id, parent })
      nameInput.setValue(name ?? '')
      setSelectedLocation(parent.label as any)
    } else {
      setSelectedTreeObj(null)
    }
  }

  async function removeCategory() {
    if (selectedTreeObj) {
      const response = await logic.removeCategory(Number(selectedTreeObj.id))
      context?.toastShowHandler({ status: response })
    } else {
      context?.toastShowHandler({ status: false, errors: 'Нужно выбрать подкатегорию' })
    }
  }

  function onClickAddNewCategory() {
    setSelectedTreeObj(null)
    nameInput.setValue('')
    setSelectedLocation(null)
  }

  useEffect(() => {
    logic.loadCategoryType()
  }, [])

  useEffect(() => {
    logic.loadCategoryByParkId()
  }, [selectedPark])

  useEffect(() => {
    if (types) {
      handleCategoriesAndTypesChange({ types, categories })
    }
  }, [categories, types])

  return (
    <Layout scrollable={true} className='!bg-transparent !p-0'>
      <div className='grid h-full grid-cols-2 gap-[12px]'>
        <div className='flex h-full w-full flex-col rounded-[3px] bg-white p-4'>
          <div className='mb-[35px] flex w-full flex-row items-center gap-[16px]'>
            <UiText size='lg' weight='bold'>
              Категории
            </UiText>
            <SearchField />
          </div>
          {dataThree && (
            <DataThreePrimereact selectedTreeObj={selectedTreeObj} selectedTree={selectedTree} dataThree={dataThree} />
          )}
          <BottomButtons titleSave='Добавить' onClickAdd={onClickAddNewCategory} />
        </div>

        <div className='flex h-full w-full flex-col rounded-[3px] bg-white p-4'>
          <UiText weight='bold' size='lg'>
            Создание / Редактирование
          </UiText>
          <div className='mt-[16px] flex flex-col gap-[16px]'>
            <UiTextField className='w-[100%]' name='Название' placeholder='Выбрать название' {...nameInput} />
            <div className='flex flex-col gap-[4px]'>
              <UiText className='text-grey'>Расположение</UiText>

              {dataThree && (
                <Dropdown
                  disabled={Boolean(selectedTreeObj)}
                  value={selectedLocation}
                  onChange={(e) => setSelectedLocation(e.value)}
                  options={[...dataThree[0].children.map((item) => item.label)]}
                  optionLabel=''
                  placeholder='Выбрать расположение'
                  className='md:w-14rem worker w-full border-green'
                  panelClassName='worker-panel'
                  color='green'
                />
              )}
            </div>
          </div>
          <BottomButtons titleSave='Сохранить' onClickDelete={removeCategory} onClickAdd={onClickAdd} />
        </div>
      </div>
    </Layout>
  )
})
