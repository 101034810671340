import { ApiEmployee, ImagePark, Park } from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'
import { RoleNameEnum } from '@config/roles'

import { action, observable } from 'mobx'

export class AuthStore {
  @observable
  token: string | null = null

  @observable
  user: ApiEmployee | null = null

  @observable
  selectedPark: Park | null = null

  @observable
  availableParks: Park[] | null = null

  @observable
  roles: RoleNameEnum[] | null = null

  @observable
  logInLoading = LoadState.Initial

  @observable
  registrationLoading = LoadState.Initial

  @observable
  getPark = (parkId: number) => {
    if (this?.user?.parks) {
      const findIndex = this?.user?.parks.findIndex((item) => item.id === parkId)

      if (findIndex >= 0) {
        return this?.user?.parks[findIndex]
      }
    } else {
      return
    }
  }

  editPark = (park: Park) => {
    if (this.user?.parks) {
      const findIndex = this?.user?.parks.findIndex((item) => item.id === park.id)

      if (findIndex >= 0) {
        this.user.parks = [
          ...this.user.parks.slice(0, findIndex),
          {
            ...park,
            city: this?.user?.parks[findIndex].city,
            images: this?.user?.parks[findIndex].images,
          },
          ...this.user.parks.slice(findIndex + 1),
        ]
      }
    }
  }

  choisePark = (park: Park) => {
    this.selectedPark = park
  }

  addImg = (imgObj: ImagePark) => {
    this.selectedPark?.images.push(imgObj)
  }

  @action
  updateUser = (user: ApiEmployee) => {
    this.user = user
  }
}
