import { Loader } from '@components/ui/Loader/Loader'
import { AppRoutesPaths } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import Constructor from '@modules/Schedule/Constructor/Constructor'
import { IEvent } from '@modules/Schedule/SchedulePage'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

const startDate = new Date()
const endDate = new Date()

startDate.setHours(10, 0, 0)
endDate.setHours(12, 0, 0)

export const ConstructorPage = observer(() => {
  const {
    auth: { selectedPark },
  } = useStore()

  const [isParkDataLoaded, setIsParkDataLoaded] = useState(true)
  const [isEventLoaded, setIsEventLoaded] = useState(true)
  const [event, setEvent] = useState<IEvent | null>(null)
  const logic = useLogic()
  const navigate = useNavigate()
  const context = useContext(ToastContext)
  const { id } = useParams()

  useEffect(() => {
    if (!selectedPark) return
    const init = async () => {
      return await Promise.all([logic.loadHolidays(), logic.loadServices(), logic.loadAnimators(), logic.loadRooms()])
    }
    init().then(() => setIsParkDataLoaded(false))
  }, [selectedPark])

  const changeStatusOfEvent = async (status: number) => {
    setEvent((prev) => {
      if (!prev) return prev
      return { ...prev, status: status }
    })

    await loadApplication()
  }

  const loadApplication = async () => {
    setIsEventLoaded(true)
    if (!id) {
      setIsEventLoaded(false)
      return
    }
    const { data } = await logic.loadApplicationById(+id!)
    data &&
      setEvent({
        ...data,
        start: data.schedule ? new Date(data.schedule.starts_at) : undefined,
        end: data.schedule ? new Date(data.schedule.ends_at) : undefined,
      })
    setIsEventLoaded(false)
  }

  useEffect(() => {
    setIsEventLoaded(true)
    if (!id) {
      setEvent(null)
      setIsEventLoaded(false)
      return
    }

    logic.loadApplicationById(+id).then((data) => {
      data.data &&
        setEvent({
          ...data.data,
          start: data.data.schedule ? new Date(data.data.schedule.starts_at) : undefined,
          end: data.data.schedule ? new Date(data.data.schedule.ends_at) : undefined,
        })
      setIsEventLoaded(false)
    })
  }, [id])

  const sendForm = async (formData: FormData) => {
    if (event) {
      const { status, errors } = await logic.updateAdminApplication(event.id, formData)
      context?.toastShowHandler({ status: status, errors: errors })
    } else {
      const { status, errors, data } = await logic.sendAdminApplication(formData)
      context?.toastShowHandler({ status: status, errors: errors })

      if (data) {
        const path = generatePath(AppRoutesPaths.ConstructorEdit, {
          id: String(data.id),
        })
        navigate(path)
      }
    }
  }

  const onCloseConstructor = async () => {
    window.history.back()
  }

  return (
    <Layout className='!p-0'>
      {isEventLoaded || isParkDataLoaded ? (
        <Loader className='!bg-white' />
      ) : (
        <Constructor
          key={event?.id}
          sendFormHandler={sendForm}
          initialState={event}
          initialDate={{
            start: window.sessionStorage.getItem('event_start')
              ? new Date(window.sessionStorage.getItem('event_start')!)
              : startDate,
            end: window.sessionStorage.getItem('event_end')
              ? new Date(window.sessionStorage.getItem('event_end')!)
              : endDate,
            date: window.sessionStorage.getItem('event_date')
              ? new Date(window.sessionStorage.getItem('event_date')!)
              : new Date(),
          }}
          changeEventStatus={changeStatusOfEvent}
          hideHandler={onCloseConstructor}
          loadApplication={loadApplication}
        />
      )}
    </Layout>
  )
})
