import { ApiPaymentOrder, ApiUser } from '@@types/apiLogicTypes'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths } from '@config/navigation'
import { statusSwitcher } from '@utils/tableUtils'
import { formatPhone } from '@utils/textUtils'
import { parseTimeFromText } from '@utils/times'
import cn from 'classnames'
import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { FC } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { IMain } from '../Main'

export const TicketTable: FC<Pick<IMain, 'contact'>> = ({ contact }) => {
  const navigate = useNavigate()

  const childTemplate = (e: ApiPaymentOrder) => {
    return <span>{e.children?.map((e) => e.name).join(', ')}</span>
  }
  const dateBodyTemplate = (rowData: ApiUser) => {
    return moment(rowData.created_at).format('DD.MM.YYYY')
  }
  const holidayTemplate = (e: ApiPaymentOrder) => {
    return <span>{e.holiday ? e.holiday.name : '-'}</span>
  }

  const toTicketSaleHandler = () => {
    window.sessionStorage.setItem('contact_name', contact.name)
    window.sessionStorage.setItem('contact_phone', formatPhone(contact.phone!))

    contact.children?.length && window.sessionStorage.setItem('contact_children', JSON.stringify(contact.children))
    contact.email && window.sessionStorage.setItem('contact_email', contact.email)

    navigate(AppRoutesPaths.TicketSale)
  }

  const toConstructorHandler = () => {
    window.sessionStorage.setItem('contact_name', contact.name)
    window.sessionStorage.setItem('contact_phone', formatPhone(contact.phone!))

    contact.children?.length && window.sessionStorage.setItem('contact_children', JSON.stringify(contact.children))
    contact.email && window.sessionStorage.setItem('contact_email', contact.email)

    navigate(AppRoutesPaths.Constructor)
  }

  const onRowDoubleClickHandler = (e: ApiPaymentOrder) => {
    if (e.tickets.length > 0) {
      const path = generatePath(AppRoutesPaths.TicketSaleEdit, {
        //@ts-ignore
        id: String(e.payment.id),
      })

      navigate(path)
    } else if (e.holiday) {
      const path = generatePath(AppRoutesPaths.ConstructorEdit, {
        id: String(e.id),
      })

      navigate(path)
    }
  }

  return (
    <section className='flex flex-col gap-[10px]'>
      <div className='flex items-center gap-5'>
        <div className='flex items-center'>
          <UiText className='mr-[42px] text-lg font-bold'>История посещений</UiText>
          <UiButton className='mr-3 w-[193px]' type='button' onClick={toTicketSaleHandler}>
            Продать билет
          </UiButton>
          <UiButton className='w-[193px]' color='blue' type='button' onClick={toConstructorHandler}>
            Продать праздник
          </UiButton>
        </div>
      </div>
      <DataTable
        value={contact.orders}
        showGridlines
        size={'small'}
        filterLocale='ru'
        className={cn('bg-white text-base', 'ui-paginator-table')}
        style={{ fontSize: '12px' }}
        removableSort
        emptyMessage='Заказы не найдены'
        scrollable
        scrollHeight='flex'
        rowClassName={(e) => {
          return statusSwitcher(e).color + `${e.tickets.length > 0 || e.holiday ? ' cursor-pointer' : ''}`
        }}
        paginator
        rows={15}
        //@ts-ignore
        onRowDoubleClick={(e) => onRowDoubleClickHandler(e.data)}
      >
        <Column filterField='created_at' header='Дата' dataType='date' body={dateBodyTemplate} />
        <Column field='id' header='Номер' />
        <Column field='price' header='Сумма' />
        <Column field='holidays' header='Праздник' body={holidayTemplate} />
        <Column header='Статус' body={(e) => <span>{statusSwitcher(e).name}</span>} />
        <Column field='children' header='Дети' body={(e) => childTemplate(e)} />
      </DataTable>
    </section>
  )
}

export const calcDiff = (e: any) => {
  if (!e.tickets[0]?.unit?.text) return
  const duration = parseTimeFromText(e.tickets[0]?.unit?.text)
  const timeLeft = moment(e.created_at).add(duration, 'm')

  const serverTimezone = 'Europe/Moscow'
  const localTimezone = moment.tz.guess()
  const serverMoment = moment.tz(timeLeft, serverTimezone)
  const localEndsAtObj = serverMoment.clone().tz(localTimezone)

  const diff = localEndsAtObj.diff(moment(), 'm')

  return diff
}
