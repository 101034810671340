import Layout from '@layouts/NonScrollableLayout'
import cn from 'classnames'
import { ReactNode } from 'react'
import { MainTable, MainTableProps } from '../ContainerTable/MainTable'
import { SettingsHeaderBtn } from '../SettingsHeaderBtn/SettingsHeaderBtn'

export type TablePageContainerProps = {
  mainTitle: string
  textAdd: string
  textArchive: string
  tableProps: MainTableProps
  onClickAdd?: () => void
  isAdmin?: boolean
  children?: ReactNode
}

export const TablePageContainer = ({
  textAdd,
  textArchive,
  onClickAdd,
  mainTitle,
  tableProps,
  isAdmin,
  children,
}: TablePageContainerProps) => {
  return (
    <Layout title={mainTitle}>
      <div className={cn('flex h-full flex-col gap-[24px] overflow-scroll bg-white', 'no-scroll')}>
        <div className={cn('flex h-full flex-col gap-[16px] overflow-x-auto rounded-[3px]')}>
          <SettingsHeaderBtn textAdd={textAdd} onClickAdd={onClickAdd} textArchive={textArchive} isAdmin={isAdmin} />
          {!children ? <MainTable {...tableProps} /> : children}
        </div>
      </div>
    </Layout>
  )
}
