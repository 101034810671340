export enum RoleNameEnum {
  client = 'user',
  superadmin = 'superadmin',
  manager = 'manager',
  animator = 'animator',
  administrator = 'administrator',
  'trainee-instructor' = 'trainee-instructor',
  'administrator-level-1' = 'administrator-level-1',
  'administrator-level-2' = 'administrator-level-2',
  'senior-administrator' = 'senior-administrator',
  bartender = 'bartender',
  'chief-animator' = 'chief-animator',
  'deputy-manager' = 'deputy-manager',
  'kitchen-staff' = 'kitchen-staff',
  accountant = 'accountant',
  default = '',
}

export const secondaryPool = [
  RoleNameEnum.superadmin,
  RoleNameEnum.manager,
  RoleNameEnum.administrator,
  RoleNameEnum['trainee-instructor'],
  RoleNameEnum['deputy-manager'],
]

export const mainPool = [
  ...secondaryPool,
  RoleNameEnum['administrator-level-1'],
  RoleNameEnum['administrator-level-2'],
  RoleNameEnum['senior-administrator'],
]

export const allRolesPool = Object.values(RoleNameEnum)
