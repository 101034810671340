import { itemPhotoFile } from '@components/Settings/ServicePhotoItem'
import { EditCenter } from '@components/ui/EditCenter/EditCenter'
import { SkalalandSpiner } from '@components/ui/SkalalandSpiner/SkalalandSpiner'
import { ToastContext } from '@context/toastContext'
import { useBoolState } from '@hooks/commonHooks'
import { useLogic, useStore } from '@hooks/storeHook'
import { useInput } from '@hooks/useInput'
import { createFormData } from '@utils/createDataForm'
import { getImagesServices } from '@utils/getImagesServices'
import { normalizePhone } from '@utils/textUtils'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useState } from 'react'

export const EditAvailableCenters = observer(() => {
  const context = useContext(ToastContext)
  const {
    auth: { selectedPark },
  } = useStore()

  const [filesDeleteId, setFilesDeleteId] = useState<number[]>([])
  const [files, setFiles] = useState<itemPhotoFile[]>(selectedPark ? getImagesServices(selectedPark.images) : [])

  useEffect(() => {
    setFiles(getImagesServices(selectedPark?.images))
  }, [selectedPark])

  const phone = useInput(String(selectedPark?.phone ?? ''), true)

  const worktime = useInput(selectedPark?.worktime ?? '')
  const vk = selectedPark?.vk ? useInput(selectedPark.vk) : useInput('')
  const whatsapp = useInput(String(selectedPark?.whatsapp ?? ''), true)
  const name = useInput(selectedPark?.name ?? '')
  const title = useInput(selectedPark?.title ?? '')
  const yooKassaShopId = useInput(selectedPark?.yookassa_shop_id ? String(selectedPark?.yookassa_shop_id) : '')
  const form1Title = useInput(selectedPark?.fh_1 ?? '')
  const form3Title = useInput(selectedPark?.fh_3 ?? '')
  const yooKassaKey = useInput(selectedPark?.yookassa_key ? String(selectedPark?.yookassa_key) : '')
  const description = useInput(selectedPark?.description ?? '')
  const abbreviation = useInput(selectedPark?.company_abbreviation ?? '')
  const isShowKitchen = useBoolState(Boolean(selectedPark?.show_kitchen ?? false))
  const isShowDr = useBoolState(Boolean(selectedPark?.show_dr ?? false))
  const isShowTicketPayment = useBoolState(Boolean(selectedPark?.show_ticket_payment ?? false))
  const isShowOnlinePayment = useBoolState(Boolean(selectedPark?.show_online_payment ?? false))
  const whatsappId = useInput(selectedPark?.whatsapp_id_instance ?? '')
  const whatsappToken = useInput(selectedPark?.whatsapp_token ?? '')
  const yandexId = useInput(selectedPark?.yandex_map_id ?? '')

  const [privacyFile, setPrivacyFile] = useState<File | null>(null)
  const [termsOfUseFile, setTermsOfUseFile] = useState<File | null>(null)
  const [bonusProgramFile, setBonusProgramFile] = useState<File | null>(null)
  const [safetyPrecautionsFile, setSafetyPrecautionsFile] = useState<File | null>(null)
  const [requisitesFile, setRequisitesFile] = useState<File | null>(null)
  const [informationFile, setInformationFile] = useState<File | null>(null)

  const logic = useLogic()

  const [isDisabledFile, setDisabledFile] = useState<boolean>(false)

  async function editPark() {
    if (!selectedPark?.id) {
      return
    }
    const form = createFormData({
      vk: vk.value,
      whatsapp: normalizePhone(whatsapp.value),
      worktime: worktime.value,
      name: name.value,
      title: title.value,
      description: description.value,
      phone: normalizePhone(phone.value),
      delete_images: filesDeleteId,
      show_kitchen: +isShowKitchen[0],
      company_abbreviation: abbreviation.value,
      show_ticket_payment: +isShowTicketPayment[0],
      show_online_payment: +isShowOnlinePayment[0],
      show_dr: +isShowDr[0],
      yookassa_shop_id: yooKassaShopId.value,
      yookassa_key: yooKassaKey.value,
      fh_1: form1Title.value,
      fh_3: form3Title.value,
      whatsapp_id_instance: whatsappId.value,
      whatsapp_token: whatsappToken.value,
      yandex_map_id: yandexId.value,
    })

    privacyFile && form.append('privacy_policy', privacyFile)
    termsOfUseFile && form.append('terms_of_use', termsOfUseFile)
    bonusProgramFile && form.append('bonus_program', bonusProgramFile)
    safetyPrecautionsFile && form.append('safety_precautions', safetyPrecautionsFile)
    requisitesFile && form.append('requisites', requisitesFile)
    informationFile && form.append('information_agree', informationFile)

    for (let i = 0; i < files.length; i++) {
      if (files[i].file) {
        form.append(`images[]`, files[i]?.file ?? '')
      }
    }

    const { status, errors } = await logic.editCenter(form, selectedPark.id)

    context?.toastShowHandler({ status, errors })
  }

  function disabledFiles(isDisabled: boolean) {
    setDisabledFile(isDisabled)
  }

  function addFile(item: itemPhotoFile) {
    setFiles((prev) => [...prev, item])
  }
  function onClickDeleteFile(indexFileDelete: number, id?: number) {
    setFiles((prev) => prev.filter((_, index) => index !== indexFileDelete))
    if (id) {
      setFilesDeleteId((prev) => [...prev, id])
    }
  }

  if (!selectedPark) {
    return <SkalalandSpiner />
  }

  return (
    <>
      <EditCenter
        name={name}
        worktime={worktime}
        vk={vk}
        phone={phone}
        whatsapp={whatsapp}
        abbreviation={abbreviation}
        title={title}
        id={yooKassaShopId}
        centerKey={yooKassaKey}
        description={description}
        editPark={editPark}
        isDisabledFile={isDisabledFile}
        setDisabledFile={disabledFiles}
        files={files}
        setUploadFile={addFile}
        onClickDeleteFile={onClickDeleteFile}
        isShowKitchen={isShowKitchen}
        isShowTicketPayment={isShowTicketPayment}
        isShowOnlinePayment={isShowOnlinePayment}
        isShowDr={isShowDr}
        form1Title={form1Title}
        form3Title={form3Title}
        privacyFile={privacyFile}
        setPrivacyFile={setPrivacyFile}
        termsOfUseFile={termsOfUseFile}
        setTermsOfUseFile={setTermsOfUseFile}
        informationFile={informationFile}
        setInformationFile={setInformationFile}
        bonusProgramFile={bonusProgramFile}
        setBonusProgramFile={setBonusProgramFile}
        safetyPrecautionsFile={safetyPrecautionsFile}
        setSafetyPrecautionsFile={setSafetyPrecautionsFile}
        requisitesFile={requisitesFile}
        setRequisitesFile={setRequisitesFile}
        selectedPark={selectedPark}
        whatsappId={whatsappId}
        whatsappToken={whatsappToken}
        yandexId={yandexId}
      />
    </>
  )
})
