import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { GetDataCheck } from '@logic/kkmLogic'
import { TCheck } from '@modules/TicketSale/TicketSale'
import { Dialog } from 'primereact/dialog'
import { FC, useContext, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import EventForm, { IEventForm } from './EventForm/EventForm'
import NotesForm from './NotesForm/NotesForm'

interface IConstructor extends IEventForm {
  hideHandler: () => void
  changeEventStatus: (status: number) => void
  loadApplication: () => Promise<any>
}

const Constructor: FC<IConstructor> = (props) => {
  const navigate = useNavigate()

  const ctx = useContext(ToastContext)
  const [visible, setVisible] = useState(false)
  const [strChecks, setStrChecks] = useState<TCheck[]>([])

  console.log(strChecks)

  const toContactPageHandler = (flag?: string) => {
    flag = flag || ''

    const path = generatePath(AppRoutesPaths.ContactEdit, {
      id: String(props.initialState!.user.id),
    })
    navigate(path + flag)
  }

  const getDataCheckHandler = async (fiskalNumber: number) => {
    await GetDataCheck(fiskalNumber, async (result: any) => {
      if (result.Status === 0) {
        setStrChecks([{ fiskalNumber: fiskalNumber, slip: result.Slip }])
        setVisible(true)
      }
      if (result.Status === 2) ctx?.toastShowHandler({ status: false, errors: result.Error })
      else if (result.Status === 3) ctx?.toastShowHandler({ status: false, errors: 'Данные не найдены' })
    })
  }

  const printCheckHandler = async (fiskalNumber: number) => {
    await GetDataCheck(
      fiskalNumber,
      async (result: any) => {
        if (result.Status === 0) ctx?.toastShowHandler({ status: true, summary: 'Успешно' })
        if (result.Status === 2) ctx?.toastShowHandler({ status: false, errors: result.Error })
        else if (result.Status === 3) ctx?.toastShowHandler({ status: false, errors: 'Данные не найдены' })
      },
      1
    )
  }

  return (
    <div className='flex min-h-full w-full flex-col items-center gap-4 bg-grey-hover'>
      <div className='flex w-full items-center gap-4 bg-white px-4 py-3'>
        {!props.initialState && (
          <>
            <i className='pi pi-angle-left cursor-pointer text-grey' onClick={props.hideHandler}></i>
            <UiText>Новая заявка</UiText>
          </>
        )}
        {props.initialState && (
          <UiText>
            <span className='cursor-pointer text-blue' onClick={() => toContactPageHandler()}>
              {props.initialState.user.name}
            </span>{' '}
            –{' '}
            <span className='cursor-pointer text-blue' onClick={() => toContactPageHandler('#payments')}>
              Продажи
            </span>{' '}
            – Заявка №{props.initialState.order_id ?? props.initialState.id}
          </UiText>
        )}
      </div>
      <div
        className={`grid ${
          props.initialState ? 'grid-cols-[2fr_1fr]' : 'grid-cols-1'
        } relative h-full w-full max-w-[1440px] gap-3 overflow-hidden bg-grey-hover`}
      >
        <EventForm {...props} />
        {props.initialState && <NotesForm initialState={props.initialState} logOnClick={getDataCheckHandler} />}
      </div>
      <Dialog
        header='Чек'
        visible={visible}
        onHide={() => setVisible(false)}
        headerClassName='py-2 '
        contentClassName='pt-0 h-fit pb-4'
      >
        <div className='flex gap-8'>
          {props.initialState?.payments?.map((e) => {
            if (!e.checks || e.checks.length === 0) return <></>
            return e.checks.map((c) => (
              <div className='flex max-w-[350px] flex-col' key={c.id}>
                <pre className='check m-0 max-w-[350px] whitespace-pre-wrap'>{c.slip}</pre>
                <UiButton
                  className='h-[30px] w-[20px]'
                  onClick={() => printCheckHandler(+c.fiskal_number)}
                  color='blue'
                >
                  <i className='pi pi-print text-sm' />
                </UiButton>
              </div>
            ))
          })}
        </div>
      </Dialog>
    </div>
  )
}

export default Constructor
