import { ApiPayment } from '@@types/apiLogicTypes'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import {
  GetDataCheck,
  IKkmClient,
  IKkmItem,
  IKkmRegisterCheckResult,
  RegisterCheck,
  ReturnPaymentByPaymentCard,
} from '@logic/kkmLogic'
import { createFormData } from '@utils/createDataForm'
import { formatFullName, formatPhone } from '@utils/textUtils'
import { observer } from 'mobx-react-lite'
import { useCallback, useContext, useState } from 'react'

interface IRefoundButton {
  payment: ApiPayment
  loadPayment: () => Promise<void>
}

export const RefountButton = observer(({ payment, loadPayment }: IRefoundButton) => {
  const [isRefundButtonLoading, setIsRefundButtonLoading] = useState(false)
  const ctx = useContext(ToastContext)
  const logic = useLogic()

  const {
    auth: { user },
  } = useStore()

  const isPreOrderInclude = payment.sum < payment.order.price
  const isOrderPrePaid = payment.order.status === 5
  const isOrderPaid = payment.order.status === 4
  const operatorName = formatFullName(user!.name, user?.surname, user?.patronymic)

  const createArrayOfServicesAndTickets = useCallback(
    (method: 2 | 4) => {
      return [...payment.order.tickets, ...payment.order.services]
        .filter((e) => e.count > 0)
        .flatMap((e) =>
          Array(e.count).fill({
            name: e.name,
            quantity: 1,
            price: +e.price,
            amount: e.discount_price ?? +e.price,
            type: 4,
            measure: 0,
            signMethodCalculation: method,
          })
        )
    },
    [payment.order.tickets, payment.order.services]
  )

  const processBonusSum = (kkmItems: IKkmItem[], bonusSum: number) => {
    kkmItems.forEach((item) => {
      if (bonusSum <= 0) return
      const maxDeductible = item.amount - 1
      const deduction = Math.min(maxDeductible, bonusSum)
      item.amount -= deduction
      bonusSum -= deduction
    })
  }

  const prePayRefoundHandler = async () => {
    const kkmClient: IKkmClient = {
      name: payment.order.user!.name,
      phone: formatPhone(payment.order.user!.phone!),
      email: payment.order.user?.email,
    }

    if (payment.type === 0 || payment.type === 1) {
      const bonusSum = payment.order.bonus_sum
      const kkmItems = createArrayOfServicesAndTickets(2)
      processBonusSum(kkmItems, bonusSum)

      const paymentType = payment.type
      if (paymentType == 0) {
        await RegisterCheck({
          typeCheck: 1,
          operatorName: operatorName,
          items: kkmItems,
          client: kkmClient,
          sum: payment.sum,
          callback: (result: IKkmRegisterCheckResult) => refoundCallback(result),
          type: 'cash',
          advancePayment: 0,
          credit: payment.order.price - payment.sum,
        })
      } else {
        await ReturnPaymentByPaymentCard(payment.sum, payment.payment_id, (result: any) =>
          refundByPaymentCardCallback(result, payment.sum, kkmItems, kkmClient, 0, payment.order.price - payment.sum)
        )
      }
      //3-й кейс: заказ предоплачен ю-кассой
    } else if (payment.type === 7 && payment.checks?.length === 1) {
      const { status } = await logic.refoundPaymentTicket(new FormData(), payment.id)
      if (!status) {
        ctx?.toastShowHandler({
          status: false,
          errors: 'Ошибка при возврате платежа на сервере',
        })
        setIsRefundButtonLoading(false)
        return
      }

      const bonusSum = payment.order.bonus_sum
      const kkmItems = createArrayOfServicesAndTickets(2)
      processBonusSum(kkmItems, bonusSum)

      await RegisterCheck({
        typeCheck: 1,
        operatorName: operatorName,
        items: kkmItems,
        client: kkmClient,
        sum: payment.sum,
        callback: (result: IKkmRegisterCheckResult) => refoundCallback(result),
        type: 'online',
        advancePayment: 0,
        credit: payment.order.price - payment.sum,
      })
    }
  }

  const refoundHandler = async () => {
    setIsRefundButtonLoading(true)

    const kkmClient: IKkmClient = {
      name: payment.order.user!.name,
      phone: formatPhone(payment.order.user!.phone!),
      email: payment.order.user?.email,
    }

    //1-й кейс: заказ оплачен полностью 1-м платежом
    if (isOrderPaid && payment.order.payments.length === 1) {
      const bonusSum = payment.order.bonus_sum
      const kkmItems = createArrayOfServicesAndTickets(4)
      processBonusSum(kkmItems, bonusSum)

      const paymentType = payment.type

      if (paymentType == 0) {
        await RegisterCheck({
          typeCheck: 1,
          operatorName: operatorName,
          items: kkmItems,
          client: kkmClient,
          sum: payment.order.price,
          callback: (result: IKkmRegisterCheckResult) => refoundCallback(result),
          type: 'cash',
          advancePayment: 0,
          credit: 0,
        })
      } else {
        await ReturnPaymentByPaymentCard(payment.order.price, payment.id, (result: any) =>
          refundByPaymentCardCallback(result, payment.order.price, kkmItems, kkmClient, 0, 0)
        )
      }
      //4-й кейс: возврат постоплаты
    } else if (payment.order.payments.length > 1 && payment.order.payments.filter((e) => e.status === 2).length === 1) {
      const bonusSum = payment.order.bonus_sum
      const kkmItems = createArrayOfServicesAndTickets(2)
      processBonusSum(kkmItems, bonusSum)

      //платеж постоплаты
      const lastSuccessfulPayment = payment.order.payments.filter((e) => e.status === 2).at(-1)!

      if (lastSuccessfulPayment.type == 0) {
        await RegisterCheck({
          typeCheck: 1,
          operatorName: operatorName,
          items: kkmItems,
          client: kkmClient,
          sum: lastSuccessfulPayment.sum,
          callback: (result: IKkmRegisterCheckResult) => refoundCallback(result, lastSuccessfulPayment),
          type: 'cash',
          advancePayment: payment.order.price - lastSuccessfulPayment.sum,
          credit: 0,
        })
      } else {
        await ReturnPaymentByPaymentCard(lastSuccessfulPayment!.sum, lastSuccessfulPayment.id, (result: any) =>
          refundByPaymentCardCallback(
            result,
            lastSuccessfulPayment.sum,
            kkmItems,
            kkmClient,
            payment.order.price - lastSuccessfulPayment.sum,
            0,
            lastSuccessfulPayment
          )
        )
      }
    }
  }

  const refoundCallback = async (result: IKkmRegisterCheckResult, new_payment: ApiPayment = payment) => {
    if (result.Status === 0) {
      if (new_payment.type !== 7) {
        const { status } = await logic.refoundPaymentTicket(new FormData(), new_payment.id)
        !status &&
          ctx?.toastShowHandler({
            status: false,
            errors: 'Произошла ошибка при возврате продажи. Пожалуйста, обратитесь к администратору',
          })
      }
      await setCheckHandler(result.CheckNumber, new_payment.id, 1)
    }
    if (result.Status === 2) {
      ctx?.toastShowHandler({ status: false, errors: result.Error })
    } else if (result.Status === 3) {
      ctx?.toastShowHandler({ status: false, errors: 'Данные не найдены' })
    }
    setIsRefundButtonLoading(false)
  }

  const refundByPaymentCardCallback = async (
    result: IKkmRegisterCheckResult,
    sum: number,
    kkmItems: IKkmItem[],
    kkmClient: IKkmClient,
    advancePayment: number,
    credit: number,
    new_payment: ApiPayment = payment
  ) => {
    if (result.Status === 0) {
      await RegisterCheck({
        typeCheck: 1,
        operatorName: operatorName,
        items: kkmItems,
        client: kkmClient,
        sum: sum,
        callback: (result: IKkmRegisterCheckResult) => refoundCallback(result, new_payment),
        type: 'online',
        advancePayment: advancePayment,
        credit: credit,
      })
    } else if (result.Status === 2) {
      ctx?.toastShowHandler({ status: false, errors: result.Error })
      setIsRefundButtonLoading(false)
    } else if (result.Status === 3) {
      ctx?.toastShowHandler({ status: false, errors: 'Устройство не найдено' })
      setIsRefundButtonLoading(false)
    }
  }

  const setCheckHandler = async (fiskalNumber: number, id: number, success: 0 | 1) => {
    await GetDataCheck(fiskalNumber, async (result: any) => {
      if (result.Status === 0) {
        const formData = createFormData({ slip: result.Slip, id: id, success: success, fiskal_number: fiskalNumber })
        const { status, errors } = await logic.checkSetTicket(formData, id)
        if (status) {
          await loadPayment()
        } else ctx?.toastShowHandler({ status: false, errors: errors })
      }
      if (result.Status === 2) ctx?.toastShowHandler({ status: false, errors: result.Error })
      else if (result.Status === 3) ctx?.toastShowHandler({ status: false, errors: 'Данные не найдены' })
    })
  }

  return (
    <>
      {isPreOrderInclude && (
        <UiButton
          type='button'
          className='w-[100px]'
          color='yellow'
          onClick={() => prePayRefoundHandler()}
          isLoading={isRefundButtonLoading}
          disabled={
            payment.order.payments.filter((e) => e.status !== 1).length === 0 ||
            payment.order.payments.filter((e) => e.status !== 1)[0]?.status === 4 ||
            (payment.type === 7 && payment.checks?.length === 0)
          }
        >
          возврат предоплаты
        </UiButton>
      )}
      <UiButton
        type='button'
        className='w-[100px]'
        color='yellow'
        onClick={() => refoundHandler()}
        isLoading={isRefundButtonLoading}
        disabled={
          !payment.order.pin_activated ||
          payment.order.status === 3 ||
          payment.order.payments.filter((e) => e.status !== 4).length === 0 ||
          isOrderPrePaid ||
          (isPreOrderInclude && payment.order.payments.filter((e) => e.status === 4).length !== 1)
        }
      >
        {isPreOrderInclude ? 'возврат постоплаты' : 'возврат'}
      </UiButton>
    </>
  )
})
