import { ArchiveContext } from '@context/archiveContext'
import { useContext } from 'react'
import { TableFilter } from '../TableFilter/TableFilter'
import { TH } from './ContainerTable'

export type MainTableProps = {
  children: React.ReactNode
  arrayHeadName: string[]
  isDeletePhoto?: boolean
  isDeleteBtns?: boolean
  isDeleteCheckbox?: boolean
}
export const MainTable = (props: MainTableProps) => {
  const context = useContext(ArchiveContext)
  return (
    <table className='table-auto border-collapse border-[1px] border-solid border-grey-secondary'>
      <thead>
        <tr>
          {!props.isDeletePhoto && <th className='w-[36px]'></th>}
          {props.arrayHeadName.map((item, i) => {
            return <TableFilter key={i} placeholder={item} />
          })}
          {!props.isDeleteCheckbox && !context?.inArchive && <TH width={49}>Сайт</TH>}
          {!props.isDeleteBtns && <TH width={172}></TH>}
        </tr>
      </thead>
      <tbody>{props.children}</tbody>
    </table>
  )
}
