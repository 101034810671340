import cn from 'classnames'
import { InputOtp, InputOtpProps } from 'primereact/inputotp'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface IFormOtp extends InputOtpProps {
  name: string
}

export const FormOtp: FC<IFormOtp> = ({ name, length = 4, ...props }) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: props.required && 'Поле обязательно', minLength: length }}
      render={({ field, fieldState }) => (
        <InputOtp
          id={field.name}
          value={field.value}
          onChange={(e) => field.onChange(e.value)}
          {...props}
          className={cn(
            `${
              fieldState.error && 'incorrect bg-red'
            } md:w-14rem worker flex h-[36px] w-full items-center border-green`,
            props.className
          )}
          required={undefined}
        />
      )}
    />
  )
}
