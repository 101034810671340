import { ApiTicket } from '@@types/apiLogicTypes'
import { IPageType } from '@@types/moduleTypes'
import { FormCheckbox } from '@components/ui/Form/FormCheckBox/FormCheckbox'
import FormDropdown from '@components/ui/Form/FormDropdown'
import FormInput from '@components/ui/Form/FormInput'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { TTicketForm } from './types'

const filterMap = {
  Часы: 'час',
  Минуты: 'мин',
  'За ребенка': 'человек',
}

export const FormPage = observer(({ isAdmin }: IPageType) => {
  const [initialValue, setInitialValue] = useState<ApiTicket | undefined>()

  const {
    auth: { selectedPark },
    services: { units, measures },
    ticket: { types },
  } = useStore()

  const methods = useForm<TTicketForm>({
    defaultValues: {
      name: '',
      unit: null,
      measure: null,
      category: null,
      weekday_price: 0,
      weekend_price: 0,
      sort: 0,
      active: true,
    },
  })

  const [isLoading, setIsLoading] = useState(true)

  const measure = useWatch({
    name: 'measure',
    control: methods.control,
  })

  const unit = useWatch({
    name: 'unit',
    control: methods.control,
  })

  useEffect(() => {
    if (!initialValue || !units || !measures || !types) return

    methods.reset({
      name: initialValue.name,
      unit: units.find((e) => e.text === initialValue.unit.text),
      measure: measures.find((e) => e.id === initialValue.measure.id),
      category: types.find((e) => e.id === initialValue.category.id),
      weekday_price: initialValue.weekday_price,
      weekend_price: initialValue.weekend_price,
      sort: initialValue.sort,
      active: Boolean(initialValue.active),
    })

    setIsLoading(false)
  }, [initialValue, units, measures, types])

  const logic = useLogic()
  const ctx = useContext(ToastContext)
  const navigate = useNavigate()

  const { id } = useParams()

  const init = async () => {
    if (!selectedPark) return

    if (id) {
      setIsLoading(true)
      ;(isAdmin ? logic.loadAdminTicketById(id) : logic.loadTicketById(id)).then((res) => setInitialValue(res.data))
    } else setIsLoading(false)
  }

  const createFormDate = (data: TTicketForm) => {
    const form: { [key: string]: any } = {
      id: initialValue?.id ?? undefined,
      name: data.name,
      unit_id: data.unit!.id,
      measure_id: data.measure!.id,
      category_id: data.category!.id,
      park_id: selectedPark!.id,
    }
    if (!isAdmin) {
      form['weekday_price'] = data.weekday_price
      form['weekend_price'] = data.weekend_price
      form['sort'] = data.sort
      form['active'] = +data.active
    }

    return form
  }

  const onCreate = async (data: TTicketForm) => {
    const form = createFormDate(data)
    const { status, errors } = isAdmin ? await logic.createAdminTickets(form) : await logic.createTicket(form)

    if (status) navigate(isAdmin ? AppRoutesPaths.AdminPanelTickets : AppRoutesPaths.SettingTicket)
    else ctx?.toastShowHandler({ status: status, errors })
  }

  const onEdit = async (data: TTicketForm) => {
    const form = createFormDate(data)
    const { status, errors } = isAdmin ? await logic.updateAdminTickets(form) : await logic.updateTicket(form)

    if (status) navigate(isAdmin ? AppRoutesPaths.AdminPanelTickets : AppRoutesPaths.SettingTicket)
    else ctx?.toastShowHandler({ status: status, errors })
  }

  useEffect(() => {
    init()
  }, [selectedPark])

  useEffect(() => {
    if (measure?.id !== unit?.measure_id) methods.setValue('unit', null)
  }, [measure])

  return (
    <Layout scrollable title={initialValue ? 'Изменить рассылку' : 'Добавить рассылку'} loading={isLoading}>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(initialValue ? onEdit : onCreate)}
          className='flex h-full flex-col justify-between'
        >
          <section className='grid max-w-lg grid-cols-2 gap-4'>
            <FormInput title='Название' name='name' required />
            <FormDropdown
              title='Категория'
              name='category'
              placeholder='Выберите категорию'
              options={types ?? []}
              size='full'
              required={true}
            />
            <FormDropdown
              title='Единица измерения'
              name='measure'
              placeholder='Выберите единицу измерения'
              options={measures ?? []}
              size='full'
              required={true}
              optionLabel='text'
            />
            <FormDropdown
              title='Значение'
              name='unit'
              placeholder='Выберите значение'
              emptyMessage='Выберите единицу измерения'
              options={
                units?.filter(
                  (e) => measure?.text && e.text.includes(filterMap[measure.text as keyof typeof filterMap])
                ) ?? []
              }
              size='full'
              required={true}
              optionLabel='text'
            />
            {!isAdmin && (
              <>
                <FormInput title='Цена в будни' name='weekday_price' required type='number' />
                <FormInput title='Цена в выходные' name='weekend_price' required type='number' />
                <FormInput title='Сортировка' name='sort' required type='number' />
                <UiText className='leading-4 text-red' weight='bold'>
                  *0 - цена не отображается
                  <br />
                  {'-1 - вместо цены "Бесплатно"'}
                </UiText>
              </>
            )}
          </section>

          <footer className='flex gap-4'>
            <UiButton className='w-[248px]'>{initialValue ? 'Изменить' : 'Добавить'}</UiButton>
            {!isAdmin && <FormCheckbox name='active' title='Доступно на сайте' />}
          </footer>
        </form>
      </FormProvider>
    </Layout>
  )
})
