import { ApiHoliday } from '@@types/apiLogicTypes'
import logo from '@assets/images/skala.png'
import '@common/table.css'
import { checkBoxBodyTemplate, priceBodyTemplate } from '@components/ui/ColumnContents/ColumnContents'
import { TableButtons } from '@components/ui/ContainerTable/TableButtons'
import { TablePageContainer } from '@components/ui/TablePageContainer/TablePageContainer'
import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ArchiveContext } from '@context/archiveContext'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { tableNameHoliday } from '@utils/variableApp'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useContext, useEffect } from 'react'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'

export const PartyScreen = observer(() => {
  const logic = useLogic()
  const {
    auth: { selectedPark },
    holiday: { holidays },
  } = useStore()

  const onAdd = () => {
    navigateTo(AppRoutesPaths.SettingPartyAdd)
  }

  const onArchive = () => {
    navigateTo(AppRoutesPaths.SettingPartyArchive)
  }

  const location = useLocation()
  const inArchive = location && location.pathname === AppRoutesPaths.SettingPartyArchive
  const context = useContext(ToastContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (inArchive) logic.loadHolidays(true)
    else logic.loadHolidays()
  }, [selectedPark, location])

  async function onChangeActive(item: { active: number | boolean; [key: string]: any }) {
    const form = {
      active: String(Number(!Number(item.active))),
      id: String(item.id),
    }

    await logic.editHoliday(form)
  }

  function onClickEdit(item: ApiHoliday) {
    const path = generatePath(AppRoutesPaths.SettingPartyUpdate, {
      id: String(item.id),
    })
    navigate(path)
  }

  async function onDelete(item: ApiHoliday) {
    if (item.deleted === 0) {
      const { status, errors } = await logic.deleteHoliday(item.id)
      if (!status) {
        context?.toastShowHandler({ status, errors })
      } else logic.loadHolidays()
    } else {
      const { status, errors } = await logic.editHoliday({
        deleted: 0,
        id: String(item.id),
      })
      if (!status) {
        context?.toastShowHandler({ status, errors })
      } else logic.loadHolidays(true)
    }
  }

  const buttonBodyTemplate = (item: ApiHoliday) => {
    return (
      <TableButtons onClickEdit={!inArchive ? () => onClickEdit(item) : undefined} onClickBox={() => onDelete(item)} />
    )
  }

  const nameBodyTemplateWithIcon = (rowData: ApiHoliday) => {
    return (
      <div className='flex items-center justify-between'>
        <UiText onClick={() => onClickEdit(rowData)}>{rowData.name}</UiText>
        {rowData?.group_id && <img src={logo} alt='Создано администратором' className='ml-2 h-3 w-3' />}
      </div>
    )
  }

  return (
    <ArchiveContext.Provider value={{ onArchive, inArchive }}>
      <TablePageContainer
        tableProps={{
          arrayHeadName: tableNameHoliday,
          children: <></>,
          isDeletePhoto: true,
        }}
        mainTitle='Праздник'
        textAdd={'Праздник'}
        onClickAdd={onAdd}
        textArchive={'Праздники'}
      >
        <DataTable
          value={holidays ?? []}
          showGridlines
          className={classnames('bg-white text-base')}
          style={{ fontSize: '12px' }}
          removableSort
          sortField='name'
          sortOrder={1}
          emptyMessage='Нет данных'
          // filters={filters}
        >
          <Column field='name' header='Название' sortable body={nameBodyTemplateWithIcon}></Column>
          <Column
            field='weekday_price'
            header='Цена по будням'
            sortable
            body={(item: ApiHoliday) => priceBodyTemplate(item.weekday_price)}
          ></Column>
          <Column
            field='weekend_price'
            header='Цена в выходные'
            sortable
            body={(item: ApiHoliday) => priceBodyTemplate(item.weekend_price)}
          ></Column>

          <Column field='count' header='Кол-во билетов' sortable></Column>

          {!inArchive && (
            <Column field='active' header='Сайт' body={(item) => checkBoxBodyTemplate(item, onChangeActive)}></Column>
          )}
          <Column field='' header='' body={buttonBodyTemplate}></Column>
        </DataTable>
      </TablePageContainer>
    </ArchiveContext.Provider>
  )
})
