import { ApiNewsletterStat } from '@@types/apiLogicTypes'
import { phoneTemplate } from '@components/ui/ColumnContents/ColumnContents'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import moment from 'moment'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { Calendar } from 'primereact/calendar'
import { Column } from 'primereact/column'
import { DataTable, DataTableStateEvent } from 'primereact/datatable'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export const StatisticPage = () => {
  const logic = useLogic()
  const { id } = useParams()
  const [totalRecords, setTotalRecords] = useState(0)
  const [stats, setStats] = useState<ApiNewsletterStat[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [lazyState, setlazyState] = useState<DataTableStateEvent>({
    first: 0,
    rows: 10,
    page: 0,
    sortField: '',
    sortOrder: null,
    filters: {
      global: { value: null, matchMode: FilterMatchMode.BETWEEN },
      created_at: {
        operator: FilterOperator.AND,
        constraints: [{ value: new Date(), matchMode: FilterMatchMode.DATE_IS }],
      },
    },
    multiSortMeta: null,
  })

  const {
    auth: { selectedPark },
  } = useStore()

  const loadLazyData = async () => {
    setIsLoading(true)

    const { page, filters } = lazyState
    //@ts-ignore
    const { matchMode, value } = filters?.created_at?.constraints[0] || {}

    const formatDate = (date: Date) => moment(date).format('YYYY-MM-DD')

    let startDate, endDate

    if (matchMode === FilterMatchMode.DATE_IS && value) {
      startDate = endDate = formatDate(value)
    } else if (matchMode === FilterMatchMode.BETWEEN && Array.isArray(value)) {
      ;[startDate, endDate] = value.map(formatDate)
    }

    const response = await logic.loadNewsletterStatistic(+id!, page! + 1, startDate, endDate)

    if (response?.data) {
      setTotalRecords(response?.data.data.total)
      setStats(response.data.data.data)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    loadLazyData()
  }, [selectedPark, lazyState])

  const dateBodyTemplate = (rowData: any) => {
    return moment(rowData.created_at).format('DD.MM.YYYY')
  }

  const onPage = (props: Required<DataTableStateEvent>) => {
    setlazyState(props)
  }

  const onFilter = (props: Required<DataTableStateEvent>) => {
    props['first'] = 0
    setlazyState(props)
  }

  const dateFilterTemplate = (options: any) => {
    const selectionMode = options.filterModel.matchMode === FilterMatchMode.BETWEEN ? 'range' : 'single'

    return (
      <Calendar
        selectionMode={selectionMode}
        value={options.value}
        onChange={(e: any) => {
          options.filterCallback(e.value, options.index)
        }}
        dateFormat='dd.mm.yy'
        locale='ru'
        maxDate={new Date()}
        hideOnRangeSelection={selectionMode === 'range'}
        mask={selectionMode === 'range' ? '99.99.9999 - 99.99.9999' : '99.99.9999'}
      />
    )
  }

  return (
    <Layout title='Статистика' showHeader={false} className='h-full !p-0'>
      <section className='relative flex flex-col gap-5 overflow-hidden pb-[80px]'>
        <DataTable
          lazy
          totalRecords={totalRecords}
          onPage={onPage}
          filters={lazyState.filters}
          globalFilterFields={['created_at']}
          onFilter={onFilter}
          value={stats}
          loading={isLoading}
          showGridlines
          size={'small'}
          filterLocale='ru'
          className='ui-paginator-table bg-white text-base'
          style={{ fontSize: '12px' }}
          removableSort
          sortField={lazyState.sortField}
          first={lazyState.first}
          emptyMessage='Платежи не найдены'
          paginator
          rows={20}
          scrollable
          scrollHeight='flex'
          columnResizeMode='fit'
        >
          <Column
            filterField='created_at'
            header='Дата'
            dataType='date'
            body={dateBodyTemplate}
            showFilterMatchModes={true}
            filterMatchModeOptions={[
              { label: 'Период', value: 'between' },
              { label: 'За день', value: 'dateIs' },
            ]}
            showFilterMenuOptions={true}
            showAddButton={false}
            showFilterOperator={false}
            filter
            filterElement={dateFilterTemplate}
            filterApply={(e) => (
              <UiButton onClick={e.filterApplyCallback} className='capitalize'>
                Принять
              </UiButton>
            )}
            filterClear={(e) => (
              <UiButton
                onClick={e.filterClearCallback}
                className='!border border-solid border-blue capitalize'
                color='blue'
              >
                Очистить
              </UiButton>
            )}
          />
          <Column field='text' header='Рассылка' />
          <Column field='user.name' header='Пользователь' />
          <Column
            field='user.phone'
            header='Номер'
            body={(e) => phoneTemplate(e.user)}
            bodyClassName={'whitespace-nowrap'}
          />
        </DataTable>
      </section>
    </Layout>
  )
}
