import { LoadState } from '@@types/loadState'
import { selectedTreeObjType } from '@components/Settings/SettingsCategories'
import { appColors } from '@config/theme'
import { TreeNodePrimereact } from '@utils/createDataTreePrimereact'
import { Tree, TreeExpandedKeysType } from 'primereact/tree'
import { TreeNode } from 'primereact/treenode'
import { useEffect, useRef, useState } from 'react'
import { UiText } from '../UiText/UiText'
import './DataThreePrimereact.css'
export const DataThreePrimereact = ({
  dataThree,
  selectedTree,
  selectedTreeObj,
}: {
  loading?: LoadState
  dataThree: any[]
  selectedTree?: (name: string, id: string, parent: TreeNodePrimereact) => void
  selectedTreeObj: selectedTreeObjType | null
}) => {
  const [expandedKeys, setExpandedKeys] = useState<TreeExpandedKeysType>({
    '0': true,
    '0-0': true,
  })
  const [selectedKey] = useState<string>('')
  const ref = useRef<Tree | null>(null)

  const expandAll = () => {
    const _expandedKeys = {}

    for (const node of dataThree) {
      expandNode(node, _expandedKeys)
    }

    setExpandedKeys(_expandedKeys)
  }

  const expandNode = (node: any, _expandedKeys: TreeExpandedKeysType) => {
    if (node.children && node.children.length && node.key) {
      _expandedKeys[node.key] = true

      for (const child of node.children) {
        expandNode(child, _expandedKeys)
      }
    }
  }

  const nodeTemplate = (node: TreeNode, options: any) => {
    const onClickBtn = (evt: any) => {
      options.onTogglerClick(evt)
      if (node.id && selectedTree) {
        selectedTree(node.label ?? '', node.id, options.props.parent)
      }
    }

    let isActive = false
    if (selectedTreeObj && node.id) {
      isActive = selectedTreeObj.id === node.id
    }

    return (
      <div onClick={onClickBtn} className='flex h-[43px] w-[100%] cursor-pointer flex-row items-center gap-[8px]'>
        <div
          style={{
            backgroundColor: isActive ? appColors.bg : undefined,
          }}
          className='rounded-[3px] p-[8px]'
        >
          <UiText>{node.label} </UiText>
        </div>
      </div>
    )
  }

  useEffect(() => {
    expandAll()
  }, [dataThree])

  return (
    <div className='flex-column align-items-center custom-three flex'>
      <Tree
        nodeTemplate={nodeTemplate}
        togglerTemplate={<></>}
        onToggle={(e) => setExpandedKeys(e.value)}
        selectionMode='single'
        selectionKeys={selectedKey}
        className='md:w-30rem w-full'
        value={dataThree}
        expandedKeys={expandedKeys}
        ref={ref}
      />
    </div>
  )
}
