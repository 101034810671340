import { LoadState } from '@@types/loadState'
import { Loader } from '@components/ui/Loader/Loader'
import { UiText } from '@components/ui/UiText/UiText'
import { ToastContext } from '@context/toastContext'
import { useBoolState } from '@hooks/commonHooks'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { observer } from 'mobx-react-lite'
import { Checkbox } from 'primereact/checkbox'
import { useContext, useEffect } from 'react'
import { GameForm } from './Form/Form'

export const Page = observer(() => {
  const {
    auth: { selectedPark },
    game: { loading },
  } = useStore()
  const logic = useLogic()

  useEffect(() => {
    if (!selectedPark) return
    logic.loadIntegrationByPark()
  }, [selectedPark])

  const context = useContext(ToastContext)
  const [selected, select, unSelect] = useBoolState(Boolean(selectedPark?.show_games_crm ?? false))

  const toggleGamesShowOnCRMHandler = async () => {
    const formData = new FormData()
    formData.append('show_games_crm', String(+!selected))
    const { status, errors } = await logic.editCenter(formData, selectedPark!.id)
    if (status) context?.toastShowHandler({ status })
    else context?.toastShowHandler({ status, errors })
    selected ? unSelect() : select
  }

  return (
    <Layout>
      {loading !== LoadState.Success ? (
        <Loader className='bg-white' />
      ) : (
        <main className='flex flex-col gap-4'>
          <div className='flex items-center gap-2'>
            <Checkbox checked={selected} onChange={toggleGamesShowOnCRMHandler} />
            <UiText className='mb-[3px] text-grey'>Доступно на сайте</UiText>
          </div>
          <GameForm />
        </main>
      )}
    </Layout>
  )
})
