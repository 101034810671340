import { ApiHoliday, ApiRoom } from '@@types/apiLogicTypes'
import FormDropdown from '@components/ui/Form/FormDropdown'
import { useStore } from '@hooks/storeHook'
import { observer } from 'mobx-react-lite'
import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { IEvent } from '../SchedulePage'

interface IFilters {
  room: ApiRoom
  animator: string
  type: ApiHoliday
}

interface IFilterPanel {
  setEvents: Dispatch<SetStateAction<[] | IEvent[]>>
  events: IEvent[]
}

const FilterPanel: FC<IFilterPanel> = observer(({ setEvents, events }) => {
  const {
    rooms: { rooms },
    holiday: { holidays },
  } = useStore()
  const methods = useForm<IFilters>()

  useEffect(() => {
    const subscription = methods.watch((value) => {
      if (!value.type && !value.room) {
        setEvents(events)
        return
      }
      if (value.type && value.type.name && value.room && value.room.name) {
        setEvents(
          events.filter((item) => {
            return item.holiday.name === value.type?.name && item.holiday.room?.name === value.room?.name
          })
        )
      } else if (value.type && value.type.name) {
        setEvents(
          events.filter((item) => {
            return item.holiday.name === value.type?.name
          })
        )
      } else if (value.room && value.room.name) {
        setEvents(
          events.filter((item) => {
            return item.holiday.room?.name === value.room?.name
          })
        )
      }
    })

    return () => subscription.unsubscribe()
  }, [events, methods.watch])

  return (
    <FormProvider {...methods}>
      <div className='z-10 flex w-[calc(100%_-_350px)] flex-col gap-4'>
        <div className='flex gap-4'>
          <FormDropdown name='type' title='Тип' options={holidays ?? []} size='small' showClear={true} />
          <FormDropdown name='animator' value={{}} title='Аниматор' options={[]} onChange={() => {}} size='small' />
          <FormDropdown name='room' title='Патирум' options={rooms ?? []} size='small' showClear={true} />
        </div>
        {/* <UiButton onClick={onClick} className='w-[252px]' color='blue'>
            Добавить праздник
          </UiButton> */}
      </div>
    </FormProvider>
  )
})

export default FilterPanel
