import { UiText } from '@components/ui/UiText/UiText'
import { Checkbox, CheckboxProps } from 'primereact/checkbox'
import { Controller, useFormContext } from 'react-hook-form'

interface IFormCheckbox extends Omit<CheckboxProps, 'checked'> {
  name: string
  title: string
  onChange?: () => void
}

export const FormCheckbox = ({ name, title, onChange, ...props }: IFormCheckbox) => {
  const { control } = useFormContext()

  return (
    <div className='flex items-center gap-3'>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Checkbox
            {...props}
            checked={Boolean(field.value)}
            onChange={
              onChange
                ? onChange
                : (e) => {
                    field.onChange(e.checked)
                  }
            }
            inputRef={field.ref}
          />
        )}
      />
      <UiText className='text-grey'>{title}</UiText>
    </div>
  )
}
