import { ApiTicket } from '@@types/apiLogicTypes'
import { IPageType } from '@@types/moduleTypes'
import {
  buttonBodyTemplate,
  checkBoxBodyTemplate,
  measureBodyTemplate,
  nameBodyTemplateWithIcon,
  priceBodyTemplate,
} from '@components/ui/ColumnContents/ColumnContents'
import { SettingsHeaderBtn } from '@components/ui/SettingsHeaderBtn/SettingsHeaderBtn'
import { AppRoutesPaths } from '@config/navigation'
import { ArchiveContext } from '@context/archiveContext'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { onAdd } from '@utils/tableUtils'
import { observer } from 'mobx-react-lite'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const Page = observer(({ isAdmin, inArchive = false }: IPageType) => {
  const logic = useLogic()
  const [isLoading, setIsLoading] = useState(true)
  const {
    ticket: { tickets, adminTickets },
    services: { units },
    auth: { selectedPark },
  } = useStore()

  const location = useLocation()
  const ctx = useContext(ToastContext)
  const navigate = useNavigate()

  const onArchive = () => {
    navigate(AppRoutesPaths.SettingTicketArchive)
  }

  useEffect(() => {
    setIsLoading(true)
    if (inArchive) logic.loadTicket(true).finally(() => setIsLoading(false))
    else (isAdmin ? logic.loadAdminTickets() : logic.loadTicket()).finally(() => setIsLoading(false))
  }, [location, selectedPark])

  async function onChangeActive(ticket: { active: number | boolean; [key: string]: any }) {
    await logic.updateActiveTicket({
      ...ticket,
      active: Number(!Number(ticket.active)),
    })
  }

  async function onDelete(ticket: ApiTicket) {
    const action =
      ticket.deleted === 0 ? logic.deleteTicket(ticket.id) : logic.updateTicket({ id: ticket.id, deleted: 0 })

    const { status, errors } = await action

    if (status) {
      logic.loadTicket(ticket.deleted !== 0 || undefined)
    } else {
      ctx?.toastShowHandler({ status, errors })
    }
  }

  return (
    <Layout title='Билеты'>
      <ArchiveContext.Provider value={{ inArchive, onArchive }}>
        <SettingsHeaderBtn
          textAdd={'билет'}
          onClickAdd={() => onAdd(isAdmin ? AppRoutesPaths.AdminPanelTicketsAdd : AppRoutesPaths.SettingTicketAdd)}
          textArchive={'билеты'}
          isAdmin={isAdmin}
        />
        <DataTable
          value={isAdmin ? adminTickets : tickets.filter((e) => e.deleted === (inArchive ? 1 : 0))}
          showGridlines
          className={'bg-white text-base'}
          style={{ fontSize: '12px' }}
          removableSort
          sortField='name'
          sortOrder={1}
          emptyMessage='Нет данных'
          loading={isLoading}
        >
          <Column
            field='name'
            header='Название'
            sortable
            body={(item) =>
              nameBodyTemplateWithIcon(
                item,
                isAdmin ? AppRoutesPaths.AdminPanelTicketsUpdate : AppRoutesPaths.SettingTicketUpdate
              )
            }
          />
          <Column field='category.name' header='Категория' sortable />
          {!isAdmin && (
            <Column
              field='weekday_price'
              header='Цена по будням'
              sortable
              body={(item: ApiTicket) => priceBodyTemplate(item.weekday_price)}
            />
          )}
          {!isAdmin && (
            <Column
              field='weekend_price'
              header='Цена в выходные'
              sortable
              body={(item: ApiTicket) => priceBodyTemplate(item.weekend_price)}
            />
          )}
          <Column
            field='unit_id'
            header='Измерение'
            body={(rowData: ApiTicket) => measureBodyTemplate(rowData, units ?? null)}
          ></Column>
          {!isAdmin && <Column field='sort' header='Сортировка' sortable />}
          {!inArchive && !isAdmin && (
            <Column field='active' header='Сайт' body={(item) => checkBoxBodyTemplate(item, onChangeActive)}></Column>
          )}
          <Column
            field=''
            header=''
            body={(item) =>
              buttonBodyTemplate({
                item: item,
                path: isAdmin ? AppRoutesPaths.AdminPanelTicketsUpdate : AppRoutesPaths.SettingTicketUpdate,
                onArchive: !isAdmin ? () => onDelete(item) : undefined,
                onDelete: undefined,
              })
            }
          />
        </DataTable>
      </ArchiveContext.Provider>
    </Layout>
  )
})
