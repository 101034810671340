import { UiText } from '@components/ui/UiText/UiText'
import Layout from '@layouts/NonScrollableLayout'
import { observer } from 'mobx-react-lite'
import { Table } from './Table/Table'
import { TodaySchedule } from './TodaySchedule/TodaySchedule'

const ReceptionPage = observer(() => {
  return (
    <Layout className='!p-0'>
      <div className='grid h-full w-full grid-cols-6 gap-[14px] bg-grey-hover'>
        <div className='col-start-1 col-end-5 flex h-full flex-col gap-7 overflow-hidden bg-white px-5 pt-[25px]'>
          <UiText className='absolute top-[10px] mt-[25px] !text-lg !font-bold'>Продажи</UiText>
          <Table />
        </div>
        <div className='col-start-5 col-end-7 flex h-full flex-col gap-[16px] overflow-hidden bg-white px-5 py-[25px]'>
          <UiText className='!text-lg !font-bold'>Праздники сегодня</UiText>
          <TodaySchedule />
        </div>
      </div>
    </Layout>
  )
})

export default ReceptionPage
