import { kanbanColors } from '@config/theme'
import { typeViews } from '@modules/Applications/ApplicationsPage'
import { FC } from 'react'
import KanbanButton, { ButtonTypes } from '../KanbanButton/KanbanButton'

interface IKanbanControlPanel {
  typeView: (typeof typeViews)[number]
  isVisible: boolean
}

const KanbanControlPanel: FC<IKanbanControlPanel> = ({ isVisible, typeView }) => {
  return (
    <footer
      className={`pointer-events-none absolute bottom-0 flex min-h-[68px] w-full justify-end gap-4 border-0 border-t-[1px] border-solid border-light-grey bg-white bg-opacity-80 opacity-0 ${
        isVisible && '!pointer-events-auto !opacity-100'
      } transition-opacity`}
    >
      {typeView === typeViews[0] ? (
        <>
          <KanbanButton color={kanbanColors.red} type={ButtonTypes.DELETE}>
            <i className='pi pi-trash'></i>
          </KanbanButton>
          <KanbanButton color={kanbanColors.grey} type={ButtonTypes.NOT_REALISED}>
            Не реализована
          </KanbanButton>
          <KanbanButton color={kanbanColors.green} type={ButtonTypes.SUCCESFULL}>
            Успешно завершена
          </KanbanButton>
        </>
      ) : (
        <KanbanButton color={kanbanColors.green} type={ButtonTypes.TO_NEW}>
          <i className='pi pi-calendar-plus'></i>
        </KanbanButton>
      )}
    </footer>
  )
}

export default KanbanControlPanel
