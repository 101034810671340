import { ApiEmployee, ApiEmployeeTrackingReports } from '@@types/apiLogicTypes'
import { UiButton } from '@components/ui/UiButton/UiButton'
import moment from 'moment'
import { Calendar } from 'primereact/calendar'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'

export const datePicketBodyTemplate = (
  report: ApiEmployeeTrackingReports,
  editCallback: (id: number, query: string) => Promise<void>,
  editLocalStateCallback: (report: ApiEmployeeTrackingReports, rowIndex?: number) => void,
  rowIndex: number
) => {
  const onChangeHandler = async (e: any) => {
    const date = moment(e.value, 'YYYY-MM-DD').toDate().toLocaleDateString('en-CA')

    if (!report.id) {
      editLocalStateCallback({ ...report, date: date }, rowIndex)
      return
    }

    const query = `date=${date}`
    await editCallback(report.id, query)
  }

  return (
    <Calendar
      value={new Date(report.date)}
      onChange={onChangeHandler}
      showIcon={false}
      dateFormat='dd.mm.yy'
      locale='ru'
      readOnlyInput={true}
      className={`worker !w-[85px] shadow-none`}
      inputClassName='shadow-none border-green'
    />
  )
}

export const employeeBodyTemplate = (
  report: ApiEmployeeTrackingReports,
  employees: ApiEmployee[],
  editCallback: (id: number, query: string) => Promise<void>,
  editLocalStateCallback: (report: ApiEmployeeTrackingReports, rowIndex?: number) => void,
  rowIndex: number
) => {
  const onChangeHandler = async (e: DropdownChangeEvent) => {
    if (!report.id) {
      editLocalStateCallback(
        {
          ...report,
          user_id: e.value.id,
          user: employees.find((user) => user.id === e.value.id),
        },
        rowIndex
      )
      return
    }

    const query = `user_id=${e.value.id}`
    await editCallback(report.id, query)
  }

  return (
    <Dropdown
      value={report.user ? employees.find((e) => e.id === report.user!.id) : undefined}
      onChange={onChangeHandler}
      options={employees}
      panelClassName='worker-panel'
      color='green'
      optionLabel='name'
      placeholder='Выберите сотрудника'
      className='worker flex h-[36px] items-center border-green'
      itemTemplate={(e) => <span>{(e?.surname ?? '') + ' ' + e?.name}</span>}
      valueTemplate={(e, options) =>
        e?.surname || e?.name ? <span>{(e?.surname ?? '') + ' ' + e?.name}</span> : options.placeholder
      }
    />
  )
}

export const buttonsTemplate = (deleteCallback: () => Promise<void>, addCallback?: () => Promise<void>) => {
  const onClickDeleteHandler = async () => {
    await deleteCallback()
  }

  const onClickAddHandler = async () => {
    await addCallback!()
  }

  return (
    <div className='flex flex-row gap-2'>
      <UiButton color='red' onClick={onClickDeleteHandler}>
        <i className='pi pi-trash !text-white'></i>
      </UiButton>
      {addCallback && (
        <UiButton onClick={onClickAddHandler}>
          <i className='pi pi-plus !text-white'></i>
        </UiButton>
      )}
    </div>
  )
}
