import { IconElement } from '@components/ui/IconElement/IconElement'
import { UiButton } from '@components/ui/UiButton/UiButton'

export const BottomButtons = ({
  onClickDelete,
  onClickAdd,
  titleSave,
}: {
  onClickDelete?: () => void
  onClickAdd?: () => void
  titleSave?: string
}) => {
  return (
    <div className='mt-auto flex h-[52px] flex-row items-end gap-[16px] border-0 border-t-[1px] border-solid border-grey-secondary'>
      <UiButton onClick={onClickAdd}>{titleSave}</UiButton>
      {onClickDelete && (
        <UiButton onClick={onClickDelete} color='red' icon>
          <IconElement icon={'/icons/DeleteIcon.svg'} />
        </UiButton>
      )}
    </div>
  )
}
