import { ApiEmployee } from '@@types/apiLogicTypes'
import { buttonBodyTemplate, imgBodyTemplate, phoneTemplate } from '@components/ui/ColumnContents/ColumnContents'
import { SettingsHeaderBtn } from '@components/ui/SettingsHeaderBtn/SettingsHeaderBtn'
import { AppRoutesPaths } from '@config/navigation'
import { ArchiveContext } from '@context/archiveContext'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { createFormData } from '@utils/createDataForm'
import { onAdd } from '@utils/tableUtils'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { FC, useContext, useEffect, useState } from 'react'

interface IWorkersPage {
  isAdmin?: boolean
  inArchive?: boolean
}

export const WorkersPage: FC<IWorkersPage> = observer(({ isAdmin, inArchive = false }) => {
  const [isLoading, setIsLoading] = useState(false)
  const logic = useLogic()
  const context = useContext(ToastContext)

  const {
    auth: { selectedPark },
    users: { employees, managers },
  } = useStore()

  useEffect(() => {
    if (!isAdmin) {
      if (employees.length) return

      setIsLoading(true)
      logic.loadEmployees().finally(() => setIsLoading(false))
    } else {
      if (managers.length) return

      setIsLoading(true)
      logic.loadManagers().finally(() => setIsLoading(false))
    }
  }, [selectedPark])

  const parkTemplate = (e: ApiEmployee) => {
    return <span>{e.parks.map((e) => e.name).join(', ')}</span>
  }

  const nameTemplate = (e: ApiEmployee) => {
    const surname = e.surname ? e.surname + ' ' : ''
    const patronymic = e.patronymic ? ' ' + e.patronymic : ''
    return <span>{surname + e.name + patronymic}</span>
  }

  const onArchive = async (item: ApiEmployee) => {
    const formdata = createFormData({ deleted: Number(!Boolean(item.deleted)) })
    const { status, errors } = await logic.updateEmployee(formdata, item.id)
    if (!status) context?.toastShowHandler({ status: false, errors: errors })
  }

  return (
    <Layout title={isAdmin ? 'Управляющие' : 'Сотрудники'}>
      <ArchiveContext.Provider value={{ inArchive, onArchive: () => onAdd(AppRoutesPaths.SettingEmployeeArchive) }}>
        <div className='flex h-full flex-col gap-[16px] overflow-x-auto rounded-[3px]'>
          <SettingsHeaderBtn
            textAdd={isAdmin ? 'управляющего' : 'сотрудника'}
            onClickAdd={() => onAdd(isAdmin ? AppRoutesPaths.AdminPanelEmployeeAdd : AppRoutesPaths.SettingEmployeeAdd)}
            textArchive={isAdmin ? 'управляющие' : 'сотрудники'}
            isAdmin={isAdmin}
          />
          <DataTable
            value={
              isAdmin
                ? managers
                : employees.filter(
                    (e) =>
                      e.parks.some((item) => item.id === selectedPark?.id && e.roles[0].id !== 3) &&
                      e.deleted === (inArchive ? 1 : 0)
                  )
            }
            showGridlines
            className={classnames('bg-white text-base')}
            style={{ fontSize: '12px' }}
            removableSort
            sortField='surname'
            sortOrder={1}
            emptyMessage='Нет данных'
            loading={isLoading}
          >
            <Column body={imgBodyTemplate} header='Фото' bodyClassName='text-center'></Column>
            <Column field='surname' header='ФИО' sortable body={nameTemplate} />
            {!isAdmin && <Column field='roles.0.ru' header='Должность' sortable />}
            {isAdmin && <Column header='Центр' body={parkTemplate} />}
            <Column header='Телефон' body={phoneTemplate} />
            <Column
              field=''
              header=''
              body={(item) =>
                buttonBodyTemplate({
                  item: item,
                  path: isAdmin ? AppRoutesPaths.AdminPanelEmployeeEdit : AppRoutesPaths.SettingEmployeeEdit,
                  onArchive: !isAdmin ? () => onArchive(item) : undefined,
                })
              }
            />
          </DataTable>
        </div>
      </ArchiveContext.Provider>
    </Layout>
  )
})
