import { AppRoutesType, navigateTo } from '@config/navigation'
import { useStore } from '@hooks/storeHook'
import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { UiText } from '../UiText/UiText'

export const NavigationNavbar = ({ routes, title }: { routes: AppRoutesType; title: string }) => {
  const onSelect = (route: string) => {
    navigateTo(route)
  }
  const { auth } = useStore()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const pathArray = location.pathname.split('/').filter((i) => i)
    const str = pathArray[0]

    if (pathArray.length === 1) {
      navigate(routes[0].path)
    }
    if (pathArray.indexOf(str) >= 0) {
      return
    }
    navigate(routes[0].path)
  }, [location.pathname])

  return (
    <div className='flex min-h-full w-full flex-row overflow-x-hidden'>
      <div className='z-[2] mr-[8px] flex max-h-[992px] min-h-full min-w-[204px] max-w-[204px] flex-row'>
        <div className='fixed flex h-[100%] max-h-[calc(100vh_-_32px)] min-h-[calc(100vh_-_32px)] min-w-[204px] max-w-[204px] flex-col gap-[16px] rounded-[3px] bg-white px-[8px] py-[16px]'>
          <div className='flex h-[36px] w-full flex-col items-start justify-start border-0 border-b-[1px] border-solid border-green pb-[16px]'>
            <UiText size='lg' weight='bold' className='text-left'>
              {title}
            </UiText>
          </div>
          <div className='flex h-[100vh] flex-col gap-[16px] rounded-[3px]'>
            {routes.map((item) => {
              if (!item.allowedRole?.some((allowedRole) => auth.roles?.includes(allowedRole))) {
                return <></>
              }
              return (
                <div
                  key={item.name}
                  onClick={onSelect.bind(this, item.path)}
                  className='flex h-[28px] min-h-[32px] w-full cursor-pointer flex-col items-start justify-center p-[8px]'
                >
                  <UiText
                    style={{
                      fontWeight: location.pathname.includes(item.path) ? 800 : 400,
                    }}
                    className='text-left text-2base'
                  >
                    {item.name}
                  </UiText>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className={'no-scroll mx-[auto] min-h-full w-full max-w-[1440px] overflow-x-auto'}>
        <Outlet />
      </div>
    </div>
  )
}
