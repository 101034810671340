import { FC } from 'react'
import { UiText } from '../UiText/UiText'

interface IUiTextArea extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  title: string
}

export const UiTextArea: FC<IUiTextArea> = ({ title, ...props }) => {
  return (
    <div className={`flex flex-col gap-[4px]`}>
      <UiText className='text-grey'>{title}</UiText>
      <textarea
        {...props}
        className={`text-area h-[36px] min-h-[100px] resize-none rounded-[3px] border-[1px] border-solid border-green bg-white px-[8px] py-[10px] text-base !tracking-normal !text-grey focus:outline-none ${
          props.className
        } ${props.disabled ? 'opacity-[0.6]' : ''}`}
        placeholder='Введите текст'
      ></textarea>
    </div>
  )
}
