import { Employee } from '@@types/apiLogicTypes'
import { normalizePhone } from '@utils/textUtils'
import { PropsWithChildren } from 'react'
import { IconElement } from '../IconElement/IconElement'
import { TableFilter } from '../TableFilter/TableFilter'
import { UiButton } from '../UiButton/UiButton'
import { UiText } from '../UiText/UiText'

export type ContainerTableProps = {
  onAdd(): void
  mainTitle: string
  addButtonText: string
  archivalText: string
  workers: Employee[]
}

export const ContainerTable = (props: ContainerTableProps) => {
  return (
    <div className='flex h-full flex-col gap-[24px] bg-white p-[16px]'>
      <UiText weight='bold' size='2lg'>
        {props.mainTitle}
      </UiText>
      <div className='flex h-full flex-col gap-[16px] overflow-x-auto rounded-[3px]'>
        <div className='flex flex-row gap-[16px]'>
          <UiButton onClick={props.onAdd} className='w-[252px]'>
            {props.addButtonText}
          </UiButton>
          <UiButton className='w-[252px]' color='blue'>
            {props.archivalText}
          </UiButton>
        </div>
        {props.workers.length > 0 && (
          <table className='table-auto border-collapse border-[1px] border-solid border-grey-secondary'>
            <thead>
              <tr>
                <th className='w-[36px]'></th>
                <TableFilter placeholder='ФИО' />
                <TableFilter placeholder='Должность' />
                <TableFilter placeholder='Центр' />
                <TableFilter placeholder='Телефон' />

                <TH width={172}></TH>
              </tr>
            </thead>
            <tbody>
              {props.workers.map((item) => {
                return (
                  <tr key={item.id}>
                    <TD>
                      <UiText>
                        <IconElement
                          // width={80}
                          height={80}
                          icon={item.photo.path}
                        />
                      </UiText>
                    </TD>
                    <TD>
                      <UiText>{`${item.name} ${item.secondName} ${item.patronymic}`}</UiText>
                    </TD>
                    <TD>
                      <UiText>{item.jobTitle}</UiText>
                    </TD>
                    <TD>
                      <UiText>{item.center.name}</UiText>
                    </TD>
                    <TD>
                      <UiText>{normalizePhone(String(item.center.phone))}</UiText>
                    </TD>
                    <TD center>
                      <div className='flex flex-row gap-[16px] px-[8px]'>
                        <UiButton icon>
                          <IconElement icon={'/icons/EditIcon.svg'} />
                        </UiButton>
                        <UiButton color='grey' icon>
                          <IconElement icon={'/icons/BoxIcon.svg'} />
                        </UiButton>
                        <UiButton color='red' icon>
                          <IconElement icon={'/icons/DeleteIcon.svg'} />
                        </UiButton>
                      </div>
                    </TD>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
        {props.workers.length === 0 && <div>Сотрудников нету</div>}
      </div>
    </div>
  )
}

export const TH = ({ children, width }: PropsWithChildren & { width?: number }) => (
  <th
    style={{ width: width ? `${width}px` : '193px' }}
    className='border-[1px] border-solid border-grey-secondary px-[8px] py-[10px] !text-sm !font-bold'
  >
    {children}
  </th>
)
export const TD = ({
  children,
  center,
  cursorPointer,
}: PropsWithChildren & { center?: boolean; cursorPointer?: boolean }) => (
  <td
    className={`border-[1px] border-solid border-grey-secondary p-[8px] ${center ? 'text-center' : ''} ${
      cursorPointer ? 'cursor-pointer' : ''
    }`}
  >
    {children}
  </td>
)
