import { FC } from 'react'
import { UiButton } from '../UiButton/UiButton'
import { UiText } from '../UiText/UiText'

interface ICounter {
  value: number
  increment: () => void
  decrement: () => void
  title?: string
  disabled?: boolean
}

const Counter: FC<ICounter> = ({ value, title, increment, decrement, disabled = false }) => {
  return (
    <div className='flex w-fit flex-col gap-[4px]'>
      {title && <UiText className='text-grey'>{title}</UiText>}
      <div className='flex w-fit items-center gap-2'>
        <UiButton className='!h-5 !w-[18px] !min-w-[18px]' onClick={decrement} type='button' disabled={disabled}>
          -
        </UiButton>
        <UiText>{value}</UiText>
        <UiButton className='!h-5 !w-[18px] !min-w-[18px]' onClick={increment} type='button' disabled={disabled}>
          +
        </UiButton>
      </div>
    </div>
  )
}

export default Counter
