import { Park } from '@@types/apiLogicTypes'
import { brands } from '@components/ui/EditPark/EditPark'
import { AppRoutesPaths } from '@config/navigation'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { getCity } from '@utils/getCity'
import { formatPhone } from '@utils/textUtils'
import { observer } from 'mobx-react-lite'
import { Button } from 'primereact/button'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const ParkScreen = observer(() => {
  const {
    park: { parks, cities },
  } = useStore()
  const logic = useLogic()
  const navigate = useNavigate()

  function onClickAddPark() {
    navigate(AppRoutesPaths.AdminPanelAddPark)
  }

  function onEditPark(item: Park) {
    navigate(AppRoutesPaths.AdminPanelEditPark, {
      state: { selectedPark: item.id },
    })
  }

  useEffect(() => {
    logic.loadParks()
    logic.loadСities()
  }, [])

  return (
    <Layout title='Центры' scrollable={true}>
      <div className='flex h-full max-h-[calc(100vh_-_170px)] flex-col gap-[16px] overflow-x-auto overflow-y-auto rounded-[3px]'>
        <div className='flex flex-row gap-[16px]'>
          <Button
            onClick={onClickAddPark}
            severity='success'
            className='hover:bg-green-[#16A34A] max-h-[36px] w-[252px] justify-center !bg-green bg-green !text-sm !font-black uppercase'
          >
            Добавить Центр
          </Button>
        </div>
        {parks.length === 0 && <div>Центров нету</div>}
        {parks.map((item) => {
          const cityName = getCity(cities, item.city_id ?? -1)?.name
          const phone = formatPhone(String(item.phone))

          return (
            <div
              key={item.id}
              className='flex flex-col gap-[10px] rounded-[12px] border border-solid border-green p-[20px]'
            >
              <div className={containerItem}>
                <div>Адресс центра:</div>
                <div>{item.address}</div>
              </div>
              <div className={containerItem}>
                <div>Город центра:</div>
                <div>{cityName}</div>
              </div>
              {item.location && (
                <div className={containerItem}>
                  <div>Локация центра:</div>
                  <div>{item.location}</div>
                </div>
              )}
              {item.name && (
                <div className={containerItem}>
                  <div>Название центра:</div>
                  <div>{item.name}</div>
                </div>
              )}
              {item.phone && (
                <div className={containerItem}>
                  <div>Телефон центра:</div>
                  <div>{phone}</div>
                </div>
              )}
              {item.slug && (
                <div className={containerItem}>
                  <div>Слаг центра:</div>
                  <div>{item.slug}</div>
                </div>
              )}
              {item.emails && (
                <div className={containerItem}>
                  <div>Почта для заявок:</div>
                  <div>{item.emails.toString()}</div>
                </div>
              )}
              {item.emails && (
                <div className={containerItem}>
                  <div>Бренд:</div>
                  <div>{brands.find((e) => e.id === item.brand_id)?.name}</div>
                </div>
              )}
              <div>
                <Button
                  onClick={() => onEditPark(item)}
                  severity='success'
                  className='hover:bg-green-[#16A34A] max-h-[36px] w-[252px] justify-center !bg-green bg-green !text-sm !font-black uppercase'
                >
                  Изменить центр
                </Button>
              </div>
            </div>
          )
        })}
      </div>
    </Layout>
  )
})
const containerItem = 'flex gap-[20px]'
