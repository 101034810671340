import { ApiShowsServices } from '@@types/apiLogicTypes'
import { MultiSelect, MultiSelectProps } from 'primereact/multiselect'
import { FC } from 'react'
import { IconElement } from '../IconElement/IconElement'
import { UiButton } from '../UiButton/UiButton'
import { UiText } from '../UiText/UiText'
import './style.css'

export type TestSelected = { name: string; code: string }

interface MultiSelectSkalalandProps extends Omit<MultiSelectProps, 'size'> {
  selectedItems: any[]
  setSelectedItem: (items: any[]) => void
  deleteSelected: (id: number) => void
  title?: string
  size?: 'small' | 'full'
}
export const MultiSelectSkalaland: FC<MultiSelectSkalalandProps> = ({
  setSelectedItem,
  selectedItems,
  deleteSelected,
  title,
  size = 'small',
  ...props
}) => {
  const maxSelectedLabels = 3

  function selectedItemTemplate(value?: ApiShowsServices) {
    if (value) {
      return <SelectedItem id={value.id} itemName={value.name} onClick={deleteSelected} />
    } else {
      return <div>Не выбрано</div>
    }
  }

  return (
    <div className={`flex flex-col gap-[4px] ${size === 'small' ? 'w-[233px]' : 'w-full'}`}>
      {title && <UiText className='text-grey'>{title}</UiText>}
      <div
        className={`card justify-content-center flex ${size === 'small' ? 'min-w-[229px] max-w-[229px]' : 'w-full'}`}
      >
        <MultiSelect
          value={selectedItems}
          onChange={(e) => setSelectedItem(e.value)}
          optionLabel='name'
          placeholder='Выбрать шоу'
          display='chip'
          className={`md:w-14rem worker multiselect min-h-[36px] w-full border-green ${
            selectedItems.length > maxSelectedLabels ? 'green-label' : ''
          } ${size === 'small' ? 'min-w-[229px] max-w-[229px]' : 'w-full'} `}
          panelClassName='multiselect-dropdown'
          // itemTemplate={renderItemTemplate}
          selectedItemTemplate={selectedItemTemplate}
          selectedItemsLabel={`Выбрано ${selectedItems.length}`}
          filter
          maxSelectedLabels={3}
          {...props}
        />
      </div>
    </div>
  )
}

const SelectedItem = ({ id, itemName, onClick }: { id: number; itemName: string; onClick: (id: number) => void }) => {
  function onClickbtn() {
    onClick(id)
  }

  return (
    <div data-key={id} className='!m-[0px] flex !p-[0px] px-[6px] py-[3.5px]' data-pc-section='label'>
      <div className='p-multiselect-token flex gap-[6px]' data-pc-section='token'>
        <div className='p-multiselect-token-label flex items-center' data-pc-section='tokenlabel'>
          <UiText className='!text-xs text-white'>{itemName}</UiText>
        </div>

        <UiButton
          className='!h-[12px] !w-[12px] !min-w-[12px] !max-w-[12px] !bg-transparent p-[0px]'
          onClick={onClickbtn}
          icon
        >
          <IconElement height={12} icon={'/icons/CloseIcon.svg'} />
        </UiButton>
      </div>
    </div>
  )
}
