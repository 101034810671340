import { ApiProduct } from '@@types/apiLogicTypes'
import { MultiSelect, MultiSelectChangeEvent, MultiSelectProps } from 'primereact/multiselect'
import React from 'react'
import { IconElement } from '../IconElement/IconElement'
import { UiButton } from '../UiButton/UiButton'
import { UiText } from '../UiText/UiText'
import './style.css'

export type SelectProductProps = {
  decrementCounter: (props: ApiProduct) => void
  incrementCounter: (props: ApiProduct) => void
  items: ApiProduct[]

  selectedItems: [] | ApiProduct[]
  onChangeSelectedItems: (items: ApiProduct[]) => void
  onDeleteSelected: (id: number) => void
}

export const SelectProduct = ({
  decrementCounter,
  incrementCounter,
  items,
  selectedItems,
  onChangeSelectedItems,
  onDeleteSelected,
}: SelectProductProps) => {
  const maxSelectedLabels = 3

  function selectedItemTemplate(value?: ApiProduct) {
    if (value) {
      return <SelectedItem id={value.id} itemName={value.name} onClick={onDeleteSelected} />
    } else {
      return <div>Не выбрано</div>
    }
  }

  function itemTemplate(props: MultiSelectProps) {
    const itemSelected = props as unknown as ApiProduct

    function onClickDecrementCounter(evt: React.MouseEvent<HTMLButtonElement>) {
      evt.stopPropagation()

      decrementCounter(itemSelected)
    }
    function onClickIncrementCounter(evt: React.MouseEvent<HTMLButtonElement>) {
      evt.stopPropagation()
      incrementCounter(itemSelected)
    }

    if (selectedItems.some((item) => itemSelected.id === item.id)) {
      return (
        <div className='flex w-[100%] items-center justify-between gap-[5px]'>
          <UiText className='text-black'>{props.name}</UiText>
          <div className='flex items-center gap-[6px]'>
            <UiButton
              icon
              onClick={onClickDecrementCounter}
              className='max-h-[20px] min-h-[20px] min-w-[20px] max-w-[20px]'
            >
              -
            </UiButton>

            <UiText className='min-w-[26px] text-center text-black'>{itemSelected.count ?? 0}</UiText>
            <UiButton
              icon
              onClick={onClickIncrementCounter}
              className='max-h-[20px] min-h-[20px] min-w-[20px] max-w-[20px]'
            >
              +
            </UiButton>
          </div>
        </div>
      )
    } else {
      return <UiText className='!text-xs text-grey'>{props.name}</UiText>
    }
  }

  function onChangeSelect(evt: MultiSelectChangeEvent) {
    if (evt.selectedOption.id) {
      const indexItem = evt.value.findIndex((item: ApiProduct) => item.id === evt.selectedOption.id)

      if (evt.value[indexItem]?.count) {
        evt.value[indexItem].count = 0
      }
    }
    onChangeSelectedItems(evt.value)
  }
  return (
    <div className='card justify-content-center flex min-w-[229px] max-w-[229px]'>
      <MultiSelect
        value={selectedItems}
        onChange={onChangeSelect}
        options={items}
        optionLabel='name'
        placeholder='Select Cities'
        display='chip'
        className={`md:w-14rem worker multiselect min-h-[36px] w-full min-w-[229px] max-w-[229px] border-green ${
          selectedItems.length > maxSelectedLabels ? 'green-label' : ''
        }`}
        panelClassName='multiselect-dropdown'
        selectedItemTemplate={selectedItemTemplate}
        itemTemplate={itemTemplate}
        selectedItemsLabel={`Выбрано ${selectedItems.length}`}
        maxSelectedLabels={3}
      />
    </div>
  )
}

const SelectedItem = ({
  id,
  itemName,
  onClick,
}: //  onClick
{
  id: number
  itemName: string
  onClick: (id: number) => void
}) => {
  function onClickbtn(evt: React.MouseEvent<HTMLButtonElement>) {
    evt.stopPropagation()
    onClick(id)
  }
  return (
    <div className='!m-[0px] flex !p-[0px]' data-pc-section='label'>
      <div className='p-multiselect-token flex gap-[6px]' data-pc-section='token'>
        <div className='p-multiselect-token-label flex items-center' data-pc-section='tokenlabel'>
          <UiText className='!text-xs text-white'>{itemName}</UiText>
        </div>

        <UiButton className='!h-[12px] !w-[12px] !min-w-[12px] !bg-transparent p-[0px]' onClick={onClickbtn} icon>
          <IconElement height={12} icon={'/icons/CloseIcon.svg'} />
        </UiButton>
      </div>
    </div>
  )
}
