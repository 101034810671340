import { UiButton } from '@components/ui/UiButton/UiButton'
import { canAccessFeature, FeatureNameEnum } from '@config/featureAccessConfig'
import { useStore } from '@hooks/storeHook'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { TCashboxAction } from '../IntegrationPage'

interface IControlPanel {
  cashManipulateCallback: (flag: boolean, action: TCashboxAction) => void
}

export const ControlPanel: FC<IControlPanel> = observer(({ cashManipulateCallback }) => {
  const {
    auth: { user },
  } = useStore()

  const minusCashboxHandler = async (type: 2 | 3 | 4) => {
    cashManipulateCallback(true, type)
  }

  return (
    <div className='z-10 flex gap-4'>
      <UiButton
        className='w-[184px]'
        color='blue'
        onClick={() => minusCashboxHandler(4)}
        disabled={!canAccessFeature(user!.roles, FeatureNameEnum.manageUncompleteLogs)}
      >
        РКО
      </UiButton>
      <UiButton
        className='w-[184px]'
        onClick={() => minusCashboxHandler(3)}
        disabled={!canAccessFeature(user!.roles, FeatureNameEnum.manageUncompleteLogs)}
      >
        Авансовый отчет
      </UiButton>
      <UiButton
        className='w-[184px]'
        color='yellow'
        onClick={() => minusCashboxHandler(2)}
        disabled={!canAccessFeature(user!.roles, FeatureNameEnum.collectionMoney)}
      >
        Инкассация
      </UiButton>
    </div>
  )
})
