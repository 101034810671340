import { ApiRoom, ApiServicesMeasures, ApiServicesTypes, ApiServicesUnits } from '@@types/apiLogicTypes'
import { itemPhotoFile } from '@components/Settings/ServicePhotoItem'
import { useBoolStateTypeReturn } from '@hooks/commonHooks'
import { UseInputType } from '@hooks/useInput'
import Layout from '@layouts/NonScrollableLayout'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { Dispatch, SetStateAction } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Checkbox } from '../Checkbox/Checkbox'
import { LoadPhoto } from '../LoadPhoto/LoadPhoto'
import { UiButton } from '../UiButton/UiButton'
import { UiText } from '../UiText/UiText'
import { UiTextField } from '../UiTextField/UiTextField'

export type ServicesFormProps = {
  name: UseInputType
  sort: UseInputType

  types: ApiServicesTypes[] | null
  title: string
  subtitle: string
  selectedCategory: ApiServicesTypes | null
  setSelectedCategory: (value: SetStateAction<ApiServicesTypes | null>) => void
  measures: ApiServicesMeasures[] | null
  selectedMetering: ApiServicesUnits | null
  changeUnits(event: DropdownChangeEvent): void
  selectedValue: ApiServicesMeasures | null
  setSelectedValue: (value: SetStateAction<ApiServicesMeasures | null>) => void
  units: ApiServicesMeasures[] | null
  sendForm: () => void
  useBoolStateCheckbox: useBoolStateTypeReturn
  isAdmin?: boolean

  description: string
  setDescription: Dispatch<SetStateAction<string>>

  isDisabledFile: boolean
  setDisabledFile: Dispatch<SetStateAction<boolean>>
  files: itemPhotoFile[]
  onClickDelete(index: number, id?: number): void
  setUploadFile(item: itemPhotoFile): void

  basedCountChild: UseInputType
  price: UseInputType
  extraAdditionalChild: UseInputType

  selectedPatrium: ApiRoom | null
  setSelectedPatrium: Dispatch<SetStateAction<ApiRoom | null>>

  patriums: ApiRoom[]
}

export const ServicesForm = (props: ServicesFormProps) => {
  return (
    <Layout title={props.title} scrollable={true}>
      <div className='flex h-full flex-col gap-[16px] overflow-x-auto rounded-[3px]'>
        <div className='flex flex-row items-center gap-[32px]'>
          <UiTextField className='!w-[233px]' name='Название' {...props.name}></UiTextField>

          {props.types && (
            <div className='flex w-[100%] max-w-[233px] flex-col gap-[4px]'>
              <UiText className='text-grey'>Категория</UiText>

              <Dropdown
                value={props.selectedCategory}
                onChange={(e) => props.setSelectedCategory(e.value)}
                options={props.types}
                optionLabel='name'
                placeholder='Категория'
                className='md:w-14rem worker w-full border-green'
                panelClassName='worker-panel'
                color='green'
              />
            </div>
          )}

          <UiTextField
            className='!w-[233px]'
            name='Базовое кол-во детей'
            placeholder='Базовое кол-во детей'
            disabled={props.selectedMetering?.text === 'За ребенка'}
            {...props.basedCountChild}
          />
          {!props.isAdmin && (
            <UiTextField className='!w-[233px]' name='Сортировка' placeholder='Сортировка' {...props.sort} />
          )}
        </div>
        <div className='flex flex-row items-center gap-[32px]'>
          {!props.isAdmin && <UiTextField className='!w-[233px]' name='Цена' {...props.price}></UiTextField>}

          <div className='flex w-[100%] max-w-[233px] flex-col gap-[4px]'>
            <UiText className='text-grey'>Измерение</UiText>

            {props.measures && (
              <Dropdown
                value={props.selectedMetering}
                onChange={props.changeUnits}
                options={props.measures}
                optionLabel='text'
                placeholder='Измерение'
                className='md:w-14rem worker w-full border-green'
                panelClassName='worker-panel'
                color='green'
              />
            )}
          </div>

          <div className='flex w-[100%] max-w-[233px] flex-col gap-[4px]'>
            <UiText className='text-grey'>Значение</UiText>

            <Dropdown
              value={props.selectedValue}
              onChange={(e) => props.setSelectedValue(e.value)}
              options={props.units ?? undefined}
              optionLabel='text'
              placeholder='Значение'
              className='md:w-14rem worker w-full border-green'
              panelClassName='worker-panel'
              color='green'
              emptyMessage='Нужно выбрать измерение'
              disabled={props.selectedMetering?.text === 'За ребенка'}
            />
          </div>
          {!props.isAdmin && (
            <UiTextField
              className='!w-[233px]'
              name='Надбавка за доп ребенка'
              {...props.extraAdditionalChild}
            ></UiTextField>
          )}
        </div>
        <div className='flex-row'>
          <span className='text-base text-black text-grey'>Описание</span>
          <ReactQuill theme='snow' value={props.description} onChange={props.setDescription} />
        </div>
        {!props.isAdmin && (
          <div className='flex w-[100%] max-w-[233px] flex-col gap-[4px]'>
            <UiText className='text-grey'>Привязка к патируму</UiText>

            <Dropdown
              value={props.selectedPatrium}
              onChange={(e) => props.setSelectedPatrium(e.value)}
              options={props.patriums}
              optionLabel='name'
              placeholder='Привязка к патриуму'
              className='md:w-14rem worker w-full border-green'
              panelClassName='worker-panel'
              color='green'
            />
          </div>
        )}
        <div className='flex flex-row gap-[33px]'>
          <LoadPhoto
            isDisabledFile={props.isDisabledFile}
            setDisabledFile={(isDisabledFile) => props.setDisabledFile(isDisabledFile)}
            arrayFile={props.files}
            setUploadFile={props.setUploadFile}
            onClickDelete={props.onClickDelete}
          />
        </div>
        <div className='mt-auto flex min-h-[52px] flex-row items-end gap-[16px] border-0 border-t-[1px] border-solid border-grey-secondary'>
          <UiButton onClick={props.sendForm} className='w-[233px]'>
            Сохранить
          </UiButton>
          {!props.isAdmin && (
            <div className='flex items-center gap-[8px]'>
              <Checkbox useBoolState={props.useBoolStateCheckbox}></Checkbox>
              <UiText className='mb-[3px] text-grey'>Доступно на сайте</UiText>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}
