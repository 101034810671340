import { IconElement } from '../IconElement/IconElement'
import './styles.css'
export const SearchField = ({
  placeholder = 'Поиск',
  classNameContainer = '',
}: {
  placeholder?: string
  classNameContainer?: string
}) => {
  return (
    <div
      className={`flex h-[36px] w-full flex-row items-center gap-[8px] rounded-[3px] bg-bg px-[8px] py-[6px] ${classNameContainer}`}
    >
      <IconElement icon={'/icons/SearchIcon.svg'} />
      <input className='search-field bg-bg text-base text-black' type='text' placeholder={placeholder}></input>
    </div>
  )
}
