import { ApiEmployee, ApiEmployeeTrackingReports } from '@@types/apiLogicTypes'
import { dateBodyTemplate } from '@components/ui/ColumnContents/ColumnContents'
import { Loader } from '@components/ui/Loader/Loader'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { Calendar } from 'primereact/calendar'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { Row } from 'primereact/row'
import { FC, useEffect, useState } from 'react'

export const StaffReports: FC = observer(() => {
  const [loading, setLoading] = useState(true)
  const [loadingTable, setLoadingTable] = useState(false)

  const [selectedEmployee, setSelectedEmployee] = useState<ApiEmployee | null>(null)
  const [dateFrom, setDateFrom] = useState<Date>(new Date())
  const [dateTo, setDateTo] = useState<Date>(new Date())
  const [reports, setReports] = useState<ApiEmployeeTrackingReports[]>([])

  const logic = useLogic()

  const {
    auth: { selectedPark },
    users: { employees },
  } = useStore()

  useEffect(() => {
    if (!selectedPark) return
    logic.loadEmployees().then(() => setLoading(false))
  }, [selectedPark])

  useEffect(() => {
    if (selectedEmployee && dateFrom && dateTo) {
      setLoadingTable(true)

      logic
        .getEmployeeReportById(
          selectedEmployee!.id,
          moment(dateFrom, 'YYYY-MM-DD').toDate().toLocaleDateString('en-CA'),
          moment(dateTo, 'YYYY-MM-DD').toDate().toLocaleDateString('en-CA')
        )
        .then(({ data }) => setReports(data))
        .finally(() => setLoadingTable(false))
    }
  }, [selectedEmployee, dateFrom, dateTo])

  const sumTotal = () => {
    let total = 0

    for (const report of reports) {
      if (report.sum) total = report.format === 1 ? total + report.sum : total - report.sum
    }

    return total
  }

  const timeCalc = (report: ApiEmployeeTrackingReports) => {
    if (!report.to || !report.from) return '–'

    const momentTo = moment(report.to)
    const momentFrom = moment(report.from)
    const duration = moment.duration(momentTo.diff(momentFrom))
    const hours = duration.asHours()

    return String(hours)
  }

  const exportExcel = () => {
    const heading = [['Дата', 'Тип оплаты', 'Должность', 'Ставка', 'Часы', 'Сумма']]
    const xlsxReports = reports.map((e) => ({
      date: e.date,
      type: e.type === 1 ? 'Рабочие часы' : 'Доп. учёт',
      role: e.user!.roles[0].ru,
      rate: e.user!.working_rate ?? '-',
      hours: timeCalc(e),
      sum: e.format === 1 ? e.sum : 0 - e.sum!,
    }))

    import('xlsx').then((xlsx) => {
      const wb = xlsx.utils.book_new()
      //@ts-ignore
      const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet([])
      xlsx.utils.sheet_add_aoa(ws, heading)

      xlsx.utils.sheet_add_json(ws, xlsxReports, { origin: 'A2', skipHeader: true })

      const sumRangeStart = 2
      const sumRangeEnd = xlsxReports.length + 1
      const sumCell = `F${sumRangeEnd + 1}`

      // Устанавливаем формулу массива для суммы столбца F
      ws[sumCell] = { t: 'n', f: `SUM(F${sumRangeStart}:F${sumRangeEnd})` }

      // Обновляем диапазон для новой ячейки
      if (!ws['!ref']) {
        ws['!ref'] = `A1:F${sumRangeEnd + 1}`
      } else {
        ws['!ref'] = ws['!ref'].replace(/:[A-Z]+\d+/, `:F${sumRangeEnd + 1}`)
      }

      ws['!cols'] = [
        { wch: 15 }, // Ширина первой колонки (Дата)
        { wch: 20 }, // Ширина второй колонки (Тип оплаты)
        { wch: 20 }, // Ширина третьей колонки (Должность)
        { wch: 10 }, // Ширина четвертой колонки (Ставка)
        { wch: 10 }, // Ширина пятой колонки (Часы)
        { wch: 10 }, // Ширина шестой колонки (Сумма)
      ]

      ws[`A${sumRangeEnd + 1}`] = { t: 's', v: 'Итого' }

      xlsx.utils.book_append_sheet(wb, ws, 'Sheet1')

      const excelBuffer = xlsx.write(wb, {
        bookType: 'xlsx',
        type: 'array',
      })

      saveAsExcelFile(excelBuffer, selectedEmployee ? selectedEmployee!.surname! : 'reports')
    })
  }

  const saveAsExcelFile = (buffer: any, fileName: string) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        const EXCEL_EXTENSION = '.xlsx'
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        })

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION)
      }
    })
  }

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer='Итого:' colSpan={6} footerStyle={{ textAlign: 'left' }} />
        <Column footer={sumTotal} />
      </Row>
    </ColumnGroup>
  )

  if (loading)
    return (
      <Layout>
        <Loader />
      </Layout>
    )

  return (
    <Layout title='Отчет по сотрудникам'>
      <header className='flex flex-row gap-4'>
        <Calendar
          value={dateFrom}
          onChange={(e) => setDateFrom(e.value!)}
          showIcon={false}
          dateFormat='dd.mm.yy'
          locale='ru'
          readOnlyInput={true}
          className={`worker w-[85px] shadow-none`}
          inputClassName='shadow-none border-green'
        />

        <Calendar
          value={dateTo}
          onChange={(e) => setDateTo(e.value!)}
          showIcon={false}
          dateFormat='dd.mm.yy'
          locale='ru'
          readOnlyInput={true}
          className={`worker w-[85px] shadow-none`}
          inputClassName='shadow-none border-green'
        />

        <Dropdown
          value={selectedEmployee}
          options={employees}
          onChange={(e) => setSelectedEmployee(e.value)}
          optionLabel='name'
          panelClassName='worker-panel'
          color='green'
          placeholder='Выберите сотрудника'
          className='worker flex h-[36px] w-[259px] items-center border-green'
          itemTemplate={(e) => <span>{(e?.surname ?? '') + ' ' + e.name}</span>}
          valueTemplate={(e, options) =>
            e?.surname || e?.name ? <span>{(e?.surname ?? '') + ' ' + e?.name}</span> : options.placeholder
          }
        />
        <UiButton
          className={`${reports.length === 0 ? 'pointer-events-none !cursor-default !bg-grey-hover' : ''}`}
          color='blue'
          onClick={exportExcel}
          disabled={!reports.length}
        >
          Сохранить в XLS
        </UiButton>
      </header>

      <DataTable
        value={reports}
        showGridlines
        size={'small'}
        filterLocale='ru'
        className={'ui-paginator-table h-fit bg-white text-base'}
        style={{ fontSize: '12px' }}
        removableSort
        emptyMessage='Данные не найдены'
        scrollable
        scrollHeight='flex'
        footerColumnGroup={footerGroup}
        rowClassName={(e) => (e.format === 2 ? 'bg-bg-red' : '')}
        loading={loadingTable}
        sortField='date'
        sortOrder={1}
      >
        <Column field='date' header='Дата' dataType='date' body={(e) => dateBodyTemplate(e.date)} sortable />
        <Column
          field='type'
          header='Тип оплаты'
          sortable
          body={(e) => <span>{e.type === 1 ? 'Рабочее время' : 'Доп.учёт'}</span>}
        />
        <Column field='user.roles.0.ru' header='Должность' />
        <Column field='user.working_rate' header='Ставка' body={(e) => <span>{e.user.working_rate ?? '–'}</span>} />
        <Column field='time' header='Часы' body={(e) => <span>{timeCalc(e)}</span>} />
        <Column field='comment' header='Комментарий' />
        <Column
          field='sum'
          header='Сумма'
          body={(e) => <span>{e.format === 2 && e.sum > 0 ? 0 - e.sum : e.sum}</span>}
          sortable
          sortFunction={(e) => arrSortBySum(e.data, e.order)}
        />
      </DataTable>
    </Layout>
  )
})

const arrSortBySum = (reports: ApiEmployeeTrackingReports[], order: 1 | 0 | -1 | null | undefined) => {
  const arr = reports.map((e) => (e.format === 1 ? e : { ...e, sum: 0 - e.sum! }))

  if (order === -1) return arr.sort((a, b) => b.sum! - a.sum!)
  else if (order === 1) return arr.sort((a, b) => a.sum! - b.sum!)
  else return arr
}
