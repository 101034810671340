import classNames from 'classnames'
import { ProgressSpinner } from 'primereact/progressspinner'
import { FC } from 'react'

interface ILoader {
  className?: string
}

export const Loader: FC<ILoader> = ({ className }) => {
  return (
    <div
      className={classNames(
        `absolute inset-0 z-10 flex h-full w-full items-center justify-center bg-black bg-opacity-40`,
        className
      )}
    >
      <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth='4' animationDuration='.5s' />
    </div>
  )
}
