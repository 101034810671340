import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { useStore } from '@hooks/storeHook'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Form } from './Form/Form'
import './styles.css'

export const HappyBirthdayForm = observer(() => {
  const [holidaysIndexes, setHolidaysIndexes] = useState<number[]>([])

  const {
    game: { holidays },
  } = useStore()

  useEffect(() => {
    if (holidays) setHolidaysIndexes(holidays.map((e, i) => i + 1))
  }, [holidays])

  const addHolidayHandler = () => {
    if (holidaysIndexes.length === 0) setHolidaysIndexes([1])
    else setHolidaysIndexes((prev) => [...prev, prev[prev.length - 1] + 1])
  }

  const removeHolidayHandler = (index: number) => {
    setHolidaysIndexes((prev) => {
      return prev.filter((e) => e !== index)
    })
  }

  return (
    <>
      <section className='flex flex-col'>
        <header className='flex flex-row items-center gap-2'>
          <UiText className='text-lg font-bold'>День рождения</UiText>
          <UiButton className='h-[36px] w-[36px]' onClick={addHolidayHandler}>
            <i className='pi pi-plus text-sm'></i>
          </UiButton>
        </header>
        {holidaysIndexes.map((i) => (
          <Form
            key={i}
            id={`uniq${i}`}
            removeFormHandler={() => removeHolidayHandler(i)}
            initialValue={holidays.find((e, index) => index === i - 1)}
          />
        ))}
      </section>
    </>
  )
})
