import {
  ApiAttraction,
  ApiHoliday,
  ApiRoom,
  ApiServices,
  ApiServicesCreateFormImg,
  ApiServicesUnits,
  ApiTicket,
} from '@@types/apiLogicTypes'
import logo from '@assets/images/skala.png'
import { AppRoutesPaths } from '@config/navigation'
import { findItem } from '@utils/getCategory'
import { ITableItems, onEdit } from '@utils/tableUtils'
import { formatPhone } from '@utils/textUtils'
import moment from 'moment'
import { Dispatch, SetStateAction } from 'react'
import { CheckboxContainer } from '../Checkbox/CheckBoxContainer'
import { TableButtons } from '../ContainerTable/TableButtons'
import { IconElement } from '../IconElement/IconElement'
import { UiText } from '../UiText/UiText'

export const measureBodyTemplate = (
  rowData: {
    unit_id: number
    [key: string]: any
  },
  units: ApiServicesUnits[] | null
) => {
  const unit = findItem(units ?? [], rowData.unit_id) as ApiServicesUnits
  return unit?.text ?? ''
}

export const priceBodyTemplate = (price: number) => {
  return price + ' ₽'
}

export const phoneTemplate = (rowData: { phone: string | null; [key: string]: any }) => {
  return <span>{rowData.phone ? formatPhone(rowData.phone) : '-'}</span>
}

export const imgBodyTemplate = (rowData: {
  images?: ApiServicesCreateFormImg[]
  image?: string
  [key: string]: any
}) => {
  if (rowData.image) return <IconElement height={40} icon={rowData.image} />
  return rowData?.images?.[0]?.path ? (
    <IconElement height={40} icon={rowData?.images?.[0]?.path ?? ''}></IconElement>
  ) : (
    <>Нет фото</>
  )
}

export const checkBoxBodyTemplate = (
  rowData: ApiRoom | ApiHoliday | ApiServices | ApiAttraction | ApiTicket,
  onChangeActive: (
    data: {
      active: number | boolean
      [key: string]: any
    },
    flag: boolean,
    setFlag: Dispatch<SetStateAction<boolean>>
  ) => Promise<void>
) => {
  return <CheckboxContainer item={rowData} onChangeActive={onChangeActive} />
}

export const buttonBodyTemplate = (obj: {
  item: ITableItems
  path: AppRoutesPaths
  onDelete?: () => void
  onArchive?: () => void
}) => {
  return (
    <TableButtons
      onClickEdit={() => onEdit(obj.path, obj.item)}
      onClickBox={obj.onArchive}
      onClickDelete={obj.onDelete}
    />
  )
}

export const nameBodyTemplateWithIcon = (rowData: ITableItems, path: AppRoutesPaths) => {
  return (
    <div className='flex items-center justify-between'>
      <UiText onClick={() => onEdit(path, rowData)}>{rowData.name}</UiText>
      {rowData?.group_id && <img src={logo} alt='Создано администратором' className='ml-2 h-3 w-3' />}
    </div>
  )
}

export const dateBodyTemplate = (rowData: string) => {
  return moment(rowData).format('DD.MM.YYYY')
}
