import {
  ApiRoom,
  ApiServicesCreateForm,
  ApiServicesMeasures,
  ApiServicesTypes,
  ApiServicesUnits,
  ApiValidationResponse,
} from '@@types/apiLogicTypes'
import { Checkbox } from '@components/ui/Checkbox/Checkbox'
import { LoadPhoto } from '@components/ui/LoadPhoto/LoadPhoto'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { UiTextField } from '@components/ui/UiTextField/UiTextField'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useBoolState } from '@hooks/commonHooks'
import { useLogic, useStore } from '@hooks/storeHook'
import { useInput } from '@hooks/useInput'
import Layout from '@layouts/NonScrollableLayout'
import { createFormData } from '@utils/createDataForm'
import { findItem } from '@utils/getCategory'
import { getImagesServices } from '@utils/getImagesServices'
import { separateHoursAndMinutes } from '@utils/separateHoursAndMinutes'
import { observer } from 'mobx-react-lite'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { useContext, useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useParams } from 'react-router-dom'
import { itemPhotoFile } from '../ServicePhotoItem'
import '../style.css'

export const patriumOption = {
  name: 'Нет',
  id: -1,
  category_id: -1,
  park_id: -1,
  category: {
    name: '',
    park_id: -1,
    type: -1,
    updated_at: '',
    created_at: '',
    id: -1,
  },
  active: 0,
  sort: 0,
  color: '',
  capacity: 0,
  images: [],
  description: '',
}

export const ServiceAddScreen = observer(() => {
  const {
    services: { types, units: unitsStore, measures, services },
    auth: { selectedPark },
    rooms: { rooms },
  } = useStore()
  const patriums = rooms ? [...rooms, patriumOption] : [patriumOption]

  const { id } = useParams()

  const selectedServices = services[services.findIndex((item) => item.id === Number(id))] ?? null
  const images = getImagesServices(selectedServices?.images) ?? []

  const selectedTypes = findItem(types ?? [], selectedServices?.category_id ?? -1) as ApiServicesTypes
  const selectedMeasures = findItem(measures ?? [], selectedServices?.measure_id ?? -1) as ApiServicesUnits
  const selectedUnit = findItem(unitsStore ?? [], selectedServices?.unit_id ?? -1) as ApiServicesUnits
  const selectPatrium = findItem(patriums, selectedServices?.room_id ?? -1) as ApiRoom

  const useBoolStateCheckbox = useBoolState(Boolean(selectedServices?.active ?? false))
  const showInCrmCheckbox = useBoolState(Boolean(selectedServices?.show_in_crm ?? false))
  const context = useContext(ToastContext)

  const logic = useLogic()

  const [isDisabledFile, setDisabledFile] = useState<boolean>(false)

  const name = useInput(String(selectedServices?.name ?? ''))
  const sort = useInput(String(selectedServices?.sort ?? ''))
  const [selectedCategory, setSelectedCategory] = useState<ApiServicesTypes | null>(selectedTypes ?? null)
  const basedCountChild = useInput(String(selectedServices?.base_count ?? ''))

  const price = useInput(String(selectedServices?.price ?? ''))
  const [selectedMetering, setSelectedMetering] = useState<ApiServicesUnits | null>(selectedMeasures ?? null)
  const [selectedValue, setSelectedValue] = useState<ApiServicesMeasures | null>(selectedUnit ?? null)

  const objSeparateUnits = separateHoursAndMinutes(unitsStore ?? [])
  const [units, setUnits] = useState<ApiServicesMeasures[] | null | undefined>(null)
  const extraAdditionalChild = useInput(String(selectedServices?.extra_price ?? ''))

  const [description, setDescription] = useState(selectedServices?.description ?? '')

  const [selectedPatrium, setSelectedPatrium] = useState<ApiRoom | null>(selectPatrium)

  const [files, setFiles] = useState<itemPhotoFile[]>([...images])
  const [filesDeleteId, setFilesDeleteId] = useState<number[]>([])

  function changeUnits(event: DropdownChangeEvent) {
    setSelectedMetering(event.value)
  }
  function addFile(item: itemPhotoFile) {
    setFiles((prev) => [...prev, item])
  }
  function onClickDeleteFile(indexFileDelete: number, id?: number) {
    setFiles((prev) => prev.filter((_, index) => index !== indexFileDelete))
    if (id) {
      setFilesDeleteId((prev) => [...prev, id])
    }
  }

  useEffect(() => {
    if (!selectedMetering) {
      return
    }
    if (selectedMetering.text === 'Часы') {
      setUnits(objSeparateUnits.hours)
    } else if (selectedMetering.text === 'Минуты') {
      setUnits(objSeparateUnits.minutes)
    } else if (selectedMetering.text === 'За ребенка') {
      setUnits(unitsStore?.filter((e) => e.text === 'человек'))
      setSelectedValue(unitsStore?.filter((e) => e.text === 'человек')[0] ?? null)
      basedCountChild.setValue('1')
      extraAdditionalChild.setValue(price.value)
    }
  }, [selectedMetering])

  async function sendForm() {
    if (selectedCategory && selectedValue && selectedPark && selectedMetering && description) {
      const form = createFormData({
        base_count: basedCountChild.value,
        description: description,
        name: name.value,
        sort: sort.value,
        price: price.value,
        park_id: String(selectedPark.id),
        category_id: String(selectedCategory.id),
        measure_id: String(selectedMetering.id),
        unit_id: String(selectedValue.id),
        extra_price: String(Number(extraAdditionalChild.value)),
        active: String(Number(useBoolStateCheckbox[0])),
        show_in_crm: String(Number(showInCrmCheckbox[0])),
      })

      let response: ApiValidationResponse = { status: false }

      for (let i = 0; i < files.length; i++) {
        if (files[i].file) {
          form.append(`images[]`, files[i]?.file ?? '')
        }
      }
      if (selectedPatrium && selectedPatrium.id >= 0) {
        form.append(`room_id`, String(selectedPatrium.id))
      }

      if (filesDeleteId.length > 0) {
        form.append('delete_images', filesDeleteId.toString())
      }

      if (selectedServices) {
        response = await logic.editServices(form as Partial<ApiServicesCreateForm>, selectedServices.id)
      } else {
        response = await logic.createServices(form)
      }
      if (response.status) {
        navigateTo(AppRoutesPaths.SettingsServices)
      } else {
        context?.toastShowHandler({ status: response.status, errors: response.errors })
      }
    }
  }

  useEffect(() => {
    if (selectedMetering?.text === 'За ребенка') extraAdditionalChild.setValue(price.value)
  }, [price])

  useEffect(() => {
    if (files.length > 3) {
      setDisabledFile(true)
    } else {
      setDisabledFile(false)
    }
  }, [files])

  useEffect(() => {
    logic.loadRooms()
  }, [])

  const title = selectedServices ? ' Редактирование услуги' : 'Создание услуги'
  return (
    <Layout title={title} scrollable={true}>
      <div className='flex h-full flex-col gap-[24px] overflow-x-auto bg-white'>
        <div className='flex h-full flex-col gap-[16px] overflow-x-auto rounded-[3px]'>
          <div className='flex flex-row items-center gap-[32px]'>
            <UiTextField className='!w-[233px]' name='Название' {...name}></UiTextField>

            {types && (
              <div className='flex w-[100%] max-w-[233px] flex-col gap-[4px]'>
                <UiText className='text-grey'>Категория</UiText>

                <Dropdown
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.value)}
                  options={types}
                  optionLabel='name'
                  placeholder='Категория'
                  className='md:w-14rem worker w-full border-green'
                  panelClassName='worker-panel'
                  color='green'
                />
              </div>
            )}
            <UiTextField
              className='!w-[233px]'
              name='Базовое кол-во детей'
              placeholder='Базовое кол-во детей'
              disabled={selectedMetering?.text === 'За ребенка'}
              {...basedCountChild}
            />
            <UiTextField className='!w-[233px]' name='Сортировка' placeholder='Сортировка' {...sort} />
          </div>
          <div className='flex flex-row items-center gap-[32px]'>
            <UiTextField className='!w-[233px]' name='Цена' {...price}></UiTextField>

            <div className='flex w-[100%] max-w-[233px] flex-col gap-[4px]'>
              <UiText className='text-grey'>Измерение</UiText>

              {measures && (
                <Dropdown
                  value={selectedMetering}
                  onChange={changeUnits}
                  options={measures}
                  optionLabel='text'
                  placeholder='Измерение'
                  className='md:w-14rem worker w-full border-green'
                  panelClassName='worker-panel'
                  color='green'
                />
              )}
            </div>

            <div className='flex w-[100%] max-w-[233px] flex-col gap-[4px]'>
              <UiText className='text-grey'>Значение</UiText>

              <Dropdown
                value={selectedValue}
                onChange={(e) => setSelectedValue(e.value)}
                options={units ?? undefined}
                optionLabel='text'
                placeholder='Значение'
                className='md:w-14rem worker w-full border-green'
                panelClassName='worker-panel'
                color='green'
                emptyMessage='Нужно выбрать измерение'
                disabled={selectedMetering?.text === 'За ребенка'}
              />
            </div>
            <UiTextField
              className='!w-[233px]'
              name='Надбавка за доп ребенка'
              disabled={selectedMetering?.text === 'За ребенка'}
              {...extraAdditionalChild}
            ></UiTextField>
          </div>
          <div className='flex-row'>
            <span className='text-base text-black text-grey'>Описание</span>
            <ReactQuill theme='snow' value={description} onChange={setDescription} />
          </div>
          <div className='flex w-[100%] max-w-[233px] flex-col gap-[4px]'>
            <UiText className='text-grey'>Привязка к патируму</UiText>

            <Dropdown
              value={selectedPatrium}
              onChange={(e) => setSelectedPatrium(e.value)}
              options={patriums}
              optionLabel='name'
              placeholder='Привязка к патриуму'
              className='md:w-14rem worker w-full border-green'
              panelClassName='worker-panel'
              color='green'
            />
          </div>
          <div className='flex flex-row gap-[33px]'>
            <LoadPhoto
              isDisabledFile={isDisabledFile}
              setDisabledFile={(isDisabledFile) => setDisabledFile(isDisabledFile)}
              arrayFile={files}
              setUploadFile={addFile}
              onClickDelete={onClickDeleteFile}
            />
          </div>
          <div className='mt-auto flex h-[52px] flex-row items-end gap-[16px] border-0 border-t-[1px] border-solid border-grey-secondary'>
            <UiButton onClick={sendForm} className='w-[233px]'>
              Сохранить
            </UiButton>
            <div className='flex items-center gap-[8px]'>
              <Checkbox useBoolState={useBoolStateCheckbox}></Checkbox>
              <UiText className='mb-[3px] text-grey'>Доступно на сайте</UiText>
            </div>
            <div className='flex items-center gap-[8px]'>
              <Checkbox useBoolState={showInCrmCheckbox}></Checkbox>
              <UiText className='mb-[3px] text-grey'>Доступно в CRM</UiText>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
})
