import { ApiUser } from '@@types/apiLogicTypes'
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton'
import { FC, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BonusesTable } from './BonusesTable/BonusesTable'
import { Form } from './Form/Form'
import { TicketTable } from './TicketTable/TicketTable'

export interface IMain {
  contact: ApiUser
  updateUserHandler?: (user: ApiUser) => void
}

const typeViews = [
  { link: '#main', name: 'Основное' },
  { link: '#promo', name: 'Бонусы' },
  { link: '#payments', name: 'Продажи и заявки' },
]

export const Main: FC<IMain> = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const hash = location.hash

  const [viewType, setViewType] = useState(typeViews.find((e) => e.link === hash) ?? typeViews[0])

  const onSelectButtonClickHandler = (e: SelectButtonChangeEvent) => {
    navigate(e.value.link)
    setViewType(e.value)
  }

  return (
    <div className='flex h-full w-full flex-col bg-white px-4 py-[20px]'>
      <SelectButton
        value={viewType}
        onChange={(e) => onSelectButtonClickHandler(e)}
        options={typeViews}
        optionLabel='name'
        className='selectButton mb-[20px]'
        allowEmpty={false}
      />
      {(!hash || hash === typeViews[0].link) && <Form {...props} />}
      {hash === typeViews[1].link && <BonusesTable {...props} />}
      {hash === typeViews[2].link && <TicketTable {...props} />}
    </div>
  )
}
