import Layout from '@layouts/NonScrollableLayout'
import { Column } from 'primereact/column'
import { DataTable, DataTableStateEvent } from 'primereact/datatable'

import { ApiUser } from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { AppRoutesPaths } from '@config/navigation'
import { useLogic, useStore } from '@hooks/storeHook'
import { formatPhone } from '@utils/textUtils'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { Calendar } from 'primereact/calendar'
import { useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

const ContactsPage = observer(() => {
  const logic = useLogic()
  const [totalRecords, setTotalRecords] = useState(0)
  const navigate = useNavigate()
  const [lazyState, setlazyState] = useState<DataTableStateEvent>({
    first: 0,
    rows: 10,
    page: 0,
    sortField: '',
    sortOrder: null,
    filters: {
      global: { value: null, matchMode: FilterMatchMode.BETWEEN },
      created_at: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
    },
    multiSortMeta: null,
  })

  const {
    users: { clients, loading },
    auth: { selectedPark },
  } = useStore()

  const onPage = (props: Required<DataTableStateEvent>) => {
    setlazyState(props)
  }

  const onSort = (props: Required<DataTableStateEvent>) => {
    console.log(props)
  }

  const onFilter = (props: Required<DataTableStateEvent>) => {
    props['first'] = 0
    setlazyState(props)
  }

  const loadLazyData = async () => {
    const { page, filters } = lazyState
    //@ts-ignore
    const { matchMode, value } = filters?.created_at?.constraints[0] || {}

    const formatDate = (date: Date) => moment(date).format('YYYY-MM-DD')

    let startDate, endDate

    if (matchMode === FilterMatchMode.DATE_IS && value) {
      startDate = endDate = formatDate(value)
    } else if (matchMode === FilterMatchMode.BETWEEN && Array.isArray(value)) {
      ;[startDate, endDate] = value.map(formatDate)
    }

    const response = await logic.loadClients(page! + 1, startDate, endDate)
    if (response?.data) {
      setTotalRecords(response?.data.total)
    }
  }
  useEffect(() => {
    loadLazyData()
  }, [selectedPark, lazyState])

  const onDoubleClickHandler = (item: ApiUser) => {
    const path = generatePath(AppRoutesPaths.ContactEdit, {
      id: String(item.id),
    })
    navigate(path)
  }

  const dateBodyTemplate = (rowData: ApiUser) => {
    return moment(rowData.created_at).format('DD.MM.YYYY')
  }

  const phoneFilterTemplate = (options: ApiUser) => {
    return <span>{options.phone && formatPhone(options.phone)}</span>
  }

  const dateFilterTemplate = (options: any) => {
    const selectionMode = options.filterModel.matchMode === FilterMatchMode.BETWEEN ? 'range' : 'single'

    return (
      <Calendar
        selectionMode={selectionMode}
        value={options.value}
        onChange={(e: any) => options.filterCallback(e.value, options.index)}
        dateFormat='dd.mm.yy'
        locale='ru'
        maxDate={new Date()}
        hideOnRangeSelection={selectionMode === 'range'}
        mask={selectionMode === 'range' ? '99.99.9999 - 99.99.9999' : '99.99.9999'}
      />
    )
  }

  return (
    <Layout title={'Контакты'} scrollable>
      <DataTable
        lazy
        totalRecords={totalRecords}
        onPage={onPage}
        filters={lazyState.filters}
        globalFilterFields={['created_at']}
        onSort={onSort}
        onFilter={onFilter}
        value={clients}
        loading={loading === LoadState.Loading}
        showGridlines
        size={'small'}
        filterLocale='ru'
        className={cn('bg-white text-base', 'ui-paginator-table')}
        style={{ fontSize: '12px' }}
        removableSort
        sortField={lazyState.sortField}
        first={lazyState.first}
        sortOrder={lazyState.sortOrder}
        emptyMessage='Нет данных'
        paginator
        rows={20}
        rowClassName={() => 'cursor-pointer'}
        onRowDoubleClick={(e) => onDoubleClickHandler(e.data as ApiUser)}
      >
        <Column selectionMode='multiple' headerStyle={{ width: '3rem' }}></Column>
        <Column
          field='name'
          header='ФИО'
          // sortable
        />
        <Column
          field='phone'
          header='Телефон'
          // sortable
          body={phoneFilterTemplate}
        />
        <Column
          filterField='created_at'
          header='Дата создания'
          dataType='date'
          body={dateBodyTemplate}
          filterMatchMode={FilterMatchMode.BETWEEN}
          showFilterMatchModes={true}
          filterMatchModeOptions={[
            { label: 'Период', value: 'between' },
            { label: 'За день', value: 'dateIs' },
          ]}
          showFilterMenuOptions={true}
          showAddButton={false}
          showFilterOperator={false}
          filter
          filterElement={dateFilterTemplate}
          filterApply={(e) => (
            <UiButton onClick={e.filterApplyCallback} className='capitalize'>
              Принять
            </UiButton>
          )}
          filterClear={(e) => (
            <UiButton
              onClick={e.filterClearCallback}
              className='!border border-solid border-blue capitalize'
              color='blue'
            >
              Очистить
            </UiButton>
          )}
        />
      </DataTable>
    </Layout>
  )
})

export default ContactsPage
