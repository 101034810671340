import { ToastContext } from '@context/toastContext'
import { useLogic } from '@hooks/storeHook'
import { useInput } from '@hooks/useInput'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import { useContext, useState } from 'react'
const Authorization = () => {
  const logic = useLogic()
  const context = useContext(ToastContext)
  const [isLoading, setIsLoading] = useState(false)

  const email = useInput()
  const password = useInput()

  async function onSendAuthorization() {
    if (email.value.length > 0 && password.value.length > 0) {
      setIsLoading(true)
      const { status, errors } = await logic.onSendAuthorization(email.value, password.value)
      if (!status) context?.toastShowHandler({ status: false, errors: errors })
    } else {
      context?.toastShowHandler({ status: false, errors: 'Заполните все поля' })
    }
    setIsLoading(false)
  }
  return (
    <div className='flex h-[100vh] w-[100%] flex-col items-center justify-center bg-grey-200'>
      <div className='flex w-[100%] max-w-[600px] flex-col gap-[20px] rounded-[24px] bg-white p-[40px] px-[60px]'>
        <div className='flex items-center justify-center gap-[20px]'>
          <div className='w-[100%] text-center'>Авторизация</div>
        </div>
        <div className='p-inputgroup flex-1'>
          <InputText {...email} placeholder='email ' />
        </div>
        <div className='w-full'>
          <Password
            {...password}
            feedback={false}
            tabIndex={1}
            placeholder='пароль'
            inputClassName='auth w-full'
            toggleMask
            className='min-w-full'
            panelClassName='min-w-full'
          />
        </div>
        <div className='w-[100%]'>
          <Button
            onClick={onSendAuthorization}
            className='w-[100%] justify-center gap-4 text-center'
            loading={isLoading}
          >
            Вход
          </Button>
        </div>
      </div>
    </div>
  )
}
export default Authorization
