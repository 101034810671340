import { ApiUser } from '@@types/apiLogicTypes'
import { AppRoutesPaths } from '@config/navigation'
import { useLogic } from '@hooks/storeHook'
import { ItemTemplate } from '@modules/Schedule/Constructor/EventForm/ClientPart'
import { formatPhone, normalizePhone } from '@utils/textUtils'
import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteCompleteEvent } from 'primereact/autocomplete'
import { useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { IconElement } from '../IconElement/IconElement'
import { UiButton } from '../UiButton/UiButton'

export const MainSearch = () => {
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [filteredUsers, setFilteredUsers] = useState<ApiUser[]>([])
  const logic = useLogic()

  const toTicketSaleHandler = () => {
    navigate(AppRoutesPaths.TicketSale)
  }

  const toConstructor = () => {
    navigate(AppRoutesPaths.Constructor)
  }

  const search = async (event: AutoCompleteCompleteEvent) => {
    let _filteredUsers: ApiUser[] = []

    if (event.query.trim().length < 7) {
      _filteredUsers = []
    } else {
      const response = await logic.searchUserByPhone(undefined, event.query)
      //@ts-ignore
      _filteredUsers = response
    }

    setFilteredUsers(_filteredUsers)
  }

  const onChangePhone = (e: AutoCompleteChangeEvent) => {
    if (e.target.value.phone) {
      const path = generatePath(AppRoutesPaths.ContactEdit, {
        id: String(e.target.value.id),
      })
      navigate(path)
    } else {
      handleInputChange(e.value)
    }
  }

  const handleInputChange = (e: string) => {
    const formattedValue = formatPhone(normalizePhone(e))
    if (e.length <= 16 && formattedValue.length > 0) {
      setName(formattedValue)
    } else {
      setName(e)
    }
  }

  return (
    <div className='flex w-[100%] gap-[8px]'>
      <div className='p-input-icon-left relative w-[100%]'>
        <div style={{ transform: 'translateY(-50%)' }} className='absolute left-[8px] top-[50%] z-10 h-[18px]'>
          <IconElement icon={'/icons/SearchIcon.svg'} />
        </div>
        <AutoComplete
          maxLength={16}
          field='name'
          value={name}
          suggestions={filteredUsers}
          delay={500}
          completeMethod={search}
          itemTemplate={(e) => ItemTemplate(e, name)}
          placeholder='Поиск по ФИО и номеру телефона'
          onChange={(e) => {
            onChangePhone(e)
          }}
          className='text-field h-[36px] w-[100%] rounded-[3px] !border-[0px] border-solid border-green bg-white !p-0 !py-[8px] px-[8px] !pl-6 text-grey focus:shadow-none focus:outline-none'
        />
      </div>
      <UiButton className='!min-w-[141px] whitespace-nowrap' onClick={toTicketSaleHandler}>
        Билет
      </UiButton>
      <UiButton className='!min-w-[141px]' color='blue' onClick={toConstructor}>
        Праздник
      </UiButton>
    </div>
  )
}
