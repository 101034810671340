import { Loader } from '@components/ui/Loader/Loader'
import { MainSearch } from '@components/ui/MainSearch/MainSearch'
import { UiText } from '@components/ui/UiText/UiText'
import { FC, ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

interface INonScrolabbleLayout {
  children?: ReactNode
  showHeader?: boolean
  scrollable?: boolean
  title?: string
  className?: string
  outlet?: boolean
  loading?: boolean
}

const Layout: FC<INonScrolabbleLayout> = ({
  title,
  children,
  showHeader = true,
  scrollable = false,
  className,
  outlet = false,
  loading = false,
}) => {
  return (
    <div className='relative mx-auto flex h-full w-full max-w-[1440px] flex-col max-sm:max-w-[calc(100vw_-_100px)]'>
      {showHeader && (
        <header className='mb-[14px] max-sm:hidden'>
          <MainSearch />
        </header>
      )}
      {!outlet ? (
        <main
          className={`flex h-[calc(100vh_-_82px)] w-full flex-col gap-4 bg-white p-[16px] max-sm:h-screen ${
            scrollable ? 'overflow-auto' : 'overflow-hidden'
          } relative ${className}`}
        >
          {loading && <Loader />}
          {!loading && title && <UiText className='!text-lg !font-bold'>{title}</UiText>}
          {!loading && children}
        </main>
      ) : (
        <Outlet />
      )}
    </div>
  )
}

export default Layout
