import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { ToastContext } from '@context/toastContext'
import { useStore } from '@hooks/storeHook'
import { CloseShift, OpenShift, Settlement, TerminalReport, XReport } from '@logic/kkmLogic'
import { formatFullName } from '@utils/textUtils'
import { FC, useContext } from 'react'

export const KKMPanel: FC = () => {
  const context = useContext(ToastContext)
  const {
    auth: { user },
  } = useStore()
  const operatorName = formatFullName(user!.name, user?.surname, user?.patronymic)

  const openHandler = async () => {
    const handleOpen = (res: any) => {
      if (res.Status === 2 || res.Status === 3) {
        context?.toastShowHandler({ status: false, errors: res.Error })
      } else {
        context?.toastShowHandler({ status: true, summary: 'Смена открыта' })
      }
    }
    await OpenShift(operatorName, context!, handleOpen)
  }

  const closeHandler = async () => {
    const handleClose = (res: any) => {
      if (res.Status === 2 || res.Status === 3) {
        context?.toastShowHandler({ status: false, errors: res.Error })
      } else {
        context?.toastShowHandler({ status: true, summary: 'Смена закрыта' })
      }
    }
    await CloseShift(operatorName, context!, handleClose)
  }

  const xReportHandler = async () => {
    await XReport(context!)
  }

  const terminalReportHandler = async () => {
    await TerminalReport(context!)
  }

  const settlementHandler = async () => {
    await Settlement(context!)
  }

  return (
    <section className='flex h-full w-full flex-col gap-6 bg-white p-4'>
      <UiText className='text-lg font-bold'>Отчеты и смены</UiText>

      <section className='flex flex-col gap-2'>
        <UiText className='text-md'>Управление сменами</UiText>

        <div className='flex gap-4'>
          <UiButton className='w-[150px]' color='blue' onClick={openHandler}>
            Открыть смену
          </UiButton>
          <UiButton className='w-[150px]' color='red' onClick={closeHandler}>
            Закрыть смену (z-отчет)
          </UiButton>
        </div>
      </section>

      <section className='flex flex-col gap-2'>
        <UiText className='text-md'>Управление фискализатором</UiText>
        <UiButton className='w-[150px]' color='blue' onClick={xReportHandler}>
          Отчет без гашения (X-отчет)
        </UiButton>
      </section>

      <section className='flex flex-col gap-2'>
        <UiText className='text-md'>Управление эквайрингом</UiText>

        <div className='flex gap-4'>
          <UiButton className='w-[150px]' color='blue' onClick={terminalReportHandler}>
            Отчет без гашения (по картам)
          </UiButton>
          <UiButton className='w-[150px]' color='red' onClick={settlementHandler}>
            сверка итогов (обнуление)
          </UiButton>
        </div>
      </section>
    </section>
  )
}
