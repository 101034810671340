import { AppRoutesPaths, navigateTo } from '@config/navigation'

import { ServicesTable } from '@components/ui/ContainerTable/ServicesTable'
import { SettingsHeaderBtn } from '@components/ui/SettingsHeaderBtn/SettingsHeaderBtn'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

export const AdminSettingsServicesScreen = observer(() => {
  const logic = useLogic()
  const {
    services: { adminServices },
  } = useStore()

  const onAdd = () => {
    navigateTo(AppRoutesPaths.AdminPanelServicesAdd)
  }

  useEffect(() => {
    logic.loadAdminServices()
    logic.loadRooms()
  }, [])

  return (
    <Layout title='Услуги' scrollable={true}>
      <div className={'no-scroll flex h-full flex-col gap-[24px] overflow-scroll bg-white'}>
        <div className='flex h-full flex-col gap-[16px] overflow-x-auto rounded-[3px]'>
          <SettingsHeaderBtn textAdd={'Услугу'} onClickAdd={onAdd} textArchive={'Услуги'} isAdmin={true} />
          <ServicesTable isAdmin services={adminServices} />
        </div>
      </div>
    </Layout>
  )
})
