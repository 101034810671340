import { City } from '@@types/apiLogicTypes'
import { AppRoutesPaths } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useBoolState } from '@hooks/commonHooks'
import { useLogic, useStore } from '@hooks/storeHook'
import { useInput } from '@hooks/useInput'
import Layout from '@layouts/NonScrollableLayout'
import { observer } from 'mobx-react-lite'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Checkbox } from '../Checkbox/Checkbox'
import { UiText } from '../UiText/UiText'
interface LocationState {
  selectedPark: number
}

export const brands = [
  {
    id: 1,
    name: 'Скалалэнд',
  },
  { id: 2, name: 'Люмопарк' },
]

export const EditPark = observer(() => {
  const navigate = useNavigate()
  const locationNav = useLocation()
  const selectedParkObj = (locationNav.state as LocationState) || undefined
  const {
    park: { cities, getPark, getCity },
  } = useStore()

  const parkSelected = getPark(selectedParkObj.selectedPark)

  if (!parkSelected) {
    navigate(AppRoutesPaths.AdminPanelPark)
    return <></>
  }
  const name = useInput(parkSelected.name ?? '')
  const slug = useInput(parkSelected.slug ?? '')
  const address = useInput(parkSelected.address ?? '')
  const location = useInput(parkSelected.location?.toString() ?? '')
  const email1 = useInput(parkSelected.emails && parkSelected.emails[0] ? parkSelected.emails[0] : '')
  const email2 = useInput(parkSelected.emails && parkSelected.emails[1] ? parkSelected.emails[1] : '')
  const email3 = useInput(parkSelected.emails && parkSelected.emails[2] ? parkSelected.emails[2] : '')
  const isActive = useBoolState(Boolean(parkSelected?.active))

  const logic = useLogic()

  const context = useContext(ToastContext)

  const city = getCity(parkSelected.city_id ?? -1)

  const [selectedCity, setSelectedCity] = useState<City | null>(city ?? null)

  const [selectedBrandId, setSelectedBrandId] = useState<(typeof brands)[number] | undefined>(
    brands.find((e) => e.id === parkSelected!.brand_id) ?? brands[0]
  )

  async function onAddPark() {
    if (selectedCity && name.value.length > 3 && slug.value.length > 3 && address.value.length > 3 && parkSelected) {
      const emails = []
      if (email1.value.length > 0) {
        emails.push(email1.value)
      }
      if (email2.value.length > 0) {
        emails.push(email2.value)
      }
      if (email3.value.length > 0) {
        emails.push(email3.value)
      }

      const response = await logic.editPark(
        {
          name: name.value,
          slug: slug.value,
          address: address.value,
          location: typeof location.value === 'string' ? location.value.split(',') : location.value,
          city_id: selectedCity.id,
          emails: emails,
          brand_id: selectedBrandId?.id,
          active: isActive[0] ? 1 : 0,
        },
        parkSelected?.id
      )

      if (response) {
        navigate(AppRoutesPaths.AdminPanelPark)
      }
    } else {
      context?.toastShowHandler({ status: false, errors: 'Заполните все поля' })
    }
  }

  return (
    <Layout title='Изменить центр' scrollable={true}>
      <div className='flex h-full flex-col gap-[16px] overflow-x-visible rounded-[3px]'>
        <div className='flex flex-col gap-[16px]'>
          <div>
            <UiText size='lg'>Название Центра</UiText>
          </div>
          <div className='p-inputgroup flex-1'>
            <InputText {...name} placeholder='name' />
          </div>
          <div>
            <UiText size='lg'>Слаг Центра</UiText>
          </div>
          <div className='p-inputgroup flex-1'>
            <InputText {...slug} placeholder='slug' />
          </div>
          <div>
            <UiText size='lg'>Адрес Центра</UiText>
          </div>
          <div className='p-inputgroup flex-1'>
            <InputText {...address} placeholder='address' />
          </div>
          <div>
            <UiText size='lg'>Локация Центра</UiText>
          </div>
          <div className='p-inputgroup flex-1'>
            <InputText {...location} placeholder='location' />
          </div>
          <div className='flex flex-col gap-[16px]'>
            <div>
              <UiText size='lg'>Город Центра</UiText>
            </div>
            {cities && (
              <Dropdown
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.value)}
                options={cities}
                optionLabel='name'
                placeholder='Select a City'
                className='md:w-14rem w-full'
              />
            )}
          </div>
          <div>
            <UiText size='lg'>Почты для заявок</UiText>
          </div>
          <div className='p-inputgroup flex-1 gap-2'>
            <InputText {...email1} placeholder='email' />
            <InputText {...email2} placeholder='email' />
            <InputText {...email3} placeholder='email' />
          </div>
        </div>

        <div className='flex gap-4'>
          <div className='flex w-[100%] max-w-[233px] flex-col gap-4'>
            <UiText size='lg'>Бренд</UiText>

            <Dropdown
              value={selectedBrandId}
              onChange={(e) => setSelectedBrandId(e.value)}
              options={brands}
              optionLabel='name'
              placeholder='Бренд'
              className='md:w-14rem w-full'
            />
          </div>

          <div className='mt-auto flex items-center gap-[8px]'>
            <Checkbox useBoolState={isActive}></Checkbox>
            <UiText className='mb-[3px] text-grey'>Доступно на сайте</UiText>
          </div>
        </div>

        <Button
          onClick={onAddPark}
          severity='success'
          className='hover:bg-green-[#16A34A] !mt-auto max-h-[36px] w-[100%] justify-center !bg-green !text-sm !font-black uppercase'
        >
          Изменить центр
        </Button>
      </div>
    </Layout>
  )
})
