import { ApiCashReport } from '@@types/apiLogicTypes'
import FormInput from '@components/ui/Form/FormInput'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { createFormData } from '@utils/createDataForm'
import { customBaseUploader, onUpload } from '@utils/fileLoader'
import { Dialog } from 'primereact/dialog'
import { FileUpload } from 'primereact/fileupload'
import { Image } from 'primereact/image'
import { FC, useContext, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { TCashboxAction } from '../IntegrationPage'
import { TCashboxCollection, TExpenseReportForm, TRKOForm } from '../types'

interface IForm {
  type: TCashboxAction
  callback: (flag: boolean, action?: TCashboxAction) => void
  initialState?: ApiCashReport
}

export const Form: FC<IForm> = ({ type, callback, initialState }) => {
  const formSwitcher = () => {
    switch (type) {
      case 2: {
        return CashboxCollectionForm(callback, initialState)
      }
      case 3: {
        return ExpenseReportForm(callback, initialState)
      }
      case 4: {
        return RKOForm(callback, initialState)
      }
    }
  }

  return <div>{formSwitcher()}</div>
}

const RKOForm = (callback: (flag: boolean, action?: TCashboxAction) => void, initialState?: ApiCashReport) => {
  const methods = useForm<TRKOForm>({
    defaultValues: {
      title: initialState?.name,
      sum: initialState ? String(initialState.sum) : '',
    },
  })
  const {
    auth: { selectedPark, user },
    payments: { changeCashBoxSum, changeCashboxReport, addCashboxReport },
  } = useStore()
  const logic = useLogic()
  const context = useContext(ToastContext)
  const [file, setFile] = useState<File | null>(null)
  const ref = useRef(null)
  const [visible, setVisible] = useState(false)

  const openPdf = () => {
    setVisible(true)
  }

  const onSubmit = async (data: TRKOForm) => {
    if (!file && !initialState) {
      context?.toastShowHandler({ status: false, errors: 'Пожалуйста, приложите файл' })
      return
    }
    const formData = createFormData({ park_id: selectedPark?.id, sum: data.sum, type: 4, name: data.title })
    file && formData.append('file', file)
    const { status, errors, balance, log } = initialState
      ? await logic.updateLogReport(initialState.id, formData)
      : await logic.cashboxMinus(formData)
    if (status) {
      initialState ? changeCashboxReport(log) : addCashboxReport(log)
      changeCashBoxSum(balance)
      methods.reset()
      setFile(null)
      callback(false)
    } else context?.toastShowHandler({ status: false, errors: errors })
  }

  return (
    <FormProvider {...methods}>
      <form className='flex w-full flex-col gap-4' onSubmit={methods.handleSubmit(onSubmit)}>
        <UiText className='text-lg font-bold'>РКО</UiText>
        <FormInput name='title' placeholder='Название' required={true} />
        <FormInput name='sum' placeholder='Сумма' required={true} />
        <div className={`flex flex-col gap-[4px]`}>
          <UiText className='text-grey'>Приложите файл:</UiText>
          <FileUpload
            className='h-9 w-[233px] max-w-[233px] overflow-hidden whitespace-nowrap'
            customUpload
            mode='basic'
            name='demo[]'
            onUpload={() => onUpload(context!)}
            auto
            accept='.pdf, image/*'
            uploadHandler={(e) => customBaseUploader(e, ref, setFile, context!)}
            ref={ref}
            chooseLabel={file?.name ?? initialState?.file_path?.split('/')?.pop() ?? 'Выбрать'}
          />
        </div>
        {((initialState?.file_path?.match(/\.(jpeg|jpg|gif|png)$/) != null && !file) ||
          file?.type.includes('image')) && (
          <Image
            //@ts-ignore
            src={file?.objectURL ?? initialState?.file_path}
            //@ts-ignore
            zoomSrc={file?.objectURL ?? initialState?.file_path}
            preview
            height='80'
            width='70'
            className='w-[70px]'
          />
        )}
        {((initialState?.file_path?.includes('.pdf') && !file) || file?.type.includes('pdf')) && (
          <>
            <UiText onClick={openPdf} className='cursor-pointer text-grey underline'>
              Посмотреть PDF
            </UiText>
            <Dialog
              visible={visible}
              modal
              onHide={() => {
                if (!visible) return
                setVisible(false)
              }}
              dismissableMask
              className='flex h-[80%] w-[80%] items-center justify-center'
              content={() => (
                <div className='h-full w-full'>
                  {/* @ts-ignore */}
                  <iframe src={file?.objectURL ?? initialState?.file_path} className='h-full w-full' />
                </div>
              )}
            ></Dialog>
          </>
        )}
        {user?.roles.some((e) => e.name === 'manager') && initialState?.status === 0 && (
          <UiText className='text-sm text-red'>
            *При изменении суммы необходимо будет провести платеж <span className='underline'>заново</span>
          </UiText>
        )}
        <UiButton>{initialState ? 'Изменить' : 'Отправить'} РКО</UiButton>
      </form>
    </FormProvider>
  )
}

const ExpenseReportForm = (
  callback: (flag: boolean, action?: TCashboxAction) => void,
  initialState?: ApiCashReport
) => {
  const methods = useForm<TExpenseReportForm>({
    defaultValues: {
      title: initialState?.name,
      sum: initialState ? String(initialState?.sum) : '',
      checkNumber: initialState?.check_number,
    },
  })
  const {
    auth: { selectedPark, user },
    payments: { changeCashBoxSum, addCashboxReport, changeCashboxReport },
  } = useStore()
  const logic = useLogic()
  const context = useContext(ToastContext)
  const [file, setFile] = useState<File | null>(null)
  const ref = useRef(null)
  const [visible, setVisible] = useState(false)

  const openPdf = () => {
    setVisible(true)
  }

  const onSubmit = async (data: TExpenseReportForm) => {
    if (!file && !initialState) {
      context?.toastShowHandler({ status: false, errors: 'Пожалуйста, приложите файл' })
      return
    }
    const formData = createFormData({
      park_id: selectedPark?.id,
      sum: data.sum,
      type: 3,
      name: data.title,
      check_number: data.checkNumber,
    })
    file && formData.append('file', file)
    const { status, errors, log, balance } = initialState
      ? await logic.updateLogReport(initialState.id, formData)
      : await logic.cashboxMinus(formData)
    if (status) {
      initialState ? changeCashboxReport(log) : addCashboxReport(log)
      changeCashBoxSum(balance)
      methods.reset()
      setFile(null)
      callback(false)
    } else context?.toastShowHandler({ status: false, errors: errors })
  }

  return (
    <FormProvider {...methods}>
      <form className='flex w-full flex-col gap-4' onSubmit={methods.handleSubmit(onSubmit)}>
        <UiText className='text-lg font-bold'>Авансовый отчет</UiText>
        <FormInput name='title' placeholder='Название' required={true} />
        <FormInput name='sum' placeholder='Сумма' required={true} />
        <FormInput name='checkNumber' placeholder='Номер чека' required={true} />
        <div className={`flex flex-col gap-[4px]`}>
          <UiText className='text-grey'>Приложите файл:</UiText>
          <FileUpload
            className='h-9 w-[233px] max-w-[233px] overflow-hidden whitespace-nowrap'
            customUpload
            mode='basic'
            name='demo[]'
            onUpload={() => onUpload(context!)}
            auto
            accept='.pdf, image/*'
            uploadHandler={(e) => customBaseUploader(e, ref, setFile, context!)}
            ref={ref}
            chooseLabel={file?.name ?? initialState?.file_path?.split('/')?.pop() ?? 'Выбрать'}
          />
        </div>
        {((initialState?.file_path?.match(/\.(jpeg|jpg|gif|png)$/) != null && !file) ||
          file?.type.includes('image')) && (
          <Image
            //@ts-ignore
            src={file?.objectURL ?? initialState?.file_path}
            //@ts-ignore
            zoomSrc={file?.objectURL ?? initialState?.file_path}
            preview
            height='80'
            width='70'
            className='w-[70px]'
          />
        )}
        {((initialState?.file_path?.includes('.pdf') && !file) || file?.type.includes('pdf')) && (
          <>
            <UiText onClick={openPdf} className='cursor-pointer text-grey underline'>
              Посмотреть PDF
            </UiText>
            <Dialog
              visible={visible}
              modal
              onHide={() => {
                if (!visible) return
                setVisible(false)
              }}
              dismissableMask
              className='flex h-[80%] w-[80%] items-center justify-center'
              content={() => (
                <div className='h-full w-full'>
                  {/* @ts-ignore */}
                  <iframe src={file?.objectURL ?? initialState?.file_path} className='h-full w-full' />
                </div>
              )}
            ></Dialog>
          </>
        )}
        {user?.roles.some((e) => e.name === 'manager') && initialState?.status === 0 && (
          <UiText className='text-sm text-red'>
            *При изменении суммы необходимо будет провести платеж <span className='underline'>заново</span>
          </UiText>
        )}
        <UiButton>{initialState ? 'Изменить' : 'Отправить'} авансовый отчет</UiButton>
      </form>
    </FormProvider>
  )
}

const CashboxCollectionForm = (
  callback: (flag: boolean, action?: TCashboxAction) => void,
  initialState?: ApiCashReport
) => {
  const methods = useForm<TCashboxCollection>({
    defaultValues: {
      sum: initialState ? String(initialState.sum) : '0',
    },
  })
  const {
    auth: { selectedPark },
    payments: { changeCashBoxSum, changeCashboxReport, addCashboxReport },
  } = useStore()
  const logic = useLogic()
  const context = useContext(ToastContext)

  const onSubmit = async (data: TCashboxCollection) => {
    const formData = createFormData({ park_id: selectedPark?.id, sum: data.sum })
    const { status, errors, balance, log } = initialState
      ? await logic.updateLogReport(initialState.id, formData)
      : await logic.cashboxCollection(formData)
    if (status) {
      initialState ? changeCashboxReport(log) : addCashboxReport(log)
      changeCashBoxSum(balance)
      methods.reset()
      callback(false)
    } else context?.toastShowHandler({ status: false, errors: errors })
  }

  return (
    <FormProvider {...methods}>
      <form className='flex w-full flex-col gap-4' onSubmit={methods.handleSubmit(onSubmit)}>
        <UiText className='text-lg font-bold'>Инкассация</UiText>
        <FormInput name='sum' placeholder='Сумма' required={true} />
        <UiButton>Подтвердить</UiButton>
      </form>
    </FormProvider>
  )
}
