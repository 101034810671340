import { ApiServices, ApiServicesCreateForm, ApiServicesUnits } from '@@types/apiLogicTypes'
import logo from '@assets/images/skala.png'
import { AppRoutesPaths } from '@config/navigation'
import { ArchiveContext } from '@context/archiveContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { findItem } from '@utils/getCategory'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useContext } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { UiText } from '../UiText/UiText'
import { TableButtons } from './TableButtons'

import { ToastContext } from '@context/toastContext'
import {
  checkBoxBodyTemplate,
  imgBodyTemplate,
  measureBodyTemplate,
  priceBodyTemplate,
} from '../ColumnContents/ColumnContents'

export type ServicesTableProps = {
  services: ApiServices[]
  isAdmin?: boolean
}
export const ServicesTable = observer(({ services, isAdmin }: ServicesTableProps) => {
  const {
    services: { units },
  } = useStore()

  const logic = useLogic()
  const navigate = useNavigate()

  const archiveContext = useContext(ArchiveContext)
  const toastContext = useContext(ToastContext)

  async function onDelete(services: ApiServices) {
    if (services.deleted === 0) {
      const { status, errors } = await logic.deleteService(services.id)
      if (!status) {
        toastContext?.toastShowHandler({ status, errors })
      } else logic.loadServices()
    } else {
      const { status, errors } = await logic.editServices({ deleted: 0 }, services.id)
      if (!status) {
        toastContext?.toastShowHandler({ status, errors })
      } else logic.loadServices(true)
    }
  }

  function onClickEdit(services: ApiServices) {
    let path: string = ''

    if (isAdmin) {
      path = generatePath(AppRoutesPaths.AdminPanelServicesUpdate, {
        id: String(services.id),
      })
      navigate(path)
    } else {
      path = generatePath(AppRoutesPaths.SettingsEditService, {
        id: String(services.id),
      })
      navigate(path)
    }
  }

  async function onChangeActive(rowData: { active: number | boolean; [key: string]: any }) {
    const form = new FormData()
    form.append('active', String(Number(!Number(rowData.active))))

    await logic.editActiveServices(form as Partial<ApiServicesCreateForm>, rowData.id)
  }

  const buttonBodyTemplate = (rowData: ApiServices) => {
    return (
      <TableButtons
        onClickEdit={!archiveContext?.inArchive ? () => onClickEdit(rowData) : undefined}
        onClickBox={!isAdmin ? () => onDelete(rowData) : undefined}
      />
    )
  }

  const nameBodyTemplateWithIcon = (rowData: ApiServices) => {
    return (
      <div className='flex items-center justify-between'>
        <UiText onClick={() => onClickEdit(rowData)}>{rowData.name}</UiText>
        {rowData?.group_id && <img src={logo} alt='Создано администратором' className='ml-2 h-3 w-3' />}
      </div>
    )
  }

  return (
    <>
      <DataTable
        value={services.filter((item) => {
          return findItem(units ?? [], item.unit_id) as ApiServicesUnits
        })}
        showGridlines
        className={classnames('bg-white text-base')}
        style={{ fontSize: '12px' }}
        removableSort
        sortField='name'
        sortOrder={1}
        emptyMessage='Нет данных'
        // filters={filters}
      >
        <Column field='photos' body={imgBodyTemplate} header='Фото' bodyClassName='text-center'></Column>
        <Column
          field='name'
          header='Название'
          sortable
          body={nameBodyTemplateWithIcon}
          // filter
          // filterPlaceholder='Поиск по названию'
          // showAddButton={false}
        ></Column>
        <Column field='category.name' header='Категория' sortable></Column>
        {!isAdmin && (
          <Column field='price' header='Цена' sortable body={(item) => priceBodyTemplate(item.price)}></Column>
        )}
        <Column
          field='unit_id'
          header='Измерение'
          body={(rowData: ApiServices) => measureBodyTemplate(rowData, units ?? null)}
        ></Column>
        {!isAdmin && <Column field='sort' header='Сортировка' sortable></Column>}
        {!archiveContext?.inArchive && !isAdmin && (
          <Column field='active' header='Сайт' body={(item) => checkBoxBodyTemplate(item, onChangeActive)}></Column>
        )}
        <Column field='' header='' body={buttonBodyTemplate}></Column>
      </DataTable>
    </>
  )
})
