import { UiButton } from '@components/ui/UiButton/UiButton'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { Calendar } from 'primereact/calendar'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { DataTable } from 'primereact/datatable'
import { Row } from 'primereact/row'
import { useContext, useEffect, useState } from 'react'

type TTableObject = {
  title: string
  count: number
  price: number
}

type TPaymentsTableObject = {
  cash: number
  card: number
  online: number
  bonuses: number
  total: number
}

export const TotalReport = observer(() => {
  const logic = useLogic()
  const [loading, setLoading] = useState(true)

  const [dateFrom, setDateFrom] = useState<Date>(new Date())
  const [dateTo, setDateTo] = useState<Date>(new Date())
  const [payments, setPayments] = useState<TTableObject[]>([])
  const [paymentType, setPaymentsType] = useState<TPaymentsTableObject>({
    cash: 0,
    card: 0,
    online: 0,
    bonuses: 0,
    total: 0,
  })

  const context = useContext(ToastContext)

  const {
    auth: { selectedPark },
  } = useStore()

  const loadData = async () => {
    setLoading(true)

    const response = await logic.loadTotalReport(
      moment(dateFrom, 'YYYY-MM-DD').toDate().toLocaleDateString('en-CA'),
      moment(dateTo, 'YYYY-MM-DD').toDate().toLocaleDateString('en-CA')
    )

    const paymentsArray: TTableObject[] = []
    let sumWithoutSales = 0

    if (response && response?.data) {
      response.data.tickets &&
        //@ts-ignore
        Object.entries(response.data.tickets).forEach(([title, { sum, count }]) => {
          paymentsArray.push({ title, count, price: sum })
          sumWithoutSales += sum
        })

      response.data.discounts &&
        //@ts-ignore
        Object.entries(response.data.discounts).forEach(([title, { sum, count }]) => {
          paymentsArray.push({ title, count, price: sum })
        })

      response.data.services &&
        //@ts-ignore
        Object.entries(response.data.services).forEach(([title, { sum, count }]) => {
          paymentsArray.push({ title, count, price: sum })
          sumWithoutSales += sum
        })

      response.data.holidays &&
        //@ts-ignore
        Object.entries(response.data.holidays).forEach(([title, { sum, count }]) => {
          paymentsArray.push({ title, count, price: sum })
          sumWithoutSales += sum
        })

      setPaymentsType({
        total: sumWithoutSales,
        cash: response.data?.payments?.['0'] ?? 0,
        card: response.data?.payments?.['1'] ?? 0,
        online: response.data?.payments?.['7'] ?? 0,
        bonuses: response.data?.bonuses ?? 0,
      })

      setPayments(paymentsArray)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (!selectedPark) return
    loadData()
  }, [selectedPark])

  const onClickHandler = () => {
    if (!dateFrom || !dateTo || dateTo < dateFrom)
      context?.toastShowHandler({ status: false, errors: 'Некорректное заполнение дат' })
    else loadData()
  }

  const countTotal = () => payments.reduce((sum, item) => sum + item.count, 0)

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer='Итого:' colSpan={1} footerStyle={{ textAlign: 'right' }} />
        <Column footer={countTotal} />
        <Column footer={paymentType.total} />
      </Row>
    </ColumnGroup>
  )

  return (
    <Layout title='Полный отчет'>
      <div className='flex gap-4'>
        <Calendar
          value={dateFrom}
          onChange={(e) => setDateFrom(e.value!)}
          showIcon={false}
          dateFormat='dd.mm.yy'
          locale='ru'
          readOnlyInput={true}
          className={`worker w-[85px] shadow-none`}
          inputClassName='shadow-none border-green'
          placeholder='от'
        />

        <Calendar
          value={dateTo}
          onChange={(e) => setDateTo(e.value!)}
          showIcon={false}
          dateFormat='dd.mm.yy'
          locale='ru'
          readOnlyInput={true}
          className={`worker w-[85px] shadow-none`}
          inputClassName='shadow-none border-green'
          placeholder='до'
        />

        <UiButton onClick={onClickHandler}>Сформировать отчет</UiButton>
      </div>

      <DataTable
        value={payments}
        loading={loading}
        showGridlines
        size={'small'}
        filterLocale='ru'
        className={'ui-paginator-table bg-white text-base'}
        style={{ fontSize: '12px' }}
        removableSort
        emptyMessage='Данные не найдены'
        paginator
        rows={20}
        scrollable
        footerColumnGroup={footerGroup}
      >
        <Column header='Номенклатура' field='title' />
        <Column header='Количество' field='count' />
        <Column header='Сумма' field='price' />
      </DataTable>

      <DataTable
        value={[paymentType]}
        loading={loading}
        showGridlines
        size={'small'}
        filterLocale='ru'
        className={'ui-paginator-table bg-white text-base'}
        style={{ fontSize: '12px' }}
        removableSort
        emptyMessage='Данные не найдены'
        rows={1}
        scrollable
      >
        <Column header='Наличный расчет' field='cash' />
        <Column header='Безналичный расчет' field='card' />
        <Column header='Оплачено бонусами' field='bonuses' />
        <Column header='Оплачено с сайта' field='online' />
      </DataTable>
    </Layout>
  )
})
