import { ApiTicket } from '@@types/apiLogicTypes'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { useStore } from '@hooks/storeHook'
import { TicketPaymentFormType } from '@modules/Reception/types'
import { isWeekend } from '@utils/formatDates'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { FC } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { IDisabled } from '../Form'

export const TicketPart: FC<IDisabled> = observer(({ disable }) => {
  const { control } = useFormContext<TicketPaymentFormType>()

  const { fields, update, append, remove } = useFieldArray({
    control,
    name: 'newTickets',
  })

  const {
    advertising: { discounts },
    ticket: { activeTickets },
  } = useStore()

  const ticketBodyTemplate = (rowIndex: number) => {
    return (
      <Dropdown
        value={fields[rowIndex].entity}
        onChange={(e) => {
          update(rowIndex, {
            ...fields[rowIndex],
            entity: e.value,
            totalPrice: calcPriceToDiscount(rowIndex, isWeekend() ? e.value.weekend_price : e.value.weekday_price),
          })
          if (!fields[rowIndex].entity) append({ entity: null, totalPrice: 0, discount: null })
        }}
        options={activeTickets.filter((e) => (isWeekend(new Date()) ? e.weekend_price > 0 : e.weekday_price > 0))}
        panelClassName='worker-panel'
        color='green'
        optionLabel='name'
        placeholder='Выберите билет'
        className={classNames(`md:w-14rem worker flex h-[36px] w-full items-center border-green`)}
        disabled={disable}
      />
    )
  }

  const discountBodyTemplate = (rowIndex: number) => {
    if (!fields[rowIndex].entity) return null

    return (
      <Dropdown
        optionLabel='name'
        placeholder='Выберите скидку'
        options={discounts}
        showClear
        value={fields[rowIndex].discount}
        onChange={(e) => {
          if (e.value)
            update(rowIndex, {
              ...fields[rowIndex],
              discount: e.value,
              totalPrice: calcDiscountToPrice(rowIndex, e.value.amount, e.value.type),
            })
          else
            update(rowIndex, {
              ...fields[rowIndex],
              discount: null,
              totalPrice: isWeekend() ? fields[rowIndex].entity!.weekend_price : fields[rowIndex].entity!.weekday_price,
            })
        }}
        panelClassName='worker-panel'
        color='green'
        emptyMessage='Не найдено 😢'
        className={classNames(`md:w-14rem worker flex h-[36px] w-full items-center border-green`)}
        disabled={disable}
      />
    )
  }

  const calcDiscountToPrice = (i: number, amount: number, type: number) => {
    const price = isWeekend() ? fields[i].entity?.weekend_price : fields[i].entity?.weekday_price

    if (!price) return 0

    let res = 0
    if (amount && type === 1) {
      res = price - amount
    } else if (amount && type === 2) {
      res = price - Math.ceil((price * amount) / 100)
    } else {
      res = price
    }

    return Math.max(0, res)
  }

  const calcPriceToDiscount = (i: number, price: number) => {
    const discountValue = fields[i].discount?.amount
    const discountType = fields[i].discount?.type

    let res = 0
    if (discountValue && discountType === 1) {
      res = price - +discountValue
    } else if (discountValue && discountType === 2) {
      res = price - Math.ceil((price * +discountValue) / 100)
    } else {
      res = price
    }

    return Math.max(0, res)
  }

  const deleteRowTemplate = (i: number) => {
    if (!fields[i].entity) return null

    return (
      <UiButton color='red' onClick={() => remove(i)}>
        <i className='pi pi-trash !text-white'></i>
      </UiButton>
    )
  }

  const sumBodyTemplate = (i: number) => {
    if (!fields[i].entity) return null

    return <UiText className='font-bold'>{fields[i].totalPrice}</UiText>
  }

  const textDiscountBodyTemplate = (i: number) => {
    if (!fields[i].entity) return null

    return <UiText className='font-bold'>{fields[i].discount?.amount ?? 0}</UiText>
  }

  const priceBodyTemplate = (i: number) => {
    if (!fields[i].entity) return null

    return (
      <UiText className='font-bold'>
        {isWeekend(new Date()) ? fields[i].entity?.weekend_price : fields[i].entity?.weekday_price}
      </UiText>
    )
  }

  return (
    <>
      <UiText size='lg' weight='bold'>
        Билеты
      </UiText>
      <section className='flex flex-col gap-5'>
        <DataTable
          value={fields}
          showGridlines
          size={'small'}
          filterLocale='ru'
          className='ui-paginator-table bg-white text-base'
          style={{ fontSize: '12px' }}
          removableSort
          emptyMessage='Билеты не найдены'
          scrollable
          scrollHeight='flex'
          dataKey='id'
        >
          <Column field='name' header='Билет' body={(e: ApiTicket, options) => ticketBodyTemplate(options.rowIndex)} />
          <Column header='Цена' body={(_, props) => priceBodyTemplate(props.rowIndex)} />
          <Column header='Тип скидки' body={(_, props) => discountBodyTemplate(props.rowIndex)} />
          <Column header='Сумма скидки' body={(_, props) => textDiscountBodyTemplate(props.rowIndex)} />
          <Column header='Итого' body={(_, props) => sumBodyTemplate(props.rowIndex)} />
          {!disable && (
            <Column header='' body={(_, props) => deleteRowTemplate(props.rowIndex)} className='max-w-[39px]' />
          )}
        </DataTable>
      </section>
    </>
  )
})
