import { ApiPayment } from '@@types/apiLogicTypes'
import { PinModal } from '@components/ui/PinModal/PinModal'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { AppRoutesPaths } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import {
  GetDataCheck,
  IKkmClient,
  IKkmItem,
  IKkmPaymentCardResult,
  IKkmRegisterCheckResult,
  PayByPaymentCard,
  RegisterCheck,
} from '@logic/kkmLogic'
import { TicketPaymentFormType } from '@modules/Reception/types'
import ChildPart from '@modules/Schedule/Constructor/EventForm/ChildPart'
import ClientPart from '@modules/Schedule/Constructor/EventForm/ClientPart'
import fontkit from '@pdf-lib/fontkit'
import { createFormData } from '@utils/createDataForm'
import { isWeekend } from '@utils/formatDates'
import { generateOnlinePayLink } from '@utils/generateOnlinePayLink'
import { formatFullName, formatPhone } from '@utils/textUtils'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { PDFDocument, rgb } from 'pdf-lib'
import printJS from 'print-js'
import { FC, useContext, useEffect, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { generatePath, useNavigate } from 'react-router-dom'
import { ManualApplyButton } from './ManualApplyButton/ManualApplyButton'
import { RefountButton } from './RefoundButton/RefoundButton'
import { ServicesPart } from './ServicesPart/ServicesPart'
import { TicketPart } from './TicketPart/TicketPart'
import { TotalPrice, typePaymentArray } from './TotalPrice/TotalPrice'

interface IForm {
  loadPaymentHandler: () => Promise<void>
  showCheckHandler?: () => void
  initialPayment?: ApiPayment
  updatePaymentHandler?: (payment: ApiPayment, flag?: boolean) => void
}

export interface IDisabled {
  disable?: boolean
}

export const Form: FC<IForm & IDisabled> = observer(
  ({ initialPayment, updatePaymentHandler, showCheckHandler, loadPaymentHandler }) => {
    const logic = useLogic()
    const {
      auth: { selectedPark, user },
      ticket: { activeTickets },
      advertising: { discounts },
      services: { services },
    } = useStore()
    const navigate = useNavigate()

    const isPreOrderInclude = initialPayment ? initialPayment?.sum < initialPayment?.order.price : false

    const isOrderPrePaid = initialPayment?.order.status === 5

    const [isPayButtonLoading, setIsPayButtonLoading] = useState(false)

    const [isPinModalVisible, setIsPinModalVisible] = useState(false)

    const operatorName = formatFullName(user!.name, user?.surname, user?.patronymic)

    const context = useContext(ToastContext)

    const properties = initialPayment?.order?.user?.properties || {}

    const propertiesArray = Object.entries(properties).map(([key]) => ({
      name: key,
      value: '1',
    }))

    const methods = useForm<TicketPaymentFormType>({
      mode: 'onChange',
      defaultValues: {
        order: {
          user: {
            name: initialPayment
              ? initialPayment?.order.user.name
              : window.sessionStorage.getItem('contact_name') ?? '',
            email: initialPayment?.order.user.email ?? window.sessionStorage.getItem('contact_email') ?? '',
            phone: initialPayment
              ? formatPhone(initialPayment.order!.user!.phone!)
              : window.sessionStorage.getItem('contact_phone') ?? '',
            children:
              initialPayment?.order.user.children ??
              (window.sessionStorage.getItem('contact_children')
                ? JSON.parse(window.sessionStorage.getItem('contact_children')!)
                : []),
            bonus: initialPayment ? initialPayment.order?.user?.bonus?.sum : 0,
            is_information_agreed: initialPayment ? Boolean(initialPayment?.order.user?.information_agree) : false,
            is_loyalty_agreed: initialPayment ? Boolean(initialPayment?.order.user?.loyalty_agree) : false,
            properties: propertiesArray,
          },
          children: initialPayment?.order.children ?? undefined,
          bonuses: initialPayment?.order.bonus_sum ?? 0,
        },
        id: initialPayment ? initialPayment.id : undefined,
        paymentType: typePaymentArray.find((e) => e.id === initialPayment?.type),
        tickets: initialPayment
          ? initialPayment.order.tickets.map((e) => ({
              id: e.id,
              name: e.name,
              price: String(e.price / e.count),
              totalPrice: e.discount_price ?? e.count * e.price,
              discount: discounts.find((d) => d.id === e.discount_id),
            }))
          : activeTickets
              ?.filter((e) => +e.weekday_price > 0)
              .map((e) => ({
                id: e.id,
                name: e.name,
                price: isWeekend(new Date()) ? String(e.weekend_price) : String(e.weekday_price),
                totalPrice: 0,
              })),
        newTickets:
          initialPayment && initialPayment.order?.tickets?.length > 0
            ? initialPayment.order.tickets.map((e) => ({
                entity: activeTickets.find((t) => t.id === e.original_id),
                totalPrice: e.discount_price ?? e.price,
                discount: discounts.find((d) => d.id === e.discount_id),
              }))
            : [
                {
                  entity: null,
                  totalPrice: 0,
                  discount: null,
                },
              ],
        services:
          initialPayment && initialPayment.order?.services?.length > 0
            ? initialPayment.order.services.map((e) => ({
                entity: services.find((t) => t.id === e.original_id),
                totalPrice: e.discount_price ?? e.price,
                discount: discounts.find((d) => d.id === e.discount_id),
              }))
            : [
                {
                  entity: null,
                  totalPrice: 0,
                  discount: null,
                },
              ],
        date: initialPayment?.order?.schedule ? new Date(initialPayment?.order.schedule.starts_at) : null,
        starts_at: initialPayment?.order?.schedule
          ? new Date(String(initialPayment?.order.schedule.starts_at)).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })
          : '',
        ends_at: initialPayment?.order?.schedule
          ? new Date(String(initialPayment?.order.schedule.ends_at)).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })
          : '',
        totalAmount: initialPayment?.order.price ?? 0,
        totalPrice: initialPayment ? initialPayment?.order.price + initialPayment?.bonus_sum : 0,
        preorderSum: isPreOrderInclude ? initialPayment!.sum : 0,
      },
    })

    const paymentType = useWatch({ control: methods.control, name: 'paymentType' })

    useEffect(() => {
      if (!initialPayment) {
        methods.reset({
          order: {
            user: {
              name: '',
              email: '',
              phone: '',
              children: [],
              bonus: 0,
            },
            children: [],
            bonuses: 0,
          },
          paymentType: typePaymentArray[1],
          tickets: activeTickets
            ?.filter((e) => +e.weekday_price > 0)
            .map((e) => ({
              id: e.id,
              name: e.name,
              price: isWeekend(new Date()) ? String(e.weekend_price) : String(e.weekday_price),
              count: 0,
              totalPrice: 0,
              discount: undefined,
            })),
          newTickets: [
            {
              entity: null,
              totalPrice: 0,
              discount: null,
            },
          ],
          services: [
            {
              entity: null,
              totalPrice: 0,
              discount: null,
            },
          ],
          date: null,
          starts_at: '',
          ends_at: '',
          totalAmount: 0,
          totalPrice: 0,
          preorderSum: 0,
        })
      }
    }, [initialPayment, methods, activeTickets])

    const onPinModalHide = () => {
      setIsPinModalVisible(false)
    }

    const onSuccessCheckedPIN = () => {
      updatePaymentHandler!({ ...initialPayment!, order: { ...initialPayment!.order, pin_activated: 1 } })
    }

    const onPinModalToggle = () => {
      setIsPinModalVisible(!isPinModalVisible)
    }

    const generateAndCopyLink = async () => {
      if (!initialPayment?.online_token) {
        context?.toastShowHandler({ status: false, summary: 'Токен не найден' })
        return
      }
      const link = generateOnlinePayLink(initialPayment!.online_token, selectedPark!.brand_id!)
      await navigator.clipboard.writeText(link)
      context?.toastShowHandler({ status: true, summary: 'Ссылка скопирована' })
    }

    const onSubmit = async (data: TicketPaymentFormType) => {
      if ((!data.order.children || data.order.children.length === 0) && !data.order.newKid) {
        context?.toastShowHandler({ status: false, errors: 'Добавьте хотя бы 1 ребенка' })
        return
      }

      const formData = new FormData()

      formData.append('park_id', String(selectedPark!.id))
      formData.append('user[name]', data.order.user!.name)
      formData.append('user[phone]', data.order.user!.phone)
      formData.append('payment_type', String(data.paymentType.id))
      data.order.user?.email && formData.append('user[email]', data.order.user.email)

      if (data.date && data.ends_at && data.starts_at) {
        const starts_at = data.date.toLocaleDateString('en-CA') + ' ' + data.starts_at + ':00'
        const ends_at = data.date.toLocaleDateString('en-CA') + ' ' + data.ends_at + ':00'
        formData.append('starts_at', starts_at)
        formData.append('ends_at', ends_at)
      }

      let bonusSum = data.order.bonuses || 0

      data.newTickets
        .filter((e) => e.entity !== null)
        .forEach((e, index) => {
          formData.append(`tickets[${index}][id]`, String(e.entity!.id))
          formData.append(`tickets[${index}][count]`, '1')

          let ticketPrice = e.discount ? e.totalPrice : isWeekend() ? e.entity!.weekend_price : e.entity!.weekday_price

          // Вычитаем бонусы из цены билета
          if (bonusSum > 0) {
            const maxDeductible = ticketPrice - 1 // Чтобы сумма не была меньше 1
            const deduction = Math.min(maxDeductible, bonusSum)
            ticketPrice -= deduction
            bonusSum -= deduction
          }

          formData.append(`tickets[${index}][total_price]`, String(ticketPrice))

          formData.append(
            `tickets[${index}][price]`,
            isWeekend() ? String(e.entity!.weekend_price) : String(e.entity!.weekday_price)
          )
          if (e.discount) {
            formData.append(`tickets[${index}][discount_price]`, String(e.totalPrice))
            formData.append(`tickets[${index}][discount_id]`, String(e.discount.id))
          }
        })

      data.services
        .filter((e) => e.entity !== null)
        .forEach((e, index) => {
          formData.append(`services[${index}][id]`, String(e.entity!.id))
          formData.append(`services[${index}][count]`, '1')

          let ticketPrice = e.discount ? e.totalPrice : +e.entity!.price

          // Вычитаем бонусы из цены билета
          if (bonusSum > 0) {
            const maxDeductible = ticketPrice - 1 // Чтобы сумма не была меньше 1
            const deduction = Math.min(maxDeductible, bonusSum)
            ticketPrice -= deduction
            bonusSum -= deduction
          }

          formData.append(`services[${index}][total_price]`, String(ticketPrice))

          formData.append(`services[${index}][price]`, String(e.entity?.price))
          if (e.discount) {
            formData.append(`services[${index}][discount_price]`, String(e.totalPrice))
            formData.append(`services[${index}][discount_id]`, String(e.discount.id))
          }
        })

      let childIndex = 0
      data.order.children?.forEach((e) => {
        formData.append(`kids[${childIndex}][id]`, String(e.id))
        childIndex++
      })

      data.order.newKid &&
        data.order.newKid.forEach((e) => {
          formData.append(`kids[${childIndex}][name]`, String(e.name))
          formData.append(`kids[${childIndex}][birth]`, e.birth.toLocaleDateString('en-CA'))
          childIndex++
        })

      formData.append('price', String(data.totalAmount))
      data.preorderSum
        ? formData.append('payment_sum', String(data.preorderSum))
        : formData.append('payment_sum', String(data.totalAmount))
      data.order.bonuses && formData.append('bonus_sum', String(data.order.bonuses))
      data.preorderSum && formData.append('is_prepayment', '1')

      //Сначала создаем платеж на нашем сервере
      const { status, payment, errors } = await logic.createPaymentTicket(formData)

      if (status) {
        //Если платеж успешно создался, то редирект на страницу платежа
        const path = generatePath(AppRoutesPaths.TicketSaleEdit, {
          id: String(payment.payment.id),
        })

        navigate(path)

        return
      } else context?.toastShowHandler({ status: status, errors: errors })
    }

    //Входная точка при оплате
    const onPay = async () => {
      setIsPayButtonLoading(true)
      let bonusSum = initialPayment!.order.bonus_sum

      const kkmItems: IKkmItem[] = []

      initialPayment!.order.tickets.length > 0 &&
        initialPayment!.order.tickets.forEach((e) => {
          if (e.count <= 0) return
          for (let i = 0; i < e.count; i++) {
            kkmItems.push({
              name: e.name,
              quantity: 1,
              price: +e.price,
              amount: e.discount_price ?? +e.price,
              type: 4,
              measure: 0,
              signMethodCalculation: isPreOrderInclude && !isOrderPrePaid ? 2 : 4,
            })
          }
        })

      initialPayment!.order.services.length > 0 &&
        initialPayment!.order.services.forEach((e) => {
          if (e.count <= 0) return
          for (let i = 0; i < e.count; i++) {
            kkmItems.push({
              name: e.name,
              quantity: 1,
              price: +e.price,
              amount: e.discount_price ?? +e.price,
              type: 4,
              measure: 0,
              signMethodCalculation: isPreOrderInclude && !isOrderPrePaid ? 2 : 4,
            })
          }
        })

      for (const item of kkmItems) {
        if (bonusSum <= 0) break // Если бонусы закончились, выход из цикла

        const maxDeductible = item.amount - 1 // Максимально возможная скидка, чтобы amount не стал меньше 1
        const deduction = Math.min(maxDeductible, bonusSum) // Сумма, на которую уменьшится amount
        item.amount -= deduction
        bonusSum -= deduction
      }

      const kkmClient: IKkmClient = {
        name: initialPayment!.order.user!.name,
        phone: formatPhone(initialPayment!.order.user!.phone!),
        email: initialPayment!.order.user?.email,
      }

      if (initialPayment?.sum === 0) {
        await logic.applyPayment(new FormData(), initialPayment!.id)
        await loadPaymentHandler()
        return
      }

      if (paymentType.type === 'cash') {
        const formData = new FormData()
        initialPayment?.status === 1 && isPreOrderInclude && formData.append('is_prepayment', '1')
        const { status, data } = await logic.applyPayment(formData, initialPayment!.id)
        if (!status) {
          context?.toastShowHandler({
            status: false,
            errors: 'Произошла ошибка при подтверждении платежа. Пожалуйста, обратитесь к администратору',
          })
          return
        }
        await RegisterCheck({
          typeCheck: 0,
          operatorName: operatorName,
          items: kkmItems,
          client: kkmClient,
          sum: initialPayment!.sum,
          callback: (result: IKkmRegisterCheckResult) => registerPaymentCallback(result, data),
          type: 'cash',
          advancePayment: isOrderPrePaid ? initialPayment!.sum : 0,
          credit: isPreOrderInclude && !isOrderPrePaid ? initialPayment!.order.price - initialPayment!.sum : 0,
        })
      } else {
        await PayByPaymentCard(initialPayment!.sum, (result: any) =>
          payByPaymentCardCallback(result, initialPayment!.sum, kkmItems, kkmClient, initialPayment!)
        )
      }
    }

    //Колбек для функции оплаты банковской картой.
    //Проверяет ответ от эквайрингового аппарата и в зависимости от этого либо печатает чек, либо выводит ошибку.
    const payByPaymentCardCallback = async (
      result: IKkmPaymentCardResult,
      sum: number,
      kkmItems: IKkmItem[],
      kkmClient: IKkmClient,
      payment: ApiPayment
    ) => {
      console.log('IN CALLBACK FROM SBER. RESULT => ', result)
      if (result.Status === 0) {
        const formData = new FormData()
        formData.append('payment_id', result.UniversalID)
        initialPayment?.status === 1 && isPreOrderInclude && formData.append('is_prepayment', '1')
        const { status, data } = await logic.applyPayment(formData, payment.id)
        if (!status) {
          context?.toastShowHandler({
            status: false,
            errors: 'Произошла ошибка при подтверждении платежа. Пожалуйста, обратитесь к администратору',
          })
          return
        }
        await RegisterCheck({
          typeCheck: 0,
          operatorName: operatorName,
          items: kkmItems,
          client: kkmClient,
          sum: sum,
          callback: (newResult: IKkmRegisterCheckResult) => registerPaymentCallback(newResult, data),
          type: 'online',
          advancePayment: isOrderPrePaid ? initialPayment!.sum : 0,
          credit: isPreOrderInclude && !isOrderPrePaid ? initialPayment!.order.price - initialPayment!.sum : 0,
        })
      } else if (result.Status === 2) {
        context?.toastShowHandler({ status: false, errors: result.Error })
        setIsPayButtonLoading(false)
      } else if (result.Status === 3) {
        context?.toastShowHandler({ status: false, errors: 'Устройство не найдено' })
        setIsPayButtonLoading(false)
      } else {
        context?.toastShowHandler({
          status: false,
          errors: 'Произошла ошибка. Пожалуйста, обратитесь к администратору',
        })
        setIsPayButtonLoading(false)
      }
    }

    //Колбек для печати чека платежа
    const registerPaymentCallback = async (result: IKkmRegisterCheckResult, payment: ApiPayment) => {
      if (result.Status === 0) {
        await setCheckHandler(result.CheckNumber, payment!.id, 1)
      } else if (result.Status === 2 && paymentType.type === 'online') {
        context?.toastShowHandler({ status: false, errors: result.Error })
        const formData = createFormData({ success: 0, comment: result.Error })
        await logic.checkSetTicket(formData, payment.id)
        await loadPaymentHandler()
      } else if (result.Status === 2 && paymentType.type === 'cash') {
        context?.toastShowHandler({ status: false, errors: result.Error })
        const formData = createFormData({ success: 0, comment: result.Error })
        await logic.checkSetTicket(formData, payment.id)
        await loadPaymentHandler()
      } else if (result.Status === 3 && paymentType.type === 'online') {
        context?.toastShowHandler({ status: false, errors: 'Данные не найдены' })

        const formData = createFormData({ comment: 'Данные не найдены' })
        await logic.errorPaymentTicket(formData, payment!.id)
        updatePaymentHandler!({ ...payment, status: 3 })
      } else if (result.Status === 3 && paymentType.type === 'cash') {
        context?.toastShowHandler({ status: false, errors: 'Данные не найдены' })
      }
    }

    //Очистка данных сессии, которые использовались для автозаполнения при вызове формы со страницы контакта
    useEffect(() => {
      return () => {
        window.sessionStorage.removeItem('contact_children')
        window.sessionStorage.removeItem('contact_name')
        window.sessionStorage.removeItem('contact_email')
        window.sessionStorage.removeItem('contact_phone')
      }
    }, [])

    const setCheckHandler = async (fiskalNumber: number, id: number, success: 0 | 1) => {
      await GetDataCheck(fiskalNumber, async (result: any) => {
        if (result.Status === 0) {
          const formData = createFormData({ slip: result.Slip, id: id, success: success, fiskal_number: fiskalNumber })
          const { status, errors } = await logic.checkSetTicket(formData, id)
          if (status) {
            if (!initialPayment) {
              const path = generatePath(AppRoutesPaths.TicketSaleEdit, {
                id: String(id),
              })

              navigate(path)
              return
            }
            await loadPaymentHandler()
          } else context?.toastShowHandler({ status: false, errors: errors })
        }
        if (result.Status === 2) context?.toastShowHandler({ status: false, errors: result.Error })
        else if (result.Status === 3) context?.toastShowHandler({ status: false, errors: 'Данные не найдены' })
      })
    }

    const fiskalOnlinePayment = async () => {
      let bonusSum = initialPayment!.order.bonus_sum

      const kkmItems: IKkmItem[] = []

      initialPayment!.order.tickets.length > 0 &&
        initialPayment!.order.tickets.forEach((e) => {
          if (e.count <= 0) return
          for (let i = 0; i < e.count; i++) {
            kkmItems.push({
              name: e.name,
              quantity: 1,
              price: +e.price,
              amount: e.discount_price ?? +e.price,
              type: 4,
              measure: 0,
              signMethodCalculation: isOrderPrePaid ? 2 : 4,
            })
          }
        })

      initialPayment!.order.services.length > 0 &&
        initialPayment!.order.services.forEach((e) => {
          if (e.count <= 0) return
          for (let i = 0; i < e.count; i++) {
            kkmItems.push({
              name: e.name,
              quantity: 1,
              price: +e.price,
              amount: e.discount_price ?? +e.price,
              type: 4,
              measure: 0,
              signMethodCalculation: isOrderPrePaid ? 2 : 4,
            })
          }
        })

      for (const item of kkmItems) {
        if (bonusSum <= 0) break // Если бонусы закончились, выход из цикла

        const maxDeductible = item.amount - 1 // Максимально возможная скидка, чтобы amount не стал меньше 1
        const deduction = Math.min(maxDeductible, bonusSum) // Сумма, на которую уменьшится amount
        item.amount -= deduction
        bonusSum -= deduction
      }

      const kkmClient: IKkmClient = {
        name: initialPayment!.order.user!.name,
        phone: formatPhone(initialPayment!.order.user!.phone!),
        email: initialPayment!.order.user?.email,
      }

      await RegisterCheck({
        typeCheck: 0,
        operatorName: operatorName,
        items: kkmItems,
        client: kkmClient,
        sum: initialPayment!.sum,
        callback: (result: IKkmRegisterCheckResult) => retryFiskalCallback(result, initialPayment),
        type: 'online',
        advancePayment: 0,
        credit: initialPayment!.order.price - initialPayment!.sum,
      })
    }

    const retryFiskal = async (sum?: number, advancePayment?: number, credit?: number) => {
      let bonusSum = initialPayment!.order.bonus_sum

      const kkmItems: IKkmItem[] = []

      initialPayment!.order.tickets.length > 0 &&
        initialPayment!.order.tickets.forEach((e) => {
          if (e.count <= 0) return
          for (let i = 0; i < e.count; i++) {
            kkmItems.push({
              name: e.name,
              quantity: 1,
              price: +e.price,
              amount: e.discount_price ?? +e.price,
              type: 4,
              measure: 0,
            })
          }
        })

      initialPayment!.order.services.length > 0 &&
        initialPayment!.order.services.forEach((e) => {
          if (e.count <= 0) return
          for (let i = 0; i < e.count; i++) {
            kkmItems.push({
              name: e.name,
              quantity: 1,
              price: +e.price,
              amount: e.discount_price ?? +e.price,
              type: 4,
              measure: 0,
            })
          }
        })

      for (const item of kkmItems) {
        if (bonusSum <= 0) break // Если бонусы закончились, выход из цикла

        const maxDeductible = item.amount - 1 // Максимально возможная скидка, чтобы amount не стал меньше 1
        const deduction = Math.min(maxDeductible, bonusSum) // Сумма, на которую уменьшится amount
        item.amount -= deduction
        bonusSum -= deduction
      }

      const kkmClient: IKkmClient = {
        name: initialPayment!.order.user!.name,
        phone: formatPhone(initialPayment!.order.user!.phone!),
        email: initialPayment!.order.user?.email,
      }

      console.log(paymentType)

      await RegisterCheck({
        typeCheck: 0,
        operatorName: operatorName,
        items: kkmItems,
        client: kkmClient,
        sum: sum ?? initialPayment!.order.price,
        callback: (result: IKkmRegisterCheckResult) => retryFiskalCallback(result, initialPayment),
        type: paymentType.type === 'cash' ? 'cash' : 'online',
        advancePayment: advancePayment ?? 0,
        credit: credit ?? 0,
      })
    }

    const calcKKMEntities = () => {
      const kkmItems: IKkmItem[] = []

      let bonusSum = initialPayment!.order.bonus_sum

      initialPayment!.order.tickets.length > 0 &&
        initialPayment!.order.tickets.forEach((e) => {
          if (e.count <= 0) return
          for (let i = 0; i < e.count; i++) {
            kkmItems.push({
              name: e.name,
              quantity: 1,
              price: +e.price,
              amount: e.discount_price ?? +e.price,
              type: 4,
              measure: 0,
            })
          }
        })

      initialPayment!.order.services.length > 0 &&
        initialPayment!.order.services.forEach((e) => {
          if (e.count <= 0) return
          for (let i = 0; i < e.count; i++) {
            kkmItems.push({
              name: e.name,
              quantity: 1,
              price: +e.price,
              amount: e.discount_price ?? +e.price,
              type: 4,
              measure: 0,
            })
          }
        })

      for (const item of kkmItems) {
        if (bonusSum <= 0) break // Если бонусы закончились, выход из цикла

        const maxDeductible = item.amount - 1 // Максимально возможная скидка, чтобы amount не стал меньше 1
        const deduction = Math.min(maxDeductible, bonusSum) // Сумма, на которую уменьшится amount
        item.amount -= deduction
        bonusSum -= deduction
      }

      const kkmClient: IKkmClient = {
        name: initialPayment!.order.user!.name,
        phone: formatPhone(initialPayment!.order.user!.phone!),
        email: initialPayment!.order.user?.email,
      }

      return { kkmItems, kkmClient }
    }

    //Колбек для повторной фискализации и печати чека платежа
    const retryFiskalCallback = async (result: IKkmRegisterCheckResult, payment?: ApiPayment) => {
      if (result.Status === 0) {
        await setCheckHandler(result.CheckNumber, payment!.id, 1)
      }
      if (result.Status === 2) {
        context?.toastShowHandler({ status: false, errors: result.Error })
      } else if (result.Status === 3) {
        context?.toastShowHandler({ status: false, errors: 'Данные не найдены' })
      }
    }

    const onPostPay = async () => {
      const formData = new FormData()
      formData.append('payment_type', paymentType!.type === 'cash' ? '0' : '1')
      formData.append('sum', String(initialPayment!.order.price - initialPayment!.sum))
      const { payment } = await logic.createPaymentTicketByOrderId(initialPayment!.order_id, formData)
      if (!payment) return

      if (payment.type == 0) {
        const { status, data } = await logic.applyPayment(new FormData(), payment!.id)
        if (!status) {
          context?.toastShowHandler({
            status: false,
            errors: 'Произошла ошибка при подтверждении платежа. Пожалуйста, обратитесь к администратору',
          })
          return
        }
        await RegisterCheck({
          typeCheck: 0,
          operatorName: operatorName,
          items: calcKKMEntities().kkmItems,
          client: calcKKMEntities().kkmClient,
          sum: data!.sum,
          callback: (result: IKkmRegisterCheckResult) => registerPaymentCallback(result, data),
          type: 'cash',
          advancePayment: initialPayment!.sum,
          credit: 0,
        })
      } else {
        await PayByPaymentCard(initialPayment!.order.price - initialPayment!.sum, (result: any) =>
          payByPaymentCardCallback(
            result,
            initialPayment!.order.price - initialPayment!.sum,
            calcKKMEntities().kkmItems,
            calcKKMEntities().kkmClient,
            payment
          )
        )
      }
    }

    const onPrintPDF = async () => {
      const pdfUrl = '/docs/agreement.pdf' // Путь к PDF-файлу в папке public

      // Загрузка PDF-файла из папки public
      const existingPdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer())

      // Загрузка документа в pdf-lib
      const pdfDoc = await PDFDocument.load(existingPdfBytes)

      pdfDoc.registerFontkit(fontkit)

      // Загрузка шрифта, поддерживающего кириллицу
      const fontUrl = '/fonts/Roboto-Regular.ttf' // Путь к шрифту в папке public
      const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer())

      // Встраивание шрифта в документ
      const customFont = await pdfDoc.embedFont(fontBytes)

      // Выбор страницы (например, первая страница)
      const pages = pdfDoc.getPages()
      const firstPage = pages[0]

      // Добавление текста на первую страницу
      firstPage.drawText(initialPayment!.order.user.name, {
        x: 40,
        y: 768, // положение текста
        size: 10,
        font: customFont,
        color: rgb(0, 0, 0),
      })

      firstPage.drawText(`+7${initialPayment!.order.user.phone ?? ''}`, {
        x: 480,
        y: 768, // положение текста
        size: 10,
        font: customFont,
        color: rgb(0, 0, 0),
      })

      initialPayment!.order.children.forEach((e, i) => {
        if (i > 2) return
        firstPage.drawText(e.name, {
          x: 60,
          y: 733 - i * 20, // положение текста
          size: 10,
          font: customFont,
          color: rgb(0, 0, 0),
        })
        firstPage.drawText(moment(e.birth).format('DD.MM.YYYY'), {
          x: 400,
          y: 733 - i * 20, // положение текста
          size: 10,
          font: customFont,
          color: rgb(0, 0, 0),
        })
      })

      // Добавление текста на первую страницу
      firstPage.drawText(moment().format('DD'), {
        x: 58,
        y: 254, // положение текста
        size: 10,
        font: customFont,
        color: rgb(0, 0, 0),
      })

      moment.locale('ru')

      firstPage.drawText(moment().format('DD MMMM YYYY').split(' ')[1], {
        x: 85,
        y: 254, // положение текста
        size: 10,
        font: customFont,
        color: rgb(0, 0, 0),
      })

      firstPage.drawText(moment().format('YYYY'), {
        x: 135,
        y: 254, // положение текста
        size: 10,
        font: customFont,
        color: rgb(0, 0, 0),
      })

      firstPage.drawText(moment().format('HH'), {
        x: 218,
        y: 254, // положение текста
        size: 10,
        font: customFont,
        color: rgb(0, 0, 0),
      })

      firstPage.drawText(moment().format('mm'), {
        x: 238,
        y: 254, // положение текста
        size: 10,
        font: customFont,
        color: rgb(0, 0, 0),
      })

      // Добавление текста на первую страницу
      firstPage.drawText(initialPayment!.order.user.name, {
        x: 160,
        y: 232, // положение текста
        size: 10,
        font: customFont,
        color: rgb(0, 0, 0),
      })

      // Сохранение обновленного PDF
      const pdfBytes = await pdfDoc.save()

      // Создание Blob и ссылка для скачивания
      const blob = new Blob([pdfBytes], { type: 'application/pdf' })
      const url = URL.createObjectURL(blob)

      printJS({
        printable: url,
        type: 'pdf',
        showModal: true,
        onError: () => context?.toastShowHandler({ status: false, errors: 'Произошла ошибка при печати' }),
        onPrintDialogClose: async () =>
          await logic.agreemenentConfirm(initialPayment!.order_id).then(() => onSuccessCheckedPIN()),
      })
    }

    return (
      <>
        <FormProvider {...methods}>
          <form className='flex h-full w-full flex-col gap-4' onSubmit={methods.handleSubmit(onSubmit)}>
            {initialPayment && (
              <section className='flex gap-2'>
                {initialPayment?.type === 7 && initialPayment?.order.status === 3 ? (
                  <UiButton
                    type='button'
                    className='w-[100px]'
                    color='yellow'
                    onClick={generateAndCopyLink}
                    disabled={!!initialPayment.order.pin_activated}
                  >
                    ссылка
                  </UiButton>
                ) : (
                  <UiButton
                    type='button'
                    className='w-[100px]'
                    color='yellow'
                    onClick={onPinModalToggle}
                    disabled={!!initialPayment.order.pin_activated || initialPayment.order.status === 0}
                  >
                    PIN
                  </UiButton>
                )}
                <UiButton
                  type='button'
                  className='w-[100px]'
                  onClick={onPrintPDF}
                  disabled={
                    initialPayment.order.status === 0 ||
                    (initialPayment?.type === 7 && initialPayment?.order.status === 3)
                  }
                >
                  согласие
                </UiButton>
                <UiButton
                  type='button'
                  className='w-[100px]'
                  onClick={showCheckHandler}
                  disabled={initialPayment.checks?.length === 0}
                >
                  чек
                </UiButton>
                <RefountButton loadPayment={loadPaymentHandler} payment={initialPayment} />
                <ManualApplyButton retryCallback={retryFiskal} payment={initialPayment} />
              </section>
            )}
            <div className='flex w-full justify-between gap-4'>
              <ClientPart disable={!!initialPayment || !!window.sessionStorage.getItem('contact_phone')} />
              <ChildPart disable={!!initialPayment} />
            </div>
            <div className='flex flex-col gap-6'>
              <TicketPart disable={!!initialPayment} />
              <ServicesPart disable={!!initialPayment} />
              <TotalPrice disable={!!initialPayment} status={initialPayment?.order.status ?? 0} />
            </div>
            {!initialPayment && (
              <section className='mt-auto flex gap-[30px]'>
                <UiButton className='w-[180px]'>создать заказ</UiButton>
              </section>
            )}
            <div className='flex gap-4'>
              {initialPayment && ((initialPayment.status === 1 && !isPreOrderInclude) || isOrderPrePaid) && (
                <section className='mt-auto flex gap-[30px]'>
                  <UiButton
                    className='w-[180px]'
                    onClick={() => (isPreOrderInclude ? onPostPay() : onPay())}
                    type='button'
                    isLoading={isPayButtonLoading}
                    disabled={!initialPayment.order.pin_activated}
                  >
                    оплатить
                  </UiButton>
                </section>
              )}
              {initialPayment && initialPayment.status === 1 && isPreOrderInclude && (
                <section className='mt-auto flex gap-[30px]'>
                  <UiButton
                    className='w-[180px]'
                    onClick={onPay}
                    type='button'
                    color='yellow'
                    isLoading={isPayButtonLoading}
                    disabled={
                      !initialPayment.order.pin_activated ||
                      (initialPayment?.type === 7 && initialPayment?.order.status === 3)
                    }
                  >
                    предоплата
                  </UiButton>
                </section>
              )}
            </div>
            {initialPayment &&
              initialPayment.status === 2 &&
              initialPayment.checks?.length === 0 &&
              initialPayment.type !== 7 && (
                <section className='mt-auto flex gap-[30px]'>
                  <UiButton className='w-[180px]' type='button' onClick={() => retryFiskal()}>
                    повторная фискализация
                  </UiButton>
                </section>
              )}
            {initialPayment &&
              initialPayment.status === 2 &&
              initialPayment.checks?.length === 0 &&
              initialPayment.type === 7 && (
                <section className='mt-auto flex gap-[30px]'>
                  <UiButton className='w-[180px]' type='button' onClick={fiskalOnlinePayment}>
                    фискализация онлайн-платежа
                  </UiButton>
                </section>
              )}
          </form>
        </FormProvider>
        {initialPayment && (
          <PinModal
            orderId={initialPayment.order_id}
            isVisible={isPinModalVisible}
            onHide={onPinModalHide}
            onSuccess={onSuccessCheckedPIN}
          ></PinModal>
        )}
      </>
    )
  }
)
